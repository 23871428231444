var urltype = process.env.NODE_ENV == "development"; //判断是否为本地环境
const protocol = window.location.protocol + '//';// 判断当前协议是: http: 或 https:
const protocols = 'https://';// 判断当前协议是: http: 或 https:
/****************php接口***************************************************/
let phpUrl = "";
// phpUrl = "pt.yaojuli.com:8006/api"// 正式环境
// phpUrl = "prept.yaojuli.com:8006/api"// 灰度环境
phpUrl = "192.168.40.39:8002/api";// 测试环境
// phpUrl = "192.168.40.39:9002/api";// 开发环境
// phpUrl = ".35.232:9502"//罗申洪
// phpUrl = "192.168.35.67:9502"// 陈浩
// phpUrl = "192.168.35.161:9502"// 候建
var phpBaseUrl = urltype ? protocol + phpUrl : window.location.origin + "/api";

/****************java接口***************************************************/
// let devUrl =protocol + "yygproapi.yaojuli.com"; // 阿里云正式地址
// let devUrl = protocols + "preyygproapi.yaojuli.com"; // 灰度地址
let devUrl = protocol + "222.209.81.152:8003"; //测试地址
// let devUrl = protocol +"192.168.40.39:9090"; //开发地址
// let devUrl = "192.168.35.103:10007"; //谢锋周地址
// let devUrl = "183.220.29.156:8003"; //临时地址

let csUrlapp = "222.209.81.152:8003"; // 测试
let kfUrlapp = "192.168.40.39:9090"; // 开发
let proUrlyun = "yygproapi.yaojuli.com"; // 阿里云正式地址
let preproUrlyun = "preyygproapi.yaojuli.com"; // 灰度地址
var javaUrl =
  window.location.origin.indexOf("prept.yaojuli.com") != -1
    ? preproUrlyun
    : window.location.origin.indexOf("pt.yaojuli.com") != -1
      ? proUrlyun
      : window.location.origin.indexOf("192.168.40.39:9002") != -1
        ? kfUrlapp
        : csUrlapp;
var javaBaseUrl = urltype ? devUrl : protocol + javaUrl;
/***************************************************************/
window.localStorage.setItem("javaBaseUrl", javaBaseUrl);
export { phpBaseUrl, javaBaseUrl };
