import { render, staticRenderFns } from "./merchantCenterList.vue?vue&type=template&id=adc3cdea&scoped=true&"
import script from "./merchantCenterList.vue?vue&type=script&lang=js&"
export * from "./merchantCenterList.vue?vue&type=script&lang=js&"
import style0 from "./merchantCenterList.vue?vue&type=style&index=0&id=adc3cdea&lang=scss&scoped=true&"
import style1 from "./merchantCenterList.vue?vue&type=style&index=1&id=adc3cdea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adc3cdea",
  null
  
)

export default component.exports