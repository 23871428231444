<template>
  <div class="basicGoodsInfo">
    <div class="goods-info">
      <div class="info-left">
        <div class="title">商品基本信息</div>
        <el-col :span="12">
          <div class="lable">
            商品名称:<span class="text">{{ basicForm.name || "/" }}</span>
          </div>
          <div class="lable">
            规格:<span class="text">{{ basicForm.specification || "/" }}</span>
          </div>
          <div class="lable">
            商品单位:<span class="text">{{ basicForm.unit || "/" }}</span>
          </div>
          <div class="lable">
            商品类型:<span class="text">{{
              basicForm.category | category
            }}</span>
          </div>
          <div class="lable">
            条形码（69码）:<span class="text">{{
              basicForm.barCode || "/"
            }}</span>
          </div>
          <div class="lable">
            国家医保码:<span class="text">{{
              basicForm.healthCode || "/"
            }}</span>
          </div>
          <div class="lable">
            批准文号:<span class="text">{{
              basicForm.approvalNumber || "/"
            }}</span>
          </div>
          <div class="lable">
            经营类别:<span class="text">{{
              basicForm.businessscopestring || "/"
            }}</span>
          </div>
          <div class="lable">
            外部ID:<span class="text">{{ basicForm.externalId || "/" }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="lable">
            通用名:<span class="text">{{ basicForm.commonName || "/" }}</span>
          </div>
          <div class="lable">
            拼音名:<span class="text">{{ basicForm.pinyin || "/" }}</span>
          </div>
          <div class="lable">
            品牌:<span class="text">{{ basicForm.brand || "/" }}</span>
          </div>
          <div class="lable">
            本位码:<span class="text">{{ basicForm.standardCode || "/" }}</span>
          </div>
          <div class="lable">
            执行标准:<span class="text">{{
              basicForm.executionStandard || "/"
            }}</span>
          </div>
          <div class="lable">
            产地:<span class="text">{{ basicForm.placeOrigin || "/" }}</span>
          </div>
          <div class="lable">
            剂型:<span class="text">{{ basicForm.dosageForm || "/" }}</span>
          </div>
          <div class="lable">
            生产许可证:<span class="text">{{
              basicForm.productLice || "/"
            }}</span>
          </div>
          <div class="lable">
            注册编号:<span class="text">{{ basicForm.regNo || "/" }}</span>
          </div>
          <div class="lable">
            剂型:<span class="text">{{ basicForm.model || "/" }}</span>
          </div>
          <div class="lable">
            商品分类:<span class="text">{{ goodsCategoryName }}</span>
          </div>
        </el-col>
      </div>
      <div class="info-rigth">
        <div class="title">商品厂家信息</div>
        <el-col :span="12">
          <div class="lable">
            生产厂家:<span class="text">{{
              basicForm.manufacturer || "/"
            }}</span>
          </div>
          <div class="lable">
            厂家简称:<span class="text">{{ basicForm.shortName || "/" }}</span>
          </div>
          <div class="lable">
            厂家地址:<span class="text">{{
              basicForm.productionAddress || "/"
            }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="lable">
            药品上市许可持有人:<span class="text">{{
              basicForm.marketingAuthorizationHolder || "/"
            }}</span>
          </div>
          <div class="lable">
            上市许可持有人地址:<span class="text">{{
              basicForm.marketingAuthorizationHolderAddress || "/"
            }}</span>
          </div>
        </el-col>
        <div class="title">商品标签信息</div>
        <el-col :span="12">
          <div class="lable">
            是否集采:<span class="text">{{
              basicForm.isCollect * 1 == 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="lable">
            是否有追朔码:<span class="text">{{
              basicForm.traceCode * 1 == 1 ? "是" : "否"
            }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="lable">
            是否OTC:<span class="text">{{
              basicForm.isOtc * 1 == 1 ? "是" : "否"
            }}</span>
          </div>
          <div class="lable" v-if="basicForm.isOtc == 1">
            OTC类型:<span class="text">{{
              basicForm.otcType * 1 == 1 ? "甲类" : "乙类"
            }}</span>
          </div>
        </el-col>
        <div class="title">
          <span>商品图片</span>
          <span style="margin-left: 10px"
            >(<span style="color: red; font-weight: 400; font-size: 15px"
              >最近更新时间{{ basicForm.pictureUpdateTime }}</span
            >)</span
          >
        </div>
        <div class="goods-img">
          <template>
            <el-image
              v-for="(item, index) in basicForm.pictureUrlList"
              :key="index"
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="[item]"
            ></el-image>
          </template>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div class="refuse" @click="toBack">返回</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  name: "basicGoodsShow",
  //   props:{
  //       detailsData:{
  //           type: Object,
  //           default:{}
  //       }
  //   },
  //   watch:{
  //       detailsData(val){
  //           for(let key in val){
  //               if(this.basicForm.hasOwnProperty(key)){
  //                   this.basicForm[key] = val[key];
  //               }
  //           }
  //           this.businessscopestring = val.businessscopestring
  //           if(this.basicForm.businessScopeId == 0){
  //               this.basicForm.businessScopeId = ''
  //           }
  //       }
  //   },
  data() {
    return {
      imageList: [],
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        checkStrictly: true,
        //级联选择器配置项
      },
      businessscopestring: "",
      isCreat: 1,
      goodsCategoryName: "/",
      basicForm: {
        name: "",
        commonName: "",
        specification: "",
        pinyin: "",
        unit: "",
        brand: "",
        category: 0,
        standardCode: "",
        barCode: "",
        executionStandard: "",
        healthCode: "",
        placeOrigin: "",
        approvalNumber: "",
        dosageForm: "",
        businessScopeId: "",
        manufacturer: "",
        marketingAuthorizationHolder: "",
        shortName: "",
        marketingAuthorizationHolderAddress: "",
        productionAddress: "",
        isCollect: 0,
        isOtc: 1,
        traceCode: 0,
        otcType: 1,
        pictureUrlList: [],
        goodsCategoryIdList: [[]],
        externalId: "",
      },
    };
  },
  computed: {
    ...commonIndex.mapState(["BasissCopeData"]),
  },
  created() {
    this.isCreat = this.$route.query.type;
  },
  filters: {
    category(v) {
      switch (v) {
        case 0:
          return "普药";
        case 1:
          return "中药";
        case 2:
          return "非药品";
      }
    },
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    ...mapActions(["postgoodsGetCategoryById", "getPlatformGoodsDetail"]),
    toBack() {
      window.history.go(-1);
    },
    async getDetails() {
      let id = this.$route.query.id;
      let data = await this.getPlatformGoodsDetail({ id: id });
      this.basicForm = Object.assign({}, data.content);
      this.getCategory();
    },
    async getCategory() {
      let strCategory = [];
      let res = await this.postgoodsGetCategoryById(
        this.basicForm.goodsCategoryIdList
      );
      if (res.code == "000000") {
        strCategory = res.content.flat();
        // console.log(strCategory,'11111111');
        this.goodsCategoryName = strCategory.join("/");
        // console.log(this.goodsCategoryName,'2222222222');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
.basicGoodsInfo {
  padding-top: 20px;
  .goods-info {
    display: flex;
    justify-content: space-between;

    .info-left {
      width: 48%;
    }
    .info-rigth {
      width: 48%;
    }
    .goods-img {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      padding-left: 10px;
      margin-top: 10px;
    }
    .title {
      margin-top: 10px;
      width: 100%;
      font-weight: bold;
      padding-bottom: 16px;
      margin-bottom: 10px;
      border-bottom: dashed 1px #ddd;
      display: flex;
      align-items: center;
    }
    .title::before {
      width: 4px;
      height: 16px;
      background-color: #06b7ae;
      border-radius: 10px;
      display: inline-block;
      content: "";
      margin-right: 10px;
      margin-bottom: -2px;
    }
  }
  .btn-box {
    cursor: pointer;
    margin-top: 3%;
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      width: 90px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      font-weight: normal;
    }
    .pass {
      margin-left: 15px;
      background: #06b7ae;
      color: #ffffff;
    }
    .refuse {
      background: #f2f2f2;
      color: #999999;
    }
  }
  .lable {
    padding: 20px;
    font-size: 15px;
    color: #333;
    .text {
      margin-left: 20px;
    }
  }
  .loading-text {
    margin-top: 20px;
    font-size: 16px;
    // font-weight: bold;
    text-align: center;
  }
}
</style>
