<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{ background: '#F2F3F5' }"
      border
    >
      <el-table-column
        align="center"
        label="序号"
        type="index"
        :index="indexMethod"
        width="70"
      />
      <el-table-column
        prop="orderId"
        label="订单号"
        align="center"
        width="200"
      />
      <el-table-column
        prop="groupId"
        label="主订单号"
        align="center"
        width="200"
      />
      <el-table-column
        prop="orderGroupId"
        label="订单批次号"
        align="center"
        width="200"
      />
      <el-table-column
        prop="supplierSeparateAmount"
        label="供应商分账金额"
        align="center"
        width="200"
      />

      <el-table-column
        prop="purchaserSeparateAmount"
        label="采购商分账金额"
        align="center"
        width="190"
      />
      <el-table-column
        prop="platformSeparateAmount"
        label="平台分账金额"
        align="center"
        width="170"
      />
      <el-table-column
        prop="payableAmount"
        label="支付金额"
        align="center"
        width="170"
      />
      <el-table-column
        prop="totalAmount"
        label="销售货款"
        align="center"
        width="170"
      />
      <el-table-column prop="freightAmount" label="运费" align="center" />
      <el-table-column
        prop="refundAmount"
        label="退货退款"
        align="center"
        width="170"
      />
      <el-table-column
        prop="purchaserGoodsAmount"
        label="采购商货款"
        align="center"
        width="170"
      />
      <el-table-column
        prop="settlementAmount"
        label="结算金额"
        align="center"
        width="170"
      />
      <el-table-column
        prop="platformFee"
        label="平台手续费"
        align="center"
        width="170"
      />
      <el-table-column
        prop="purchaserSubsidyFee"
        label="采购商补贴手续费"
        align="center"
        width="170"
      />
      <el-table-column
        prop="supplier"
        label="供应商"
        align="center"
        width="170"
      />
      <el-table-column
        prop="customer"
        label="客户名称"
        align="center"
        width="170"
      />
      <el-table-column prop="deliveryService" label="订单类型" align="center" />
      <el-table-column
        prop="paymentMethod"
        label="订单支付方式"
        align="center"
        width="170"
      />
      <el-table-column
        prop="orderDeliveryTime"
        label="订单发货时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="settlementNo"
        label="结算单号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="separateStatus"
        label="分账状态"
        align="center"
        width="170"
      />
      <el-table-column
        prop="withdrawNo"
        label="提现流水号"
        align="center"
        width="170"
      />
      <el-table-column prop="withdrawStatus" label="提现状态" align="center" />
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    queryParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    }
  }
};
</script>
<style lang="scss" scoped></style>
