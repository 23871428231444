<template>
  <div class="merch-card">
    <Message />
  </div>
</template>

<script>
import Message from "./merchCOM/message.vue";
export default {
  name: "",
  components: { Message },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
