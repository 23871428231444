<template>
  <div class="box">
    <div class="message-box scorr-roll">
      <div class="fx">
        <div class="titlxtd">企业基础信息</div>
        <el-popover
          placement="bottom"
          width="550"
          trigger="click"
          v-if="$route.query.type == 1"
        >
          <div>
            <el-form ref="form" :model="queryParams" :inline="true">
              <el-form-item>
                <el-date-picker
                  size="small"
                  style="width: 300px"
                  v-model="queryParams.datelist"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期 "
                  end-placeholder="结束日期"
                  unlink-panels
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" @click="handleQuery"
                  >查 找</el-button
                >
              </el-form-item>
            </el-form>
            <el-table
              v-loading="loading"
              :data="dataList"
              :header-cell-style="{ background: '#F2F3F5' }"
            >
              <el-table-column
                prop="serialNumber"
                align="center"
                label="编号"
                width="220"
              />
              <el-table-column
                prop="examineTime"
                align="center"
                label="资料日期"
              />
              <el-table-column prop="" align="center" label="" width="70">
                <template slot-scope="scope">
                  <span class="btsed" @click="Viewqualifica(scope.row)"
                    >查看</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.page"
              :limit.sync="queryParams.perPage"
              @pagination="getList"
              small
            />
          </div>
          <div class="candsc" slot="reference">查看往期资质</div>
        </el-popover>
      </div>
      <!-- 文本信息 -->
      <div class="">
        <div>
          <el-descriptions :column="2">
            <el-descriptions-item label="企业名称"
              >{{ businessInfo.name }}
            </el-descriptions-item>
            <el-descriptions-item label="简称"
              >{{ businessInfo.abbreviation }}
            </el-descriptions-item>
            <el-descriptions-item label="注册地"
              >{{ businessInfo.provinceName }}{{ businessInfo.cityName
              }}{{ businessInfo.districtName
              }}{{ businessInfo.address }}</el-descriptions-item
            >
            <el-descriptions-item label="法   人">
              {{ businessInfo.legalEntity }}
            </el-descriptions-item>
            <el-descriptions-item label="联系电话 ">
              {{ businessInfo.telephone }}
            </el-descriptions-item>
            <el-descriptions-item
              label="企业类型"
              v-if="businessInfo.content != null"
            >
              <span class="businessTypeName">
                {{ businessInfo.businessTypeName }}</span
              >
              <span
                class="business_type"
                v-for="(v, i) in businessInfo.typeName"
                :key="i"
                >{{ v }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              label="证件类型"
              v-if="businessInfo.content != null"
            >
              <span
                v-text="
                  businessInfo.unityType == '1' ? '三证合一' : '非三证合一'
                "
              ></span>
            </el-descriptions-item>
            <el-descriptions-item
              label="签章类型"
              v-if="businessInfo.content != null"
            >
              公章
            </el-descriptions-item>
          </el-descriptions>
          <div class="supplier-box">
            <div>
              <span>签章名称</span>
              <div>{{ businessInfo.sealName }}</div>
            </div>
            <div>
              <span>商家公告</span>
              <div>{{ businessInfo.desc || "暂无公告" }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 资质信息-title -->
      <!-- <div class="universal-title" v-if="businessInfo.content != null">
        证件资料
        <span @click="toDown" class="to-down"
          ><img src="../../../assets/img/todown.png" alt="" />
          下载以下所有证件</span
        >
      </div> -->
      <div v-if="businessInfo.content != null" style="padding-bottom: 70px">
        <RecordDetails
          ref="Recordes"
          :recorItem="recorItem"
          :Editonser="$route.query.type == 1 ? false : true"
          :showsteds="false"
        />
      </div>
    </div>
    <div class="operate-btn absbtn" v-if="!$route.query.type == 1">
      <div class="save1" @click="$router.push({ path: '/merchantCenterList' })">
        返回
      </div>
      <div
        v-if="routType == 'toreview'"
        class="save2"
        style="margin-left: 10px"
        @click="reject"
      >
        驳回
      </div>
      <div class="save" style="margin-left: 10px" @click="save">
        {{ routType == "toreview" ? "审核通过" : "保存" }}
      </div>
    </div>
    <div class="operate-btn absbtn" v-else>
      <div class="save1" @click="$router.push({ path: '/merchantCenterList' })">
        返回
      </div>
    </div>
    <!-- 审核驳回提醒弹窗 -->
    <el-dialog
      title="审核驳回 内容"
      :visible.sync="dialogVisible"
      width="430px"
    >
      <div style="margin-bottom: 20px; line-height: 20px">
        确定驳回当前供应商资质审核？如果当前供应商资质证照不符合平台要求，请在驳回原因中注明，且可设置相关要求。
      </div>
      <div class="fx">
        <span style="flex: 2">原因：</span>
        <el-input
          style="flex: 7"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 3 }"
          maxlength="200"
          placeholder="例：证件法人和企业法人不符"
          v-model="rejectReason.remark"
          show-word-limit
        >
        </el-input>
      </div>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="assdistribued">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UpQualification from "./UpQualification.vue";
import RecordDetails from "@/components/RecordDetails/RecordDetails.vue";
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const Audit = createNamespacedHelpers("Audit");
import {
  addReviewRecord, //新增复核记录
} from "@/api/apiAll/phpUrl.js";
export default {
  data() {
    return {
      searchData: {},
      loading: false,
      //往期资质搜索条件
      queryParams: {
        page: 1,
        perPage: 10,
        datelist: [],
      },
      //往期资质数据
      dataList: [],
      total: 0,
      recorItem: {
        examineBaseInfo: {}, //企业基础信息
        qualification: [], //资质数据
        companyTypeInfo: [], //企业类型数据
        scopeInfo: [], //经营范围数据
      },
      recorItemcopy: {},
      routType: "", //传入类型

      dialogVisible: false, //驳回弹窗
      rejectReason: {
        remark: "", //驳回原因
      },
    };
  },
  components: {
    UpQualification,
    RecordDetails,
  },
  computed: {
    ...merchantCenterList.mapState(["businessInfo"]),
  },
  created() {
    if (this.$route.query.routType) {
      this.routType = this.$route.query.routType;
    }
    this.getList();
    this.getItem();
  },
  methods: {
    ...Audit.mapActions([
      "getreviewgetDownLoad",
      "getv2download",
      "qualificationExamineList", //往期资质
      "postscopeConfig", //保存经营范围
    ]),
    ...merchantCenterList.mapActions([
      "getCredentials", //审核记录详情（资质详情）
    ]),
    async toDown() {
      let data = await this.getv2download({ uid: this.$route.query.id });
      if (data.code == 200) {
        window.open(data.data);
        //console.log(data);
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    /** 获取详情数据 */
    getItem() {
      this.getCredentials({
        businessId: this.$route.query.id,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        isBusinessInfo: this.$route.query.isBusinessInfo,
      }).then((res) => {
        Object.assign(this.recorItem, res);
        this.recorItemcopy = JSON.parse(JSON.stringify(res));
        this.$nextTick(() => {
          this.$refs.Recordes.getItem();
        });
      });
    },
    getList() {
      var queryParams = {
        ...this.queryParams,
      };
      queryParams.businessId = this.$route.query.id;
      if (queryParams.datelist) {
        queryParams.startTime = queryParams.datelist[0];
        queryParams.endTime = queryParams.datelist[1];
        delete queryParams.datelist;
      }
      this.loading = true;
      this.qualificationExamineList(queryParams).then((res) => {
        this.loading = false;
        this.dataList = res.data.data;
        this.total = res.data.total;
      });
    },
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 跳转记录详情 */
    Viewqualifica(row) {
      this.$router.push({
        path: "/detailsView",
        query: {
          businessId: this.$route.query.id,
          auditSerialNumber: row.serialNumber,
        },
      });
    },
    async getdataList(type) {
      if (type == "rejecing") {
        var num = this.recorItemcopy.scopeInfo.filter((v) => {
          return v.isSetVal != 2;
        });
      } else {
        var num = this.recorItem.scopeInfo.filter((v) => {
          return v.isSetVal != 2;
        });
      }
      if (type != "rejecing" && num.length == 0) {
        this.$message.error("请选择经营范围");
        return false;
      }
      let notime = num.filter((v) => {
        return v.expirationTime == null || v.expirationTime == "";
      });
      if (notime.length > 0) {
        this.$message.error(`请选择 ${notime[0].businessScopeName}有效期`);
        return false;
      }
      let params = JSON.stringify(num);
      params = JSON.parse(params);
      var dataList = [];
      params.forEach((v) => {
        dataList.push({
          business_scope_id: v.businessScopeId,
          business_id: this.$route.query.id,
          expiration_time: v.expirationTime,
          select_id: v.selectId,
        });
      });
      return dataList;
    },
    /** 保存、复核通过 */
    async save() {
      let dataList = await this.getdataList();
      if (!dataList) return;
      if (this.routType == "toreview") {
        this.$confirm(
          `确定通过当前供应商的资质审核？请您仔细核对供应商信息，以保证供应商资质符合要求且填写无误。`,
          "审核通过提醒",
          {
            closeOnClickModal: false,
            cancelButtonText: "取消",
            confirmButtonText: "审核通过",
          }
        )
          .then(() => {
            this.preservation(dataList);
          })
          .catch(() => {});
      } else {
        await this.preservation(dataList);
      }
    },
    async preservation(dataList) {
      const loading = this.$loading({
        lock: true,
        text: "保存中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var res = await this.postscopeConfig({
        scopeInfo: dataList,
        auditSerialNumber: this.$route.query.auditSerialNumber,
      });
      loading.close();
      if (res.code == "200") {
        if (this.routType == "toreview") {
          var res1 = await addReviewRecord({
            businessId: this.$route.query.id,
            auditSerialNumber: this.$route.query.auditSerialNumber,
            reviewStatus: 1,
            scopeInfo: dataList,
            remark: "",
          });
          if (res1.code == 200) {
            this.$router.push({
              path: "/merchantCenterList",
            });
          }
        }
        this.$message.success("保存成功");
      }
    },
    /** 驳回弹窗 */
    reject() {
      Object.assign(this.rejectReason, this.$options.data().rejectReason);
      this.dialogVisible = true;
    },
    /** 驳回 */
    async assdistribued() {
      let dataList = await this.getdataList("rejecing");
      if (!dataList) return;
      var res = await addReviewRecord({
        businessId: this.$route.query.id,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        reviewStatus: 0,
        remark: this.rejectReason.remark,
        scopeInfo: dataList,
      });
      if (res.code == "200") {
        this.$message.success("驳回成功");
        this.$router.push({
          path: "/merchantCenterList",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  max-height: 70vh;
  // padding-top: 40px;
  padding-left: 20px;
  .qualification-title {
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    height: 50px;
    color: #00bfbf;
    border-bottom: 1px solid #797979;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .qualification-message {
    margin-top: 10px;
    width: 100%;
    .title {
      width: 150px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4 px;
      background: #00bfbf;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .qualification-message-img-box {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .makeOut {
    padding-left: 5%;
    padding-right: 5%;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .makeout-box {
      margin-top: 10px;
      width: 40%;
      height: 40px;
      display: flex;
      .sch-1 {
        width: 80%;
        min-width: 200px;
        display: flex;
        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
    }
  }
}
.businessTypeName {
  border: 1px solid #ccc;
  line-height: 20px;
  font-size: 14px;
  padding: 0 8px;
  height: 24px;
  display: inline-block;
  border-radius: 4px;
  margin-right: 5px;
}
.business_type {
  line-height: 20px;
  font-size: 14px;
  padding: 0 8px;
  height: 24px;
  display: inline-block;
  border-radius: 4px;
  margin-right: 5px;
  color: #00bfbf;
  background-color: rgba($color: #00bfbf, $alpha: 0.09);
}
::v-deep .el-descriptions-item__content {
  flex: 1;
  color: #333;
  font-size: 14px;
}
::v-deep .el-descriptions-item__label {
  width: 80px !important;
  color: #666;
  margin-right: 20px;
  font-size: 14px;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
.box {
  position: relative;
  height: 100%;
}
.to-down {
  font-size: 12px;
  color: #06b7ae;
  font-weight: normal;
  margin-left: 10px;
  cursor: pointer;
  img {
    width: 13px;
    height: 13px;
  }
}
.supplier-box {
  display: flex;
  margin-bottom: 20px;
  > div {
    width: 50%;
    display: flex;
    color: #666;
    font-size: 14px;
    span {
      width: 80px;
      text-align: end;
      margin-right: 20px;
    }
    > div {
      flex: 1;
      color: #333;
    }
  }
}
.candsc {
  width: 120px;
  font-size: 17px;
  color: #06b7ae;
  cursor: pointer;
}
</style>
