<template>
  <div class="upload-wrap">
    <el-form :inline="true">
      <el-form-item :label="labeltxt" style="position: relative">
        <el-upload
          :action="baseURL + pathUrl"
          :headers="token"
          :show-file-list="true"
          :before-upload="handleBeforeUpload"
          :on-success="
            (response, file) => {
              upSuccess(response, file);
            }
          "
          :limit="1"
          :on-error="
            (err, file) => {
              upError(err, file);
            }
          "
          :data="Updata"
          :on-change="onChange"
          :on-remove="onRemove"
          style="display: inline-block"
          :auto-upload="false"
          ref="upload"
        >
          <el-button>上传文件</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <slot> </slot>
    <div style="text-align: right">
      <el-button @click="closting()">取 消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { downLoadZip } from "@/utils/zipdownload";
export default {
  name: "uploads", //导入文件
  data() {
    return {
      isFile: false, //是否选择商品文件
      token: { token: getToken() },
      uploading: null,
    };
  },
  props: {
    // 上传文件
    labeltxt: {
      type: String,
      default: "上传文件",
    },
    // 导入地址
    baseURL: {
      type: String,
      default: "",
    },
    // 导入路径
    pathUrl: {
      type: String,
      default: "",
    },
    //是否有其他入参
    confirmed: {
      type: Boolean,
      default: false,
    },
    // 导入接口的其他入参
    Updata: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // 本地文件移除
    onRemove(files) {
      this.isFile = false;
      // this.availableSupplierForm.shangcsp = {};
    },
    // 本地添加文件
    onChange(file, fileList) {
      if (fileList.length > 0) {
        this.isFile = true;
      } else {
        this.isFile = false;
      }
    },
    //导入接口失败
    upError(err, file) {
      this.$message.error(JSON.parse(err.message).message);
      this.uploading.close();
    },
    // 导入接口成功后
    upSuccess(response) {
      if (response.code == "000000" || response.code == 200) {
        this.uploading.close();
        this.closting();
      } else {
        this.$refs.upload.clearFiles();
        this.uploading.close();
        this.$message.error({
          showClose: true,
          duration: 0,
          message: response.message,
        });
      }
      this.isFile = false;
    },
    //上传前
    handleBeforeUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isXls = file.type === "application/vnd.ms-excel";
      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      const is10M = file.size / 1024 / 1024 < 10;
      //限制文件上传类型
      //   if (!isXlsx && !isXls) {
      //     this.$message.error(
      //       "导入失败，文件格式不正确，请确认文件格式为.xls或.xlsx。"
      //     );
      //     return false;
      //   }
      //限制文件上传大小
      if (!is10M) {
        this.$message.error("导入失败，文件大小不能超过10MB。");
        return false;
      }
      this.uploading = this.$loading({
        lock: true,
        text: "导入中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return true;
    },
    // 导入保存
    confirm() {
      if (!this.isFile) {
        this.$message.error("请上传文件");
      } else {
        if (this.confirmed) {
          this.$emit("confirming");
        } else {
          this.upconfirm();
        }
      }
    },
    upconfirm() {
      this.$refs.upload.submit();
    },
    // 取消
    closting() {
      this.$refs.upload.clearFiles();
      this.isFile = false;
      this.$emit("closting"); //回调
    },
  },
};
</script>

<style lang="scss" scoped></style>
