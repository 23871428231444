<template>
  <div>
    <el-dialog
      title="获取批件"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="70vw"
      style="margin-top: -10vh"
    >
      <div class="headone">
        <div v-if="detailsData.pictureUrlList">
          <el-image
            class="image"
            :src="handleImageError(detailsData.pictureUrlList[0])"
            :preview-src-list="detailsData.pictureUrlList"
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
        <div class="text">
          <div>商品名称：{{ detailsData.name }}</div>
          <div>单位：{{ detailsData.unit }}</div>
          <div>剂型：{{ detailsData.dosageForm }}</div>
          <div>批准文号：{{ detailsData.approvalNumber }}</div>
          <div>经营类别：{{ detailsData.businessscopestring }}</div>
          <div>商品编号：{{ detailsData.id }}</div>
          <div>厂家：{{ detailsData.manufacturer }}</div>
          <div>规格： {{ detailsData.specification }}</div>
          <div>条形码（69码）：{{ detailsData.barCode }}</div>
        </div>
      </div>
      <div class="huixian"></div>
      <div v-if="!detailshow">
        <!-- 顶部输入框 -->
        <el-form
          ref="queryForm"
          :model="queryParams"
          :inline="true"
          class="fx"
          style="justify-content: space-between"
          @keyup.enter.native="handleQuery"
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <div>
            <el-form-item prop="barCode">
              <el-input
                size="mini"
                v-model="queryParams.barCode"
                placeholder="69码（精准）"
                clearable
              />
            </el-form-item>
            <el-form-item prop="specification">
              <el-input
                size="mini"
                v-model="queryParams.specification"
                placeholder="规格（模糊）"
                clearable
              />
            </el-form-item>
            <el-form-item prop="licenseNumber">
              <el-input
                size="mini"
                v-model="queryParams.licenseNumber"
                placeholder="批准文号（模糊）"
                clearable
              />
            </el-form-item>
            <el-form-item prop="manufacturerName">
              <el-input
                size="mini"
                v-model="queryParams.manufacturerName"
                placeholder="厂家名称（模糊）"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="handleQuery"
                >查 询</el-button
              >
              <el-button size="mini" type="primary" @click="Reset"
                >重 置</el-button
              >
            </el-form-item>
          </div>
          <div class="fx" style="align-items: right; margin-top: 10px">
            <el-checkbox size="mini" v-model="yijianjins" @change="yijianjingd"
              >一键精准筛选</el-checkbox
            >
          </div>
        </el-form>
        <!-- 表格数据 -->
        <el-table
          v-loading="loading"
          :data="dataList"
          border
          style="width: 100%; margin-top: -15px"
          :header-cell-style="{ background: '#F2F3F5' }"
          height="380px"
        >
          <el-table-column
            align="center"
            label="序号"
            type="index"
            :index="indexMethod"
            width="100"
          />
          <el-table-column prop="name" label="商品名称" align="center" />
          <el-table-column
            prop="manufacturerName"
            label="厂家"
            align="center"
          />
          <el-table-column prop="specification" label="规格" align="center" />
          <el-table-column prop="unit" label="单位" align="center" />
          <el-table-column prop="piece" label="件装量" align="center" />
          <el-table-column
            prop="licenseNumber"
            label="批准文号"
            align="center"
          />
          <el-table-column prop="barCode" label="条形码" align="center" />
          <el-table-column
            prop="businessCategory"
            label="经营类别"
            align="center"
          />
          <el-table-column prop="dosageForm" label="剂型" align="center" />
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope"
              >{{
                scope.row.status == -1
                  ? "已废弃"
                  : scope.row.status == 0
                  ? "待审核"
                  : "已归档"
              }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <span class="btsed" @click="details(scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          size="mini"
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
      <div v-else>
        <div class="zhutxt el-icon-arrow-left curpoin" @click="toselection">
          返回重新选择
        </div>
        <div>
          <el-table
            v-loading="loading"
            :data="detaiList"
            border
            style="width: 100%; margin-top: 15px"
            :header-cell-style="{ background: '#F2F3F5' }"
            max-height="400px"
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              width="70"
            />
            <el-table-column prop="" label="批件文件名称" align="center">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.name"
                  placeholder="69码（精准）"
                  maxlength="50"
                  clearable
                />
              </template>
            </el-table-column>
            <el-table-column prop="" label="有效期" align="center">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.endTime"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
              <template slot-scope="scope">
                <span class="btsed" @click="Preview(scope.row.documentUrl)"
                  >预览</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button size="mini" @click="dialogVisible = false">关 闭</el-button>
        <el-button size="mini" v-if="detailshow" type="primary" @click="obtain"
          >获 取</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getApprovedDoc, //获取批件列表
  uploadApprovedDocBatch, //获取批件批量上传
} from "@/api/apiAll/javaUrl.js";
export default {
  name: "",
  props: {
    //基本信息
    detailsData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      yijianjins: false, //一键精准筛选
      queryParams: {
        id: "",
        page: 1,
        pageSize: 10,
        barCode: "", //69码（精准）
        specification: "", //规格（模糊）
        licenseNumber: "", //批准文号（模糊）
        manufacturerName: "", //厂家名称（模糊）
        name: "",
      },
      total: 0, //条数
      dataList: [], //列表数据
      detailshow: false,

      detaiList: [], //详情列表
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    };
  },
  created() {},
  methods: {
    getItems() {
      this.dialogVisible = true;
      this.detailshow = false;
      this.yijianjins = false;
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
    //序号
    indexMethod(index) {
      return (
        index + (this.queryParams.page - 1) * this.queryParams.pageSize + 1
      );
    },
    /** 查询列表数据 */
    async getList() {
      this.queryParams.id = this.$route.query.id;
      if (!this.yijianjins) {
        this.queryParams.name = this.detailsData.name;
      }
      this.loading = true;
      await getApprovedDoc(this.queryParams).then((response) => {
        this.dataList = response.content.list;
        this.total = response.content.total;
        this.loading = false;
      });
    },
    /** 搜索按键操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 一键精准搜索 */
    yijianjingd() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      if (this.yijianjins) {
        this.queryParams.barCode = this.detailsData.barCode;
        this.queryParams.licenseNumber = this.detailsData.approvalNumber;
        // this.queryParams.specification = this.detailsData.specification;
        // this.queryParams.manufacturerName = this.detailsData.manufacturer;
      }
      this.handleQuery();
    },
    /** 重置 */
    Reset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.yijianjins = false;
      this.getList();
    },
    /** 详情 */
    details(row) {
      this.detaiList = [...row.documentUrl];
      this.detailshow = true;
    },
    /** 预览 */
    Preview(row) {
      window.open(row);
    },
    /** 返回重新选择 */
    toselection() {
      this.detailshow = false;
      this.getList();
    },
    /** 获取 */
    async obtain() {
      if (this.detailsData.existSource == 1) {
        this.$confirm(
          `
        <div class="el-icon-warning yellos">此品种过往已同步过批件</div>
        <div style='color: red'>再次获取将自动清空过往系统同步批件</div>
        `,
          "重新获取",
          {
            closeOnClickModal: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showClose: false,
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            await this.getuploadDoc();
          })
          .catch(function () {});
      } else {
        await this.getuploadDoc();
      }
    },
    /**获取批件批量上传  */
    async getuploadDoc() {
      var data = {};
      data.operatorId = sessionStorage.getItem("admin_id");
      data.platformGoodsId = this.$route.query.id;
      data.documentUrl = this.detaiList;
      let res = await uploadApprovedDocBatch(data);
      if (res.code == "000000") {
        this.dialogVisible = false;
        this.$message.success("操作成功");
        this.$parent.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.headone {
  display: flex;
  .image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  .text {
    flex: 1;
    color: #777;
    display: flex;
    flex-wrap: wrap;
    div {
      width: 30%;
      padding-right: 20px;
      margin-bottom: 15px;
    }
  }
}
.huixian {
  width: calc(100% + 40px);
  margin: 20px -20px;
  height: 10px;
  background-color: #eee;
}
</style>
