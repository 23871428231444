<template>
  <div class="merch">
    <!-- 搜索列 -->
    <div class="fx" style="justify-content: space-between">
      <el-form
        ref="queryParams"
        :model="queryParams"
        :inline="true"
        @keyup.enter.native="handleQuery"
      >
        <el-form-item>
          <el-select
            clearable
            v-model="queryParams.auditSataus"
            placeholder="状态"
          >
            <el-option label="通过" :value="1"></el-option>
            <el-option label="拒绝" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="queryParams.matchStatus"
            placeholder="对码结果"
          >
            <el-option label="对码成功" :value="1"></el-option>
            <el-option label="对码失败" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="queryParams.articleNumber"
            placeholder="商品货号/名称"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery" style="width: 80px"
            >查 询</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <div style="text-align: right">
          <el-button type="primary" :loading="newloading" @click="UpdateCode">
            更新对码
          </el-button>
        </div>
        <div style="margin: 10px 0px">
          最近更新对码时间：{{ detailItem.lastUpdateTime }}
        </div>
      </div>
    </div>
    <el-table
      :data="dataList"
      v-loading="loading"
      border
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column prop="id" label="编号" align="center" />
      <el-table-column label="商品信息" align="center" width="350">
        <template slot-scope="{ row }">
          <div class="fx">
            <div v-if="row.pictureUrl">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="handleImageError(row.pictureUrl[0])"
                :preview-src-list="row.pictureUrl"
              >
              </el-image>
            </div>
            <div
              style="
                text-align: left;
                font-size: 12px;
                color: #777;
                line-height: 20px;
              "
            >
              <div style="font-size: 14px; color: #000">
                {{ row.goodsName }}
              </div>
              <div>规格：{{ row.specification }}</div>
              <div>厂家：{{ row.manufacturer }}</div>
              <div>产地：{{ row.placeOrigin }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="approvalNumber" label="批准文号" align="center" />
      <el-table-column prop="dosageForm" label="剂型" align="center" />
      <el-table-column prop="" label="对码结果" align="center">
        <template slot-scope="{ row }">
          <div>
            <span v-if="row.matchStatus == 0" style="color: #f34444">
              对码失败
            </span>
            <span v-else>
              <span style="color: #3dbb2b">对码成功</span>
              <span>({{ row.matchStatus == 1 ? "自动" : "手动" }})</span>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="资料库信息" align="center" width="350">
        <template slot-scope="{ row }">
          <div class="fx">
            <div v-if="row.platformgoodspictureurl">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="handleImageError(row.platformgoodspictureurl[0])"
                :preview-src-list="row.platformgoodspictureurl"
              >
              </el-image>
            </div>
            <div
              style="
                text-align: left;
                font-size: 12px;
                color: #777;
                line-height: 20px;
              "
            >
              <div style="font-size: 14px; color: #000">
                {{ row.platformGoodsInfoName }}
              </div>
              <div>规格：{{ row.platformGoodsInfoSpecification }}</div>
              <div>厂家：{{ row.platformGoodsInfoManufacturer }}</div>
              <div>批准文号：{{ row.platformGoodsInfoApprovalNumber }}</div>
              <div>产地：{{ row.platformGoodsInfoPlaceOrigin }}</div>
              <div>资料库ID：{{ row.platformGoodsInfoId }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="审核结果" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.auditStatus"
            active-color="#13ce66"
            inactive-color="#cccccc"
            :active-value="1"
            :inactive-value="2"
            :disabled="detailItem.submitStatus == 1"
            @change="changeStatus(row.id, row.auditStatus)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="refuseReason" label="拒绝原因" align="center" />
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="{ row }">
          <span
            v-if="detailItem.submitStatus == 0"
            class="btsed"
            @click="edit(row)"
            >编辑</span
          >
          <span v-else>——</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <div class="boombtn">
      <div>
        共{{ detailItem.totalNum }}个品种，审核通过{{ detailItem.passNum }}个
      </div>
      <el-button
        v-if="detailItem.submitStatus == 0"
        type="primary"
        :disabled="btnloading || newloading"
        @click="submit"
      >
        {{
          !btnloading && !newloading
            ? "提 交"
            : !newloading && btnloading
            ? "提交中"
            : "对码中"
        }}
      </el-button>
    </div>
    <!-- 拒绝弹框 -->
    <el-dialog
      title="拒绝原因"
      :visible.sync="isRefuse"
      :before-close="dialogpay"
      :close-on-click-modal="false"
      width="500px"
    >
      <div>
        <el-input
          type="textarea"
          :rows="7"
          placeholder="请输入内容"
          v-model="AuditMessage.remark"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogpay">取 消</el-button>
        <el-button type="primary" @click="notarizeRefuse">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 匹配结果弹窗 -->
    <el-dialog
      title="匹配结果"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="970px"
    >
      <div class="headrt">
        <div class="headone">
          <div v-if="delitItem.matchStatus == 0">
            <div v-if="delitItem.pictureUrl">
              <el-image
                class="image"
                :src="handleImageError(delitItem.pictureUrl[0])"
                :preview-src-list="delitItem.pictureUrl"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div class="text">
              <div class="title">{{ delitItem.goodsName }}</div>
              <div style="line-height: 20px">
                <div>规格：{{ delitItem.specification }}</div>
                <div>厂家:{{ delitItem.manufacturer }}</div>
                <div>经营类别：{{ delitItem.goodsInfoBusinessScopeStr }}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="delitItem.platformgoodspictureurl">
              <el-image
                class="image"
                :src="handleImageError(delitItem.platformgoodspictureurl[0])"
                :preview-src-list="delitItem.platformgoodspictureurl"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div class="text">
              <div class="title">{{ delitItem.platformGoodsInfoName }}</div>
              <div style="line-height: 20px">
                <div>规格：{{ delitItem.platformGoodsInfoSpecification }}</div>
                <div>厂家:{{ delitItem.platformGoodsInfoManufacturer }}</div>
                <div>
                  经营类别：{{ delitItem.platformGoodsInfoBusinessScopeStr }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="headtwo">
          <div>
            <span v-if="delitItem.matchStatus == 0" style="color: #f34444">
              对码失败
            </span>
            <span v-else>
              <span style="color: #3dbb2b">对码成功</span>
              <span>({{ delitItem.matchStatus == 1 ? "自动" : "手动" }})</span>
            </span>
          </div>
          <div>资料库ID：{{ delitItem.platformGoodsInfoIdonst }}</div>
        </div>
      </div>
      <div style="margin: 20px 0">
        <el-radio-group
          v-model="delitItem.statedtoud"
          @change="getItem"
          clearable
        >
          <el-radio :label="1">资料库有品种-选择对应编号</el-radio>
          <el-radio :label="2">资料库没有品种-自动建档</el-radio>
        </el-radio-group>
      </div>
      <div v-if="delitItem.statedtoud == 1">
        <el-form
          ref="queryOnset"
          :model="queryOnset"
          :inline="true"
          @keyup.enter.native="getoneItem"
        >
          <el-form-item>
            <el-input
              v-model="queryOnset.namegjiama"
              placeholder="名称/69码/国家码"
              size="small"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryOnset.localSpecification"
              placeholder="规格"
              size="small"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="queryOnset.chanlocalLicenseNumber"
              placeholder="厂家/批准文号"
              size="small"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getoneItem" size="small">
              搜 索
            </el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="onsetList"
          v-loading="loading"
          border
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <el-table-column prop="" label="单选" align="center" width="50">
            <template slot-scope="{ row }">
              <el-radio
                class="radio"
                :label="row.uniqueId"
                v-model="delitItem.platformGoodsInfoId"
              >
                &emsp;
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column label="资料库ID" align="center" prop="uniqueId" />
          <el-table-column label="名称" align="center" prop="localGoodsName" />
          <el-table-column
            label="规格"
            align="center"
            prop="localSpecification"
          />
          <el-table-column
            label="厂家"
            align="center"
            prop="localManufacturer"
          />
          <el-table-column
            label="产地"
            align="center"
            prop="localPlaceOrigin"
          />
          <el-table-column
            label="批准文号"
            align="center"
            prop="localLicenseNumber"
          />
          <el-table-column
            label="经营类别"
            align="center"
            prop="businessType"
          />
          <el-table-column
            label="一级分类"
            align="center"
            prop="categoryName"
          />
        </el-table>
      </div>
      <div v-if="delitItem.statedtoud == 2">
        <div class="twocss">
          <el-form
            :model="ItemTwo"
            label-width="140px"
            :rules="ruletwo"
            ref="formtwo"
            :inline="true"
          >
            <div class="fx">
              <div>
                <el-form-item label="商品名称" prop="goodsName">
                  <el-input
                    v-model="ItemTwo.goodsName"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="通用名" prop="commonName">
                  <el-input
                    v-model="ItemTwo.commonName"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="拼音名" prop="pinyin">
                  <el-input
                    v-model="ItemTwo.pinyin"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="规格" prop="specification">
                  <el-input
                    v-model="ItemTwo.specification"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="批准文号" prop="approvalNumber">
                  <el-input
                    v-model="ItemTwo.approvalNumber"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="剂型" prop="dosageForm">
                  <el-input
                    v-model="ItemTwo.dosageForm"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="单位" prop="unit">
                  <el-input
                    v-model="ItemTwo.unit"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="生产许可证" prop="productLice">
                  <el-input
                    v-model="ItemTwo.productLice"
                    size="small"
                    clearable
                    placeholder="请输入生产许可证"
                    maxlength="30"
                  />
                </el-form-item>
                <el-form-item label="注册编号" prop="regNo">
                  <el-input
                    v-model="ItemTwo.regNo"
                    size="small"
                    clearable
                    placeholder="请输入注册编号"
                    maxlength="30"
                  />
                </el-form-item>
                <el-form-item label="型号" prop="model">
                  <el-input
                    v-model="ItemTwo.model"
                    size="small"
                    clearable
                    placeholder="请输入型号"
                    maxlength="30"
                  />
                </el-form-item>
                <el-form-item label="产地" prop="placeOrigin">
                  <el-input
                    v-model="ItemTwo.placeOrigin"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="品牌" prop="brand">
                  <el-input
                    v-model="ItemTwo.brand"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="经营类别" prop="businessScope">
                  <el-select
                    size="small"
                    v-model="ItemTwo.businessScope"
                    placeholder="请选择相关证明"
                    @change="changeBusiness"
                  >
                    <el-option
                      v-for="(item, i) in basisList"
                      :key="i"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="商品分类" prop="goodsClassification">
                  <el-select
                    size="small"
                    v-model="ItemTwo.goodsClassification"
                    placeholder="请选择商品分类"
                  >
                    <el-option
                      v-for="(item, i) in categtList"
                      :key="i"
                      :label="item.category_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="厂家" prop="manufacturer">
                  <el-input
                    v-model="ItemTwo.manufacturer"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="厂家地址" prop="productionAddress">
                  <el-input
                    v-model="ItemTwo.productionAddress"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item
                  label="上市许可持有人地址"
                  prop="marketingAuthorizationHolderAddress"
                >
                  <el-input
                    v-model="ItemTwo.marketingAuthorizationHolderAddress"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="药品上市许可持有人" prop="mah">
                  <el-input
                    v-model="ItemTwo.mah"
                    placeholder="请输入"
                    clearable
                    size="small"
                  />
                </el-form-item>
                <el-form-item label="类型" prop="goodsType">
                  <el-radio-group v-model="ItemTwo.goodsType" clearable>
                    <el-radio :label="0"> 普药 </el-radio>
                    <el-radio :label="1"> 中药 </el-radio>
                    <el-radio :label="2"> 非药品 </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="是否集采" prop="isCollect">
                  <el-radio-group v-model="ItemTwo.isCollect" clearable>
                    <el-radio :label="0"> 否 </el-radio>
                    <el-radio :label="1"> 是 </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="是否OTC" prop="isOtc">
                  <el-radio-group v-model="ItemTwo.isOtc" clearable>
                    <el-radio :label="0"> 否 </el-radio>
                    <el-radio :label="1"> 是 </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="是否有追溯码" prop="isTranceCode">
                  <el-radio-group v-model="ItemTwo.isTranceCode" clearable>
                    <el-radio :label="0"> 否 </el-radio>
                    <el-radio :label="1"> 是 </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="商品图片" prop="pictureUrl">
                  <div class="red">最多上传照片数量6张</div>
                  <div class="fx">
                    <UpimgList
                      :imgnumber="6"
                      :disabled="false"
                      :imglist="ItemTwo.pictureUrl"
                      @Upimgsun="Upimgsun"
                      :imageSize="3"
                      :nourl="true"
                    />
                  </div>
                </el-form-item>
              </div>
            </div>
            <el-form-item label="说明书：" prop="goodsSpecification">
              <div style="height: 550px; width: 750px; margin-top: -10px">
                <pre><Editor v-model="ItemTwo.goodsSpecification" class="Editor" /></pre>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSave" :loading="btnloading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGoodsImportAuditList, //审核明细列表
  goodsImportAuditToggle, //审核状态切换
  basisscope, //获取经营类别
  categoryList, //获取商品分类
  getGoodsImportAuditGoodsList, //获取对码商品列表
  getGoodsImportAuditGoodsDetail, //获取商品详情
  saveGoodsImportAudit, //商品审核编辑-保存
  goodsImportAuditGoodsCode, //商品审核编辑-更新对码
  goodsImportAuditSubmit, //商品审核编辑-审核提交
  getGoodsImportAuditGoods, //商品文件审核详情
} from "@/api/apiAll/phpUrl.js";
import UpimgList from "@/components/Updata/UpimgList.vue";
import Editor from "@/components/Editor/index.vue"; //商品说明书
export default {
  name: "",
  components: {
    UpimgList,
    Editor,
  },
  data() {
    return {
      id: "",
      detailItem: {
        lastUpdateTime: "", //数据最后更新时间
        totalNum: "", //总数量
        passNum: "", //审核通过数量
        submitStatus: "", //提交状态0未提交1已提交
      },
      btnloading: false,
      newloading: false,
      loading: false,
      queryParams: {
        id: "",
        page: 1,
        perPage: 10,
        auditSataus: "", //审核状态0：拒绝 1：通过
        matchStatus: "", //对码结果0失败1成功
        articleNumber: "",
      },
      total: 0,
      dataList: [],

      isRefuse: false, //拒绝弹框
      AuditMessage: {
        id: "",
        remark: "", //拒绝原因
      },

      dialogVisible: false, //匹配结果弹框
      delitItem: {
        id: "", //审核明细id
        statedtoud: 1, //资料库是否有品种
        platformGoodsInfoIdonst: "", //资料库商品ID(带入)
        platformGoodsInfoId: "", //资料库商品ID（新选择的）
      }, //匹配结果基本信息
      queryOnset: {
        namegjiama: "", //名称/69码/国家码
        localSpecification: "", //规格
        chanlocalLicenseNumber: "", //厂家/批准文号
      },
      onsetList: [],

      ItemTwo: {
        goodsName: "", //商品名称
        commonName: "", //通用名
        pinyin: "", //拼音名
        specification: "", //规格
        approvalNumber: "", //批准文号
        dosageForm: "", //剂型
        unit: "", //单位
        productLice: "", //生产许可证
        regNo: "", //注册编号
        model: "", //型号
        placeOrigin: "", //产地
        brand: "", //品牌
        businessScope: "", //经营类别
        goodsClassification: "", //商品分类
        manufacturer: "", //厂家
        productionAddress: "", //厂家地址
        marketingAuthorizationHolderAddress: "", //上市许可持有人地址
        mah: "", //药品上市许可持有人
        goodsType: 0, //类型
        isCollect: "", //是否集采
        isOtc: "", //是否OTC
        isTranceCode: "", //是否有追溯码
        pictureUrl: [], //商品图片
        goodsSpecification: "", //说明书
      }, //资料库没有品种-自动建档商品信息
      categtList: [],
      basisList: [],
      ruletwo: {
        goodsName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        commonName: [
          { required: true, message: "请输入通用名", trigger: "blur" },
        ],
        specification: [
          { required: true, message: "请输入规格", trigger: "blur" },
        ],
        approvalNumber: [
          { required: true, message: "请输入批准文号", trigger: "blur" },
        ],
        dosageForm: [
          { required: true, message: "请输入剂型", trigger: "blur" },
        ],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        businessScope: [
          { required: true, message: "请选择经营类别", trigger: "change" },
        ],
        goodsClassification: [
          { required: true, message: "请选择商品分类", trigger: "change" },
        ],
        manufacturer: [
          { required: true, message: "请输入厂家", trigger: "blur" },
        ],
        goodsType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.query.id;

    this.getList();
    this.getdetail();
  },
  methods: {
    async getdetail() {
      let res = await getGoodsImportAuditGoods({ id: this.id });
      if (res.code == 200) {
        this.detailItem = res.data;
      }
    },
    async getList() {
      this.loading = true;
      this.queryParams.id = this.$route.query.id;
      let res = await getGoodsImportAuditList(this.queryParams);
      this.loading = false;
      if (res.code == 200) {
        this.dataList = res.data.data;
        this.total = res.data.total;
      }
    },
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 审核状态切换判断*/
    async changeStatus(id, status) {
      if (status == 2) {
        this.AuditMessage.remark = "";
        this.AuditMessage.id = id;
        this.isRefuse = true;
      } else {
        this.chagetog(id, status);
      }
    },
    /** 关闭拒绝原因弹窗 */
    dialogpay() {
      this.isRefuse = false;
      this.getList();
    },
    /** 关闭拒绝原因 */
    notarizeRefuse() {
      if (this.AuditMessage.remark == "")
        return this.$message.error("请输入拒绝原因");
      this.chagetog(this.AuditMessage.id, 2);
    },
    /** 审核状态切换 */
    async chagetog(id, status) {
      let res = await goodsImportAuditToggle({
        id: id,
        // status: status ,
        refuse_remark: this.AuditMessage.remark,
      });
      this.getList();
      this.getdetail();
      this.isRefuse = false;
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
    },
    /** 编辑弹窗 */
    async edit(row) {
      await Object.assign(this.delitItem, row);
      this.delitItem.statedtoud = 1;
      this.delitItem.platformGoodsInfoIdonst = row.platformGoodsInfoId;
      this.delitItem.platformGoodsInfoId = "";
      this.btnloading = false;
      this.dialogVisible = true;
      await Object.assign(this.ItemTwo, this.$options.data().ItemTwo);
      await this.getItem();
    },
    /** 获取匹配结果数据 */
    async getItem() {
      if (this.delitItem.statedtoud == 1) {
        this.delitItem.platformGoodsInfoId = "";
        await this.getoneItem();
      } else {
        await this.gettwoItem();
      }
    },
    /** 资料库有品种-选择对应编号 */
    async getoneItem() {
      let res = await getGoodsImportAuditGoodsList({ id: this.delitItem.id });
      this.onsetList = res.data;
      this.onsetList = this.onsetList.filter(
        (data) =>
          (data.localGoodsName
            .toLowerCase()
            .includes(this.queryOnset.namegjiama) ||
            data.localBarcode
              .toLowerCase()
              .includes(this.queryOnset.namegjiama) ||
            data.localMedicalCode
              .toLowerCase()
              .includes(this.queryOnset.namegjiama)) &&
          data.localSpecification
            .toString()
            .includes(this.queryOnset.localSpecification) &&
          (data.localManufacturer
            .toLowerCase()
            .includes(this.queryOnset.chanlocalLicenseNumber) ||
            data.localLicenseNumber
              .toString()
              .includes(this.queryOnset.chanlocalLicenseNumber))
      );
    },
    /** 资料库没有品种-自动建档 */
    async gettwoItem() {
      let res1 = await basisscope();
      let res2 = await categoryList();
      let res3 = await getGoodsImportAuditGoodsDetail({
        id: this.delitItem.id,
      });
      await Object.assign(this.ItemTwo, res3.data);
      this.basisList = res1.data;
      this.categtList = res2.data;
    },
    //拖拽图片
    Upimgsun(imglist) {
      this.ItemTwo.pictureUrl = [...imglist];
    },
    /** 匹配结果保存 */
    async editSave() {
      let data = {};
      data.id = this.id;
      data.type = this.delitItem.statedtoud;
      var state = false;
      if (this.delitItem.statedtoud == 1) {
        if (this.delitItem.platformGoodsInfoId == "")
          return this.$message.error("请选择资料库商品。");
        data.platformGoodsInfoId = this.delitItem.platformGoodsInfoId;
        data.id = this.delitItem.id;

        state = true;
      } else {
        await this.$refs.formtwo.validate((valid) => {
          if (valid) {
            state = true;
            Object.assign(data, this.ItemTwo);
            data.id = this.delitItem.id;
          }
        });
      }
      if (state) {
        this.btnloading = true;
        let res = await saveGoodsImportAudit(data);
        this.btnloading = false;
        if (res.code == 200) {
          this.$message.success(res.message);
          this.dialogVisible = false;
          this.getList();
        }
      }
    },
    /** 更新对码 */
    async UpdateCode() {
      this.newloading = true;
      let res = await goodsImportAuditGoodsCode({
        id: this.id,
      });
      this.newloading = false;
      if (res.code == 200) {
        this.$message.success(res.message);
        this.getList();
        this.getdetail();
      }
    },
    /** 提交 */
    async submit() {
      this.btnloading = true;
      let res = await goodsImportAuditSubmit({
        id: this.id,
      });
      this.btnloading = false;
      if (res.code == 200) {
        this.$message.success(res.message);
        this.getList();
        this.getdetail();
        this.$router.push({ path: "/auditGoodsFile" });
      }
    },
    changeBusiness(value) {
      if ([14, 15, 16, 17].includes(value)) {
        this.ItemTwo.goodsType = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.headrt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headone {
    display: flex;
    .image {
      width: 100px;
      height: 80px;
      margin-right: 20px;
    }
    .text {
      color: #777;
      .title {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
  .headtwo {
    text-align: center;
    line-height: 25px;
  }
}
.twocss {
  display: flex;
  justify-content: space-between;
  .minItem {
    width: 45%;
  }
}
.boombtn {
  background: #eee;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
