<template>
  <div class="merchantCenterList MERCH">
    <!-- 供应商中心 -->
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <el-form
          ref="searchData"
          :model="searchData"
          :inline="true"
          @keyup.enter.native="handleCurrentChange"
        >
          <el-form-item prop="name">
            <el-input
              maxlength="32"
              v-model="searchData.name"
              placeholder="请输入供应商名称"
              clearable
              size="small"
              style="width: 200px"
              oninput="value=value.replace(/\s+/g,'')"
            />
          </el-form-item>
          <el-form-item prop="number">
            <el-input
              maxlength="32"
              v-model="searchData.number"
              placeholder="请输入法人姓名/联系电话"
              clearable
              size="small"
              style="width: 200px"
              oninput="value=value.replace(/\s+/g,'')"
            />
          </el-form-item>
          <el-form-item prop="formOfBusiness">
            <el-select
              v-model="searchData.formOfBusiness"
              placeholder="请选择企业类型"
              clearable
              size="small"
              style="width: 200px"
            >
              <el-option
                v-for="item in commType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="location1">
            <el-cascader
              size="small"
              v-model="searchData.location1"
              :options="AreaList"
              :props="propsValue"
              clearable
              @change="changeArea"
              placeholder="请选择企业注册地区"
            ></el-cascader>
          </el-form-item>
          <el-form-item prop="effective">
            <el-select
              v-model="searchData.effective"
              placeholder="请选择状态"
              clearable
              size="small"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in stateList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item prop="status">
            <el-select
              v-model="searchData.payType"
              placeholder="支付方式"
              clearable
              size="small"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in Paymentlist"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item prop="clearStocksStatus">
            <el-select
              v-model="searchData.clearStocksStatus"
              placeholder="自动请货状态"
              clearable
              size="small"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in clearStocks"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="certification">
            <el-select
              v-model="searchData.certification"
              placeholder="资质状态"
              clearable
              size="small"
            >
              <!-- <el-option label="全部" :value="0"></el-option> -->
              <el-option label="即将过期" :value="1"></el-option>
              <el-option label="已过期" :value="2"></el-option>
              <el-option label="正常" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="reviewStatus">
            <el-select
              v-model="searchData.reviewStatus"
              placeholder="复核状态"
              clearable
              size="small"
            >
              <el-option label="复核未通过" :value="0"></el-option>
              <el-option label="正常" :value="1"></el-option>
              <el-option label="即将到复核期" :value="2"></el-option>
              <el-option label="已到复核期" :value="3"></el-option>
              <el-option label="异常" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="handleCurrentChange">
              查 询
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="OnWarnset"
              v-if="allJurisdiction.merchantCenterList290"
            >
              复核周期设置
            </el-button>
            <el-button type="primary" size="small" @click="reviewRecord">
              复核记录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 列表 -->
      <div
        class="merch-table scorr-roll"
        :style="`max-height: ` + mainHright + `px`"
      >
        <div>
          <el-table :data="businessList" border style="width: 100%">
            <el-table-column prop="id" label="编号" align="center" width="60">
            </el-table-column>
            <el-table-column prop="name" label="企业名称" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.name"
                  placement="bottom"
                >
                  <div class="exceed">{{ scope.row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="legalEntity" label="法人" align="center">
            </el-table-column>
            <el-table-column
              prop="telephone"
              label="联系电话"
              align="center"
              width="120"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.telephone"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.telephone }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="企业注册地" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    scope.row.provinceName +
                    scope.row.cityName +
                    scope.row.districtName
                  "
                  placement="bottom"
                >
                  <div class="exceed">
                    {{ scope.row.provinceName }}{{ scope.row.cityName
                    }}{{ scope.row.districtName }}
                  </div>
                </el-tooltip>
                <!-- <div class="exceed">
                  {{ scope.row.province_name }}-{{ scope.row.city_name }}-
                  {{ scope.row.district_name }}
                </div> -->
              </template>
            </el-table-column>
            <el-table-column prop="type" label="企业类型" align="center">
              <template slot-scope="scope">
                <span
                  v-text="
                    scope.row.type == 1
                      ? '经营企业（批发）'
                      : scope.row.type == 3
                      ? '经营企业（连锁）'
                      : '生产企业'
                  "
                ></span>
              </template>
            </el-table-column>
            <el-table-column prop="goodsCount" label="商品数" align="center">
            </el-table-column>
            <el-table-column prop="loan_month" label="免息时长" align="center">
              <template slot-scope="scope">
                <span class="exceed">
                  {{ scope.row.loanMonth || "/" }}
                  个月 ({{ scope.row.loanMonthCommission || "/" }}%)
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="ledgerName"
              label="分账规则"
              align="center"
            />
            <el-table-column label="白条佣金出资" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.iou.investorName }}</div>
                <div
                  v-if="scope.row.iou.investorName == '双方出资'"
                  style="color: #999"
                >
                  <div>平台：{{ scope.row.iou.platformProvidePercent }}%</div>
                  <div>供应商：{{ scope.row.iou.businessProvidePercent }}%</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="iou.commissionPercent"
              label="白条佣金比例"
              align="center"
            >
              <template slot-scope="scope">
                <div class="green" v-if="scope.row.iou.commissionPercent">
                  {{ scope.row.iou.commissionPercent }}%
                </div>
                <div v-else>——</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="iou.commissionPercent"
              label="自动请货对接状态"
              align="center"
            >
              <template slot-scope="scope">
                <div class="" v-if="scope.row.clearStocksStatus == '-1'">
                  未对接
                </div>
                <div class="" v-if="scope.row.clearStocksStatus == '1'">
                  已关闭
                </div>
                <div class="" v-if="scope.row.clearStocksStatus == '0'">
                  已开启
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="certification"
              label="资质状态"
              align="center"
            >
              <template slot-scope="scope">
                <div
                  :style="
                    [1, 2].includes(scope.row.certification) ? 'color:red' : ''
                  "
                >
                  {{ scope.row.certification | certStatus }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="iou.commissionPercent"
              label="支付方式"
              align="center"
            >
              <template slot-scope="scope">
                <div v-for="(item, index) in scope.row.payTypes" :key="index">
                  {{ item }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="iou.commissionPercent"
              label="上次复核日期 下次复核日期"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.lastReviewTime || "——" }}</div>
                <div>{{ scope.row.nextReviewTime || "——" }}</div>
                <div :style="{ color: reviewcss(scope.row.reviewStatus) }">
                  {{ scope.row.reviewStatus | reviewStatus }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span v-text="scope.row.status == 1 ? '启用' : '停用'"></span>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" align="center" width="200">
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.status == 1 &&
                    allJurisdiction.merchantCenterList147
                  "
                  @click="Deactivateing(scope.row)"
                  class="btsed"
                  >停用</span
                >
                <el-popconfirm
                  v-if="
                    scope.row.status != 1 &&
                    allJurisdiction.merchantCenterList147
                  "
                  @confirm="changeMerchatType(scope.row)"
                  title="确定启用当前供应商？"
                >
                  <span class="btsed" slot="reference">启用</span>
                </el-popconfirm>
                <span
                  class="btsed"
                  @click="toRouter(scope.row)"
                  v-if="allJurisdiction.merchantCenterList149"
                >
                  编辑
                </span>
                <span
                  class="btsed"
                  @click="toMessage(scope.row)"
                  v-if="allJurisdiction.merchantCenterList148"
                >
                  详情
                </span>
                <span
                  class="btsed"
                  @click="WhiteslipComm(scope.row)"
                  v-if="allJurisdiction.merchantCenterList165"
                >
                  白条分佣
                </span>
                <span class="btsed" @click="erpVerify(scope.row)">
                  ERP信息对码
                </span>
                <span class="btsed" @click="handleJoint(scope.row)">
                  对接方式
                </span>
                <span class="btsed" @click="autoClear(scope.row)">
                  自动请货对接
                </span>
                <span
                  class="btsed"
                  @click="Commtiserds(scope.row)"
                  v-if="allJurisdiction.merchantCenterList256"
                >
                  分佣及区域设置
                </span>
                <span
                  class="btsed"
                  @click="toreview(scope.row)"
                  v-if="
                    scope.row.nextReviewTime &&
                    allJurisdiction.merchantCenterList293
                  "
                >
                  复核
                </span>
                <span class="btsed" @click="reviewRecord(scope.row)">
                  复核记录
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- {{businessList}} -->
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="searchBusinessList"
          layout="total, prev, pager, next, jumper"
          :total="total"
          :page-size="10"
          :current-page="searchData.currPage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 白条分佣设置弹窗 -->
    <el-dialog
      title="白条分佣设置"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <el-form
        ref="Whitesitem"
        :model="Whitesitem"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item label="白条佣金出资方：" prop="investor">
          <el-select
            clearable
            v-model="Whitesitem.investor"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in investorList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="平台出资比例："
          v-if="Whitesitem.investor == 2"
          prop="platformProvidePercent"
        >
          <el-input
            maxlength="32"
            v-model="Whitesitem.platformProvidePercent"
            placeholder="请输入平台出资比例"
            @input="
              Whitesitem.platformProvidePercent =
                Whitesitem.platformProvidePercent.replace(/^(0+)|[^\d]+/g, '')
            "
            clearable
            style="width: 300px"
          />
          <span>&emsp;%</span>
        </el-form-item>
        <el-form-item label="白条佣金基数对象：" prop="commisionFrom">
          <el-radio-group v-model="Whitesitem.commisionFrom">
            <el-radio :label="0">订单金额</el-radio>
            <el-radio :label="1">实付金额</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="白条分佣比例：" prop="commissionPercent">
          <el-input
            maxlength="32"
            v-model="Whitesitem.commissionPercent"
            @input="
              Whitesitem.commissionPercent =
                Whitesitem.commissionPercent.replace(/^(0+)|[^\d]+/g, '')
            "
            placeholder="请输入分佣比例"
            clearable
            style="width: 300px"
          />
          <span>&emsp;%</span>
        </el-form-item>
        <el-form-item label="白条分佣解绑条件：" prop="unboundType">
          <el-select
            clearable
            v-model="Whitesitem.unboundType"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in conditionList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="白条分佣解绑时效："
          v-if="Whitesitem.unboundType != 3"
          prop="bindingDays"
        >
          <el-input
            maxlength="32"
            v-model="Whitesitem.bindingDays"
            placeholder="请输入分佣解绑时效"
            @input="
              Whitesitem.bindingDays = Whitesitem.bindingDays.replace(
                /^(0+)|[^\d]+/g,
                ''
              )
            "
            clearable
            style="width: 300px"
          />
          <span>&emsp;天</span>
        </el-form-item>
        <el-form-item label="跟随平台设置变动：" prop="standardFrom">
          <el-switch
            v-model="Whitesitem.standardFrom"
            active-color="#06b7ae"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changsliitem">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 对接方式弹窗设置弹窗 -->
    <el-dialog
      title="对接方式"
      :visible.sync="dialogJoint"
      :close-on-click-modal="false"
      width="550px"
    >
      <div style="color: red; margin: 0px 0px 15px 25px">
        注：以下信息仅技术人员可配置，请勿轻易更改。
      </div>
      <el-form
        ref="jointForm"
        :model="jointForm"
        label-width="150px"
        :rules="jointRules"
      >
        <el-form-item label="对接方式：">
          <el-radio-group v-model="jointForm.accessMethod">
            <el-radio :label="1">API对接</el-radio>
            <el-radio :label="2">中间库</el-radio>
            <el-radio :label="0">无</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="接入配送商：">
          <el-checkbox-group v-model="jointForm.accessBusiness">
            <el-checkbox label="1">合纵</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div
          v-if="jointForm.accessMethod == 1"
          style="font-size: 15px; font-weight: bold; margin: 0px 0px 20px 25px"
        >
          API对接地址
        </div>
        <div v-if="jointForm.accessMethod == 1">
          <el-form-item label="查询商品库存：" prop="queryGoodsInventoryUrl">
            <el-input
              v-model="jointForm.queryGoodsInventoryUrl"
              placeholder="请输入查询商品库存"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item label="下单锁库：" prop="orderLockUrl">
            <el-input
              v-model="jointForm.orderLockUrl"
              placeholder="请输入下单锁库"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item label="取消订单：" prop="orderCancelUrl">
            <el-input
              v-model="jointForm.orderCancelUrl"
              placeholder="请输入取消订单号"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item label="传输订单：" prop="orderTransUrl">
            <el-input
              v-model="jointForm.orderTransUrl"
              placeholder="请输入传输订单号"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item
            label="订单出库明细查询："
            prop="queryOrderDeliveryDetailsUrl"
          >
            <el-input
              v-model="jointForm.queryOrderDeliveryDetailsUrl"
              placeholder="请输入订单出库明细查询"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item label="秘钥：" prop="secret">
            <el-input
              v-model="jointForm.secret"
              placeholder="请输入秘钥"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item label="配送商出库接口：">
            <el-input
              v-model="jointForm.distributorOutboundUrl"
              placeholder="请输入配送商出库接口"
              clearable
              style="width: 300px"
            />
          </el-form-item>
          <el-form-item label="渠道：">
            <el-input
              v-model="jointForm.channel"
              placeholder="请输入渠道"
              clearable
              style="width: 300px"
            />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="text-right">
        <el-button @click="dialogJoint = false">取 消</el-button>
        <el-button type="primary" @click="handleSureJoint">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 白条分佣设置弹窗 -->
    <el-dialog
      title="设置自动请货对接"
      :visible.sync="clearStocksVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <div>
        <div class="clearStocksform">
          <div class="lable">供应商名称：</div>
          <div>{{ clearStocksForm.businessName }}</div>
        </div>
        <div class="clearStocksform">
          <div class="lable"><span>*</span> 支持支付类型：</div>
          <div>
            <el-radio
              v-model="clearStocksForm.payType"
              :label="1"
              style="width: 120px"
              >自动支付</el-radio
            >
            <el-radio
              v-model="clearStocksForm.payType"
              :label="2"
              style="width: 120px"
              >手动付款</el-radio
            >
          </div>
        </div>
        <div class="clearStocksform" v-show="clearStocksForm.payType == 1">
          <div class="lable"><span>*</span> 支持自动支付方式：</div>
          <div>
            <el-radio
              v-model="clearStocksForm.autoPayWay"
              :label="1"
              style="width: 120px"
              >免密请货（货到）</el-radio
            >
            <el-radio
              v-model="clearStocksForm.autoPayWay"
              :label="2"
              style="width: 120px"
              >账期</el-radio
            >
          </div>
        </div>
        <div class="clearStocksform">
          <div class="lable"><span>*</span> 状态：</div>
          <div>
            <el-radio
              v-model="clearStocksForm.status"
              :label="1"
              style="width: 120px"
              >开启</el-radio
            >
            <el-radio
              v-model="clearStocksForm.status"
              :label="0"
              style="width: 120px"
              >关闭</el-radio
            >
          </div>
        </div>
      </div>
      <div class="btn-box">
        <el-button @click="clearStocksVisible = false">取 消</el-button>
        <el-button type="primary" @click="clearStocksSave">保 存</el-button>
      </div>
      <div style="display: flex; justify-content: center">
        <div class="friendly-reminder">
          温馨提示：
          <div>1.对接自动请货，请先完成该供应商客户对码、配送商品对码；</div>
          <div>2.开通前需让供应商总部ERP按照合纵自动请货标准推送申请单；</div>
        </div>
      </div>
    </el-dialog>
    <!-- 复核周期及提前预警设置 -->
    <el-dialog
      title="复核周期及提前预警设置"
      :visible.sync="dialogWarnset"
      width="550px"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="yellos" style="line-height: 20px; margin-bottom: 30px">
        <div>复核日计算规则：</div>
        <div>
          1.查询对应供应商资质审核记录最新审核通过时间，取日期作为上次复核日。
        </div>
        <div>
          2.已停用供应商不参与复核周期计算，开启成功，则已开启成功时间开始计算。
        </div>
      </div>
      <div>复核周期天数：</div>
      <div style="margin-bottom: 20px">
        <span style="margin-right: 10px">每隔</span>
        <el-input
          maxlength="4"
          oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          v-model="WarnFrom.reviewPeriod"
          placeholder="请输入"
          clearable
          size="small"
          style="width: 100px"
        />
        <span style="margin-left: 10px">天，为供应商资料复核日。</span>
      </div>
      <div>即将到复核期天数：</div>
      <div style="margin-bottom: 20px">
        <span style="margin-right: 10px">提前</span>
        <el-input
          maxlength="4"
          oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          v-model="WarnFrom.reviewAdvanceDays"
          placeholder="请输入"
          clearable
          size="small"
          style="width: 100px"
        />
        <span style="margin-left: 10px">天，提醒即将到复核期。</span>
      </div>
      <span slot="footer" class="text-right">
        <el-button @click="dialogWarnset = false" :loading="loading"
          >取 消</el-button
        >
        <el-button @click="Warnsettings" type="primary" :loading="loading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 分佣和区域弹窗 -->
    <el-dialog
      title="分佣和区域设置"
      :visible.sync="dialogCommtiset"
      :close-on-click-modal="false"
      width="900px"
    >
      <div>
        <div style="margin-bottom: 10px">
          免息配置*(为供应商配置免息时间，最低分佣比例可根据合同调整，不能低于平台总分佣比例)
        </div>
        <el-form ref="whitebarCheck" :model="whitebarCheck" :inline="true">
          <el-form-item prop="formOfBusiness" label="免息时间:">
            <el-select
              v-model="whitebarCheck.id"
              @change="changeWhite(whitebarCheck)"
              placeholder="请选择"
            >
              <el-option
                v-for="(itemes, index) in whitebarList"
                :key="index"
                :label="itemes.month"
                :value="itemes.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="com" label="最低分佣比例:">
            <el-input
              @change="changeElinput(whitebarCheck.com)"
              placeholder="请输入百分比、不能低于5%"
              v-model="whitebarCheck.com"
            >
              <template slot="append"> % </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="name" label="选择分账规则:">
            <el-select v-model="whitebarCheck.name" @change="changeselect">
              <el-option
                v-for="(item, index) in ListOp"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="hint">
          温馨提示：当前分账规则中设置的分账时间为【{{
            datatext.day
          }}天】，代表订单状态为“交易结束”开始计时，{{
            datatext.day
          }}天后从平台分账至供应商账户
        </div>
        <div class="box-1">
          <div class="hint" style="margin: -20px 0px 20px 0px">可售区域：</div>
          <el-checkbox-group v-model="checkList.district_ids">
            <div class="dis-box">
              <div v-for="(item, i) in reviewInfoData.district" :key="i">
                <el-checkbox :label="item.district_id">
                  {{ item.name }}
                </el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <div slot="footer" class="text-right">
          <el-button @click="dialogCommtiset = false">取 消</el-button>
          <el-button type="primary" @click="toNext">保 存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 停用确认 -->
    <el-dialog
      title="停用确认"
      :visible.sync="dialogDeactivate"
      width="550px"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="cards">
        确定停用当前供应商？被停用后,该供应商下业务员在药聚力平台无法销售商品、业务员在助手APP无法登录到该供应商平台、该供应商下账号无法登录供应商后台。继续操作请点击“确认”按钮，放弃请点击“取消”按钮。
      </div>
      <div class="fx">
        <div style="width: 80px">原因：</div>
        <el-input
          type="textarea"
          :rows="7"
          placeholder="例：供应商长期不更新资料"
          v-model="AuditMessage.remark"
          maxlength="200"
          show-word-limit
        />
      </div>
      <span slot="footer" class="text-right">
        <el-button @click="dialogDeactivate = false" :loading="loading"
          >取 消</el-button
        >
        <el-button @click="onDeactivate" type="primary" :loading="loading"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const Order = createNamespacedHelpers("Order"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");

import {
  getReviewPeriod, //获取复核周期
  setReviewPeriod, //设置复核周期
} from "@/api/apiAll/phpUrl";
import { name } from "babel-standalone";
import { color } from "echarts/lib/export";
export default {
  data() {
    return {
      loading: false,
      dialogWarnset: false, //复核周期及提前预警设置
      //复核周期及提前预警设置
      WarnFrom: {
        id: "",
        reviewPeriod: "", //复核周期
        reviewAdvanceDays: "", //提前预警天数
      },
      jointForm: {
        accessMethod: 1,
        accessBusiness: [],
        queryGoodsInventoryUrl: "",
        orderLockUrl: "",
        orderCancelUrl: "",
        orderTransUrl: "",
        channel: "",
        secret: "",
        queryOrderDeliveryDetailsUrl: "",
        distributorOutboundUrl: "",
      },
      dialogJoint: false,
      total: 0,
      businessList: [],
      //搜索条件
      searchData: {
        currPage: 1,
        pageSize: 10,
        name: "", //供应商名称
        number: "", //法人姓名/联系电话
        formOfBusiness: "", //企业类型
        payType: "", //支付方式
        location1: [],
        location: {
          province: "",
          city: "",
          district: "",
        },
        effective: "", //选择状态
        clearStocksStatus: "", //自动请货状态
        certification: "", //资质状态
        reviewStatus: "", //复核状态
      },
      Paymentlist: [
        { name: "在线支付", id: "1" },
        { name: "药聚力白条支付", id: "50" },
        { name: "对公转账", id: "0" },
        // { name: "药易购白条", id: "40" },
      ],
      // 供应商状态
      stateList: [
        { name: "启用", id: 1 },
        { name: "停用", id: 0 },
      ],
      clearStocks: [
        { name: "未对接", id: "-1" },
        { name: "已开启", id: "0" },
        { name: "已关闭", id: "1" },
      ], //药聚力研发内部交流群 2024/05/27  09:27

      commType: [
        { name: "经营企业（批发）", type: 1 },
        { name: "经营企业（连锁）", type: 3 },
        { name: "生产企业", type: 2 },
      ],
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      value: "",
      options: [],
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      }, //级联选择器配置项
      AreaArr: [],
      dialogVisible: false, //白条分佣设置弹窗
      Whitesitem: {
        investor: 1,
      }, //白条分佣数据
      investorList: [
        {
          name: "平台出资",
          id: 0,
        },
        {
          name: "供应商出资",
          id: 1,
        },
        {
          name: "双方出资",
          id: 2,
        },
      ], //白条佣金出资方
      conditionList: [
        {
          name: "时效内未使用白条",
          id: 1,
        },
        {
          name: "时效内未发生交易",
          id: 2,
        },
        {
          name: "不解绑",
          id: 3,
        },
      ],
      jointRules: {
        queryGoodsInventoryUrl: [
          { required: true, message: "请输入查询商品库存", trigger: "change" },
        ],
        orderLockUrl: [
          { required: true, message: "请输入下单锁库", trigger: "change" },
        ],
        orderCancelUrl: [
          { required: true, message: "请输入取消订单号", trigger: "change" },
        ],
        orderTransUrl: [
          { required: true, message: "请输入传输订单号", trigger: "change" },
        ],
        queryOrderDeliveryDetailsUrl: [
          {
            required: true,
            message: "请输入订单出库明细查询",
            trigger: "blur",
          },
        ],
        secret: [{ required: true, message: "请输入秘钥", trigger: "blur" }],
      },
      rules: {
        //白条分佣解绑条件
        investor: [
          { required: true, message: "请选择白条佣金出资方", trigger: "blur" },
        ],
        platformProvidePercent: [
          { required: true, message: "请选择平台出资比例", trigger: "blur" },
        ],
        commisionFrom: [
          {
            required: true,
            message: "请选择白条佣金基数对象",
            trigger: "blur",
          },
        ],
        commissionPercent: [
          { required: true, message: "请输入白条分佣比例", trigger: "blur" },
        ],
        unboundType: [
          {
            required: true,
            message: "请选择白条分佣解绑条件",
            trigger: "blur",
          },
        ],
        bindingDays: [
          { required: true, message: "请输入分佣解绑时效", trigger: "blur" },
        ],
        standardFrom: [
          {
            required: true,
            message: "请选择是否跟随平台设置变动",
            trigger: "blur",
          },
        ],
      },
      clearStocksVisible: false,
      clearStocksForm: {
        // payType:0
      },

      dialogCommtiset: false, //分佣和区域弹窗
      //分佣和区域设置
      whitebarCheck: {
        id: "",
        com: "",
        name: "",
      },
      whitebar: {
        business_id: "",
        loan_month: "",
        loan_month_commission: "",
      }, //白条分佣
      ListOp: [], //分账规则
      datatext: {},
      //选择的地区
      checkList: {
        business_id: "",
        district_ids: [],
      },

      dialogDeactivate: false, //停用弹窗
      AuditMessage: {
        id: "",
        status: "",
        remark: "", //停用原因
      },
    };
  },
  watch: {},
  filters: {
    certStatus(v) {
      switch (v) {
        case 1:
          return "有资质即将过期";
        case 2:
          return "有资质已过期";
        case 3:
          return "正常";
        default:
          return "——";
      }
    },
    reviewStatus(v) {
      switch (v) {
        case 0:
          return "复核未通过";
        case 1:
          return "正常";
        case 2:
          return "即将到复核期";
        case 3:
          return "已到复核期";
        case 4:
          return "异常";
        default:
          return "——";
      }
    },
  },
  created() {
    this.getPaymentTypeder();
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchData = this.detailsSeach[router];
      this.AreaArr = this.detailsSeach[router].AreaArr;
    }
    this.searchBusinessList();
  },
  computed: {
    ...mapState(["reviewInfoData", "stepValue"]),
    ...commonIndex.mapState([
      "AreaList",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
      "whitebarList",
    ]),
    ...merchantCenterList.mapState(["page", "businessInfo"]),
  },
  methods: {
    ...Order.mapActions([
      "getPaymentTypeList", //获取支付方式
    ]),
    ...mapActions([
      "postreviewstep",
      "getreviewInfo",
      "postreviewsetLoan",
      "postreviewsetDistrict",
      "getAlarm", //获取预警配置
      "setAlarm", //设置预警配置
    ]),
    ...merchantCenterList.mapActions([
      "getBusinessList",
      "businessEdit",
      "getselectIOU",
      "updateIOU",
      "postbusinessList",
      "updateAccessConfig",
      "getAccessConfig",
      "getRequisitionConfig",
      "requisitionConfigDetail",
      "updateRequisitionConfig",
      "getbusiness_ledgerlist",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    //** 获取支付方式 */
    async getPaymentTypeder() {
      let res = await this.getPaymentTypeList();
      if (res.code == 200) {
        this.Paymentlist = res.data;
      }
    },
    async clearStocksSave() {
      let params = this.clearStocksForm;
      if (params.status < 0) {
        return this.$message.warning("请设置自动请货对接状态");
      }
      if (params.payType < 1) {
        return this.$message.warning("请设置自动请货对接支持支付类型");
      }
      if (params.payType == 1 && params.autoPayWay < 1) {
        return this.$message.warning("请设置自动请货对接支持自动支付方式");
      }
      let res = await this.updateRequisitionConfig(params);
      if (res.code == "200") {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.searchBusinessList();
        this.clearStocksVisible = false;
      }
    },
    async autoClear(val) {
      let res = await this.requisitionConfigDetail({ businessId: val.id });
      if (res.code == "200") {
        this.clearStocksForm = res.data;
        this.clearStocksForm.businessName = val.name;
        this.clearStocksForm.businessId = val.id;
        this.clearStocksVisible = true;
      }
    },
    async handleSureJoint() {
      this.$refs.jointForm.validate(async (valid) => {
        if (valid) {
          let res = await this.updateAccessConfig(this.jointForm);
          if (res.code == 200) {
            this.$message({
              message: res.message,
              type: "success",
            });
          }
          this.dialogJoint = false;
        }
      });
    },
    async handleJoint(row) {
      if (this.$refs.jointForm !== undefined)
        this.$refs.jointForm.resetFields();
      this.dialogJoint = true;
      Object.assign(this.jointForm, this.$options.data().jointForm);
      let data = await this.getAccessConfig({ id: row.id });
      this.jointForm = data.data;
      this.jointForm.id = row.id;
    },
    changeArea(e) {
      this.searchData.location.province = e[0];
      this.searchData.location.city = e[1];
      this.searchData.location.district = e[2];
      // this.searchBusinessList();
    },
    async searchBusinessListAPI() {
      let res = await this.postbusinessList(this.searchData);
      if (res.code == "000000") {
        this.businessList = res.content.list;
        this.total = res.content.total;
      }
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchData };
      save.AreaArr = this.AreaArr;
      save.router = router;
      this.changeSeach(save);
    },
    //搜索
    handleCurrentChange() {
      this.searchData.currPage = 1;
      this.searchBusinessListAPI();
    },
    //列表查询
    searchBusinessList(e) {
      if (e != undefined) {
        this.searchData.currPage = e;
      }
      this.searchBusinessListAPI();
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    /** 停用弹窗 */
    Deactivateing(row) {
      this.AuditMessage.id = row.id;
      this.AuditMessage.status = row.status;
      this.AuditMessage.remark = "";
      this.dialogDeactivate = true;
    },
    /** 停用保存 */
    async onDeactivate() {
      if (this.AuditMessage.remark == "")
        return this.$message.error("请输入停用原因");
      this.loading = true;
      await this.changeMerchatType(this.AuditMessage);
      this.loading = false;
      this.dialogDeactivate = false;
    },
    // 启用/停用供应商
    async changeMerchatType(item) {
      var data1 = {
        id: item.id,
        status_: item.status == 1 ? 0 : 1,
        remark: item.status == 0 ? "" : this.AuditMessage.remark,
      };
      var res = await this.businessEdit(data1);
      if (res.code == 200) {
        this.$message.success("操作成功");
        this.searchBusinessList();
        // this.getBusinessList(this.searchData);
      }
    },
    /** 编辑 */
    toRouter(item) {
      this.$router.push({
        path: "/changmerchantMessage",
        query: {
          id: item.id,
          auditSerialNumber: item.auditSerialNumber,
          isBusinessInfo: true, //true供应商详情，false审核详情
        },
      });
    },
    /** 详情 */
    toMessage(item) {
      this.$router.push({
        path: "/merchantMessage",
        query: {
          id: item.id,
          auditSerialNumber: item.auditSerialNumber,
          isBusinessInfo: true, //true供应商详情，false审核详情
          type: 1,
        },
      });
    },
    erpVerify(item) {
      this.$router.push({
        path: "/ERPVerify",
        query: { id: item.id },
      });
    },

    //白条分佣弹窗
    async WhiteslipComm(row) {
      let item = await this.getselectIOU({
        businessId: row.id,
      });
      this.Whitesitem = { ...item.data };
      this.Whitesitem.businessId = row.id;
      this.dialogVisible = true;
    },
    //修改白条分佣
    changsliitem() {
      this.$refs["Whitesitem"].validate((valid) => {
        if (valid) {
          if (
            this.Whitesitem.investor == 2 &&
            this.Whitesitem.platformProvidePercent > 100
          )
            return this.$message.error("出资比例不能大于100");
          if (
            this.Whitesitem.investor != 2 &&
            this.Whitesitem.commissionPercent > 100
          )
            return this.$message.error("白条分佣比例不能大于100");
          this.updateIOU(this.Whitesitem).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            this.dialogVisible = false;
            this.searchBusinessList();
          });
        } else {
          return false;
        }
      });
    },
    /** 复核周期及提前预警设置弹窗 */
    async OnWarnset() {
      let res = await getReviewPeriod();
      Object.assign(this.WarnFrom, res.data);
      this.dialogWarnset = true;
    },
    //** 复核周期及提前预警设置 */
    async Warnsettings() {
      if (this.WarnFrom.reviewPeriod == "")
        return this.$message.warning("请输入复核周期");
      if (this.WarnFrom.reviewAdvanceDays == "")
        return this.$message.warning("请输入提前预警天数");
      if (Number(this.WarnFrom.reviewPeriod) > 1000)
        return this.$message.warning("复核周期不能大于1000");
      if (
        Number(this.WarnFrom.reviewAdvanceDays) >
        Number(this.WarnFrom.reviewPeriod)
      )
        return this.$message.warning("提前预警天数不能大于复核周期");
      this.loading = true;
      var res = await setReviewPeriod(this.WarnFrom);
      this.loading = false;
      if (res.code == 200) {
        this.$message.success("设置成功");
        this.dialogWarnset = false;
        this.searchBusinessListAPI();
      }
    },
    /** 分佣及区域弹窗 */
    async Commtiserds(row) {
      await Object.assign(
        this.whitebarCheck,
        this.$options.data().whitebarCheck
      );
      await Object.assign(this.whitebar, this.$options.data().whitebar);
      await Object.assign(this.checkList, this.$options.data().checkList);
      this.checkList.business_id = row.id;
      this.whitebar.business_id = row.id;

      await this.getreviewInfo({ id: row.id });
      await this.getdefauList();

      this.$nextTick(() => {
        this.whitebarCheck.id = this.reviewInfoData.info.loan_month;
        this.whitebarCheck.com = this.reviewInfoData.info.loan_month_commission;
        this.whitebar.loan_month = this.reviewInfoData.info.loan_month;
        this.whitebar.loan_month_commission =
          this.reviewInfoData.info.loan_month;
        this.checkList.district_ids = this.reviewInfoData.district_select;
      });
      this.dialogCommtiset = true;
    },
    // 白条免息
    changeWhite(v) {
      this.whitebarList.forEach((item) => {
        item.id == v.id
          ? (this.whitebar = {
              business_id: this.whitebar.business_id,
              loan_month: item.month,
              loan_month_commission: item.commission,
            })
          : "";
      });
      this.whitebarCheck.com = this.whitebar.loan_month_commission;
    },
    // 获取赛选
    async getdefauList() {
      let data = await this.getbusiness_ledgerlist();
      this.ListOp = data.data;
      this.datatext = this.ListOp.filter((item) => {
        return item.default == 1;
      });
      if (this.reviewInfoData.info.ledger_id != 0) {
        this.whitebarCheck.name = this.reviewInfoData.info.ledger_id;
      } else {
        this.whitebarCheck.name = this.datatext[0].id;
      }
      this.datatext.length > 0
        ? (this.datatext = this.datatext[0])
        : (this.datatext = {});
    },
    changeselect(e) {
      this.datatext = this.ListOp.filter((item) => {
        return item.id == e;
      });
      this.datatext.length > 0
        ? (this.datatext = this.datatext[0])
        : (this.datatext = {});
    },
    // 白条分佣
    changeElinput(v) {
      if (v >= this.whitebar.loan_month_commission) {
        this.whitebarCheck.com = v;
      } else {
        this.$message({
          message: `所填的分佣比列必须大于或者等于原始分佣比列`,
          type: "warning",
        });
        this.whitebarCheck.com = this.whitebar.loan_month_commission;
      }
    },
    //**分佣及区域保存修改  */
    async toNext() {
      if (!this.whitebarCheck.id || this.checkList.district_ids.length == 0) {
        this.$message({
          message: `请填写完整资料`,
          type: "warning",
        });
        return;
      }
      this.whitebar.loan_month_commission = this.whitebarCheck.com;
      // 保存白条分佣
      let whiteData = await this.postreviewsetLoan(this.whitebar);
      // 保存分佣规则
      let commissionData = await this.businessEdit({
        id: this.whitebar.business_id,
        ledger_id: this.datatext.id,
      });
      // 保存地区选择
      let disData = await this.postreviewsetDistrict(this.checkList);
      if (
        whiteData.code == 200 &&
        disData.code == 200 &&
        commissionData.code == 200
      ) {
        this.$message.success("修改成功");
        this.dialogCommtiset = false;
      }
    },
    /** 复核 */
    toreview(row) {
      this.$router.push({
        path: "/toreviewMessage",
        query: {
          id: row.id,
          auditSerialNumber: row.auditSerialNumber,
          isBusinessInfo: true, //true供应商详情，false审核详情
          routType: "toreview", //类型为复核
        },
      });
    },
    /** 复核记录 */
    reviewRecord(row) {
      this.$router.push({
        name: "复核记录",
        params: {
          id: row.id,
        },
      });
    },
    reviewcss(v) {
      switch (v) {
        case 0:
          return "#F34444";
        case 1:
          return "#3DBB2B";
        case 2:
          return "#F59A23";
        case 3:
          return "#F59A23";
        case 4:
          return "#F34444";
        default:
          return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
.el-table th.el-table__cell > .cell {
  white-space: normal;
  word-break: break-all;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
// .el-select {
//   width: 325px;
// }
.el-button--default {
  background-color: rgba($color: #2e3238, $alpha: 0.05);
  color: #383b46;
  border: none;
}
.clearStocksform {
  display: flex;
  margin-bottom: 20px;
  .lable {
    width: 150px;
    text-align: end;
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
    span {
      color: #de1f37;
    }
  }
}
.friendly-reminder {
  width: 450px;
  border-radius: 8px;
  border: 1px dashed #e0c29b;
  color: #e0c29b;
  padding: 10px;
  margin-top: 20px;
  div {
    padding-left: 20px;
    padding-top: 8px;
  }
}
.btn-box {
  display: flex;
  justify-content: center;
}
.hint {
  text-align: start;
  padding-top: 20px;
  line-height: 20px;
  font-weight: bold;
}
.box-1 {
  width: 100%;
  margin-top: 30px;
}
.dis-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  // flex-wrap: wrap;
  div {
    width: 100%;
    margin-bottom: 10px;
  }
}
.cards {
  font-size: 13px;
  line-height: 18px;
  background-color: #eee;
  border-radius: 5px;
  padding: 10px;
  color: rgb(184, 116, 26);
  margin-bottom: 20px;
}
</style>
