<template>
  <div class="usersCenterList MERCH">
    <div class="merch-card scorr-roll">
      <!-- 搜索列 -->
      <el-form
        ref="searchData"
        :model="searchData"
        :inline="true"
        @keyup.enter.native="handleCurrentChange"
      >
        <el-form-item>
          <el-input
            placeholder="请输入单位名称/社会信用编码"
            v-model="searchData.name"
            maxlength="200"
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入法人姓名/联系电话"
            v-model="searchData.number"
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            maxlength="18"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchData.type"
            placeholder="选择会员类型"
          >
            <el-option
              v-for="item in userType"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="searchData.AreaArr"
            :options="AreaList"
            :props="propsValue"
            clearable
            placeholder="请选择企业注册地"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchData.syncStatus"
            placeholder="选择是否开启经营范围同步"
          >
            <el-option label="否" value="0"></el-option>
            <el-option label="是" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="searchData.timeList"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="请选择注册开始时间"
            end-placeholder="请选择注册结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchData.isPlatformQualification"
            placeholder="请选择是否平台资质"
          >
            <el-option label="否" value="0"></el-option>
            <el-option label="是" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleCurrentChange">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div>
        <el-table
          :data="userList.data"
          border
          header-cell-class-name="table_header"
          v-loading="loading"
          height="530"
        >
          <el-table-column prop="id" label="会员编码" align="center">
          </el-table-column>
          <el-table-column prop="shop_name" label="单位简称" align="center">
          </el-table-column>
          <el-table-column prop="enterprise" label="单位名称" align="center">
          </el-table-column>
          <el-table-column prop="type_name" label="会员类型" align="center">
          </el-table-column>
          <el-table-column prop="legal" label="法人姓名" align="center">
          </el-table-column>
          <el-table-column prop="mobile" label="手机号" align="center">
          </el-table-column>
          <el-table-column label="发票类型" align="center">
            <template slot-scope="{ row }">
              <span
                style="color: #06b7ae; cursor: pointer"
                @click="handleInvoice(row)"
                >{{ row.billtypestr || "--" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="企业注册地"
            align="center"
            style="flex: 1"
          >
            <template slot-scope="scope">
              {{ scope.row.province_name }}-{{ scope.row.city_name }}-{{
                scope.row.district_name
              }}
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="注册时间" align="center">
          </el-table-column>
          <el-table-column
            prop="first_buy_time"
            label="首次下单时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="last_buy_time"
            label="最近下单时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="sync_status"
            label="经营范围同步"
            min-width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.sync_status"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="changejinyinfw(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="is_platform_qualification"
            label="是否平台资质"
            min-width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.is_platform_qualification"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="changequalifi(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="状态"
            min-width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.status"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="changeSwitch(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            align="center"
            width="170"
          >
            <template slot-scope="scope">
              <div class="userBtn">
                <!--  -->
                <div
                  @click="toRedact(scope.row)"
                  v-if="allJurisdiction.usersCenterList150"
                >
                  编辑
                </div>
                <!-- <div @click="toAccount">账户</div>
                  <div>详情</div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination
        v-show="Number(userList.total) > 0"
        :total="Number(userList.total)"
        :page.sync="searchData.page"
        :limit.sync="searchData.perPage"
        @pagination="getList"
      />
    </div>
    <el-dialog title="发票配置" :visible.sync="invoiceDialog" width="500px">
      <el-form
        ref="invoiceForm"
        :model="invoiceForm"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="当前默认">
          <div>{{ billtypestr || "--" }}</div>
        </el-form-item>
        <el-form-item label="发票类型" prop="billType">
          <el-radio v-model="invoiceForm.billType" :label="0"
            >增值税普通发票</el-radio
          >
          <el-radio v-model="invoiceForm.billType" :label="2"
            >增值税专用发票</el-radio
          >
        </el-form-item>
        <el-form-item label="发票抬头" prop="companyName">
          <el-input
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            v-model="invoiceForm.companyName"
            placeholder="请输入发票抬头"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位税号" prop="taxId">
          <el-input
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            v-model="invoiceForm.taxId"
            placeholder="请输入单位税号"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册地址" prop="companyAddress">
          <el-input
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            v-model="invoiceForm.companyAddress"
            placeholder="请输入单位税号"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册电话" prop="contactNumber">
          <el-input
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            v-model="invoiceForm.contactNumber"
            placeholder="请输入注册电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bankName">
          <el-input
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            v-model="invoiceForm.bankName"
            placeholder="请输入开户银行"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="account">
          <el-input
            oninput="this.value = this.value.replace(/[ ]/g, '')"
            v-model="invoiceForm.account"
            placeholder="请输入银行账号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="invoiceDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleSaveInvoice"
          :loading="btnLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog width="400px" title="修改提示" :visible.sync="amendVisible">
      <div>修改发票后客户开票信息将会更新是否确定修改？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="amendVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveAmend" :loading="btnLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const userCenterList = createNamespacedHelpers("userCenterList");
import {
  togglePlatformQualification, //切换平台资质类型
  switchSyncstatus, //切换会员经营范围是否同步
} from "@/api/apiAll/phpUrl";
export default {
  data() {
    return {
      billtypestr: "",
      amendVisible: false,
      invoiceForm: {
        taxId: "",
        billType: "",
        bankName: "",
        account: "",
        companyName: "",
        companyAddress: "",
        contactNumber: "",
      },
      invoiceDialog: false,
      loading: false,
      btnLoading: false,
      form: {},
      searchData: {
        page: 1,
        perPage: 10,
        AreaArr: [],
        location: {
          province: "", //省
          city: "", //市
          district: "", //区
        },
        name: "",
        number: "",
        syncStatus: "",
        timeList: [], //注册时间
        isPlatformQualification: "", //是否平台资质
      },
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      value: "",
      options: [],
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      }, //级联选择器配置项
      // AreaArr: [],
      isTran: false, //控制转移弹框
      invoiceType: [
        {
          name: "普通发票",
          id: 1,
        },
        {
          name: "增值税专用发票",
          id: 2,
        },
      ],
      rules: {
        billType: [
          { required: true, message: "发票类型必选", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "发票抬头必填", trigger: "change" },
          {
            min: 1,
            max: 50,
            message: "发票抬头长度最多50个字符",
            trigger: "change",
          },
        ],
        taxId: [
          { required: true, message: "单位税号必填", trigger: "change" },
          {
            min: 1,
            max: 50,
            message: "单位税号长度最多50个字符",
            trigger: "change",
          },
          {
            pattern: /^[a-zA-Z0-9]+$/g,
            message: "单位税号只能输入字母和数字",
            trigger: "blur",
          },
        ],
        // contactNumber: [
        //   { required: true, message: "注册电话必填", trigger: "change" },
        // ],
        // companyAddress: [
        //   { required: true, message: "注册地址必填", trigger: "change" },
        //   {
        //     min: 1,
        //     max: 100,
        //     message: "注册地址长度最多100个字符",
        //     trigger: "change",
        //   },
        // ],
        // bankName: [
        //   { required: true, message: "开户银行必填", trigger: "change" },
        //   {
        //     min: 1,
        //     max: 50,
        //     message: "开户银行长度最多50个字符",
        //     trigger: "change",
        //   },
        // ],
        // account: [
        //   { required: true, message: "银行账号必填", trigger: "change" },
        //   {
        //     min: 1,
        //     max: 50,
        //     message: "银行账号长度最多50个字符",
        //     trigger: "change",
        //   },
        //   {
        //     pattern: /^[a-zA-Z0-9]+$/g,
        //     message: "银行账号只能输入字母和数字",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
    ...userCenterList.mapState(["userList"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchData = this.detailsSeach[router];
    }
    this.getList();
  },
  methods: {
    ...userCenterList.mapActions([
      "getUserList",
      "switchMemberStatus",
      "getUserInvoice",
      "updateUserInvoice",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    /** 获取列表数据 */
    async getList() {
      this.searchData.location = {
        province: this.searchData.AreaArr[0],
        city: this.searchData.AreaArr[1],
        district: this.searchData.AreaArr[2],
      };
      if (this.searchData.timeList) {
        this.searchData.regStartTime = this.searchData.timeList[0];
        this.searchData.regEndTime = this.searchData.timeList[1];
      } else {
        this.searchData.regStartTime = "";
        this.searchData.regEndTime = "";
      }
      this.searchData.name = this.searchData.name.replace(/\s*/g, "");
      this.searchData.number = this.searchData.number.replace(/\s*/g, "");
      this.loading = true;
      await this.getUserList(this.searchData);
      this.loading = false;
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchData };
      save.router = router;
      await this.changeSeach(save);
    },
    //搜索
    handleCurrentChange() {
      this.searchData.page = 1;
      this.getList();
    },
    /** 切换状态 */
    async changeSwitch(row) {
      let pramas = {
        id: row.id,
        status: row.status,
      };
      let res = await this.switchMemberStatus(pramas);
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
      this.getList();
    },
    /** 切换是否平台资质 */
    async changequalifi(row) {
      let pramas = {
        id: row.id,
      };
      let res = await togglePlatformQualification(pramas);
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
      this.getList();
    },
    /** 切换是否经营范围同步 */
    async changejinyinfw(row) {
      let pramas = {
        id: row.id,
        syncStatus: row.sync_status,
      };
      let res = await switchSyncstatus(pramas);
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
      this.getList();
    },
    async handleSaveAmend() {
      this.btnLoading = true;
      let res = await this.updateUserInvoice(this.invoiceForm);
      this.btnLoading = false;
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.message,
        });
      }
      this.amendVisible = false;
      this.invoiceDialog = false;
      this.getList();
    },
    handleSaveInvoice() {
      this.$refs.invoiceForm.validate(async (valid) => {
        if (valid) {
          this.amendVisible = true;
        }
      });
    },
    async handleInvoice(row) {
      this.billtypestr = row.billtypestr;
      let data = await this.getUserInvoice({ userId: row.id });
      if (row.billtype == 0 || row.billtype == 2) {
        if (row.billtype * 1 == 2) {
          this.invoiceForm = Object.assign({}, data.data[1]);
        } else {
          this.invoiceForm = Object.assign({}, data.data[0]);
        }
      } else {
        this.invoiceForm = Object.assign({}, data.data[0]);
        this.invoiceForm.billType = "";
      }
      this.invoiceDialog = true;
      if (this.$refs.invoiceForm !== undefined)
        this.$refs.invoiceForm.resetFields();
    },

    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 打开转移窗口
    transferS() {
      this.isTran = true;
    },
    // 去往会员编辑页面
    toRedact(item) {
      this.$router.push({
        path: "/usersRedact",
        query: {
          id: item.id,
        },
      });
    },
    // 去往账户
    toAccount() {
      this.$router.push({ path: "/usersAccount" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .merch-content-top {
      padding-top: 10px;
      .chack-type {
        padding: 0px 40px;
        width: 600px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .TAG-1 {
          cursor: pointer;
          border-radius: 4px;
          line-height: 0px !important;
          text-align: center;
          width: 100px;
          height: 30px;
          border: 1px solid #e4e7ed;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
        }
        .TAG-2 {
          border: none;
          background: #00bfbf;
          color: #fff;
        }
      }
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        // width: 18%;
        min-width: 260px;
        display: flex;
        .el-select {
          width: 100%;
        }
        .el-cascader {
          width: 100%;
        }
        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
::v-deep .table_header {
  background-color: #f2f3f5 !important;
}
::v-deep .el-input__inner {
  border-radius: 4px !important;
}
</style>
