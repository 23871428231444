<template>
  <div class="merch">
    <el-form
      ref="form"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item>
        <el-input
          v-model="queryParams.business"
          placeholder="供应商编号/名称模糊搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="minItem">
        <el-date-picker
          v-model="queryParams.dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="复核开始日期"
          end-placeholder="复核结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.reviewSn"
          placeholder="复核编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <FilterSelect
          @filterValue="
            (value) => {
              queryParams.operatorId = value;
            }
          "
          placeholder="提交人、账号查询/处理人、账号查询"
          labelKey="real_name"
          valueKey="id"
          urlType="php"
          urltxt="/Admin/adminList"
          width="280px"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="queryParams.reviewStatus"
          placeholder="复核结果"
          clearable
        >
          <el-option label="复核通过" :value="1"></el-option>
          <el-option label="复核不通过" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery"> 搜 索 </el-button>
      </el-form-item>
    </el-form>
    <div class="container">
      <el-table
        v-loading="loading"
        :data="tableData"
        :header-cell-style="{ background: '#F2F3F5' }"
        border
      >
        <el-table-column prop="reviewSn" label="复核编号" align="center" />
        <el-table-column label="编号/供应商名称">
          <template slot-scope="scope">
            <div>{{ scope.row.businessId }}/{{ scope.row.businessName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="businessType" label="企业类型" align="center" />
        <el-table-column label="复核结果" width="160" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.reviewStatus == 1 ? "复核通过" : "复核不通过" }}</div>
            <div class="yellos" v-if="scope.row.reviewStatus != 1">
              (原因：{{ scope.row.remark }})
            </div>
          </template>
        </el-table-column>
        <el-table-column label="复核人账号/名称">
          <template slot-scope="scope">
            <div>{{ scope.row.operatorAccount }}/{{ scope.row.operator }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="完成复核时间"
          align="center"
        />
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <div @click="details(scope.row)" class="btsed">详情</div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.perPage"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  reviewRecordList, //供应商列表-复核记录
} from "@/api/apiAll/phpUrl";
import { scope } from "babel-standalone";

export default {
  name: "",
  computed: {
    ...mapGetters(["stated"]),
  },
  data() {
    return {
      loading: false,
      phpbaseURL: window.localStorage.getItem("phpUrl"),
      // 列表查询
      queryParams: {
        page: 1, // 页码
        perPage: 10, //每页大小
        business: "", //供应商编号/名称模糊搜索
        dateTime: [], //复核日期
        startTime: "", //
        endTime: "", //
        reviewSn: "", //复核编号
        operatorId: "", //提交人、账号查询/处理人、账号查询
        reviewStatus: "", //复核结果
      },
      tableData: [],
      total: 0,
    };
  },
  created() {
    let params = this.$route.params;
    if (params.id) {
      this.queryParams.business = params.id;
    }
    this.getList();
  },
  methods: {
    // 获取黑名单列表
    async getList() {
      this.loading = true;
      if (this.queryParams.dateTime) {
        this.queryParams.startTime = this.queryParams.dateTime[0];
        this.queryParams.endTime = this.queryParams.dateTime[1];
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      let res = await reviewRecordList(this.queryParams);
      if (res.code == 200) {
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
      }
    },
    //查询按钮操作
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 详情 */
    details(row) {
      this.$router.push({
        path: "/Reviewdetails",
        query: {
          auditSerialNumber: row.serialNumber,
          businessId: row.businessId,
          isBusinessInfo: false, //true供应商详情，false审核详情
          review: true, //是否是复核
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
