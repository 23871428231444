<template>
  <div>
    <el-select
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder"
      filterable
      remote
      reserve-keyword
      :remote-method="filterValue"
      @change="selectChange"
      clearable
      @clear="clear"
      @focus="focus"
      :style="{ width: width }"
    >
      <el-option
        v-for="item in list"
        :key="item.id"
        :label="item[labelKey]"
        :value="item[valueKey]"
      >
        <template>
          <!-- <span> {{ item.realName + "/" + item.userName }}</span> -->
        </template>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import axios from "axios";
import { wholeUrl } from "@/api/phpUrl.js";
import { getToken } from "@/utils/auth";

export default {
  props: {
    //labelKey
    labelKey: {
      type: String,
      default: "",
    },
    //valueKey
    valueKey: {
      type: String,
      default: "",
    },
    //disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    //placeholder
    placeholder: {
      type: String,
      default: "请选择",
    },
    //width
    width: {
      type: String,
      default: "",
    },

    //urlType区分java还是php接口，//
    urlType: {
      type: String,
      default: "",
    },
    //urltxt,url地址
    urltxt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      list: [],
    };
  },
  methods: {
    focus(value) {
      if (value.target.value == "") this.list = [];
    },
    clear() {
      this.list = [];
    },
    selectChange(value) {
      this.$emit("filterValue", value, this.key);
    },
    async filterValue(value) {
      if (value == "") return (this.list = []);
      var params = {
        keywords: value,
        perPage: 10000,
      };
      var javawholeUrl = window.localStorage.getItem("javaBaseUrl");
      const imgdownUrl1 = this.urlType == "java" ? javawholeUrl : wholeUrl; //判断是否为小程序端接口;
      var url = imgdownUrl1 + this.urltxt;
      axios({
        method: "get",
        url: url,
        headers: { TOKEN: getToken() },
        params,
      }).then((res) => {
        console.log(res, "1111111111111");
        this.list = res.data.data.data;
      });
    },
  },
};
</script>

<style></style>
