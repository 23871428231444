<template>
  <div class="merlends">
    <RecordDetails ref="Recordes" :recorItem="recorItem" />
    <div class="btn-box">
      <div class="go-back" @click="$router.go(-1)">返回</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
import RecordDetails from "@/components/RecordDetails/RecordDetails.vue";
import {
  reviewRecordDetail, //复核记录详情
} from "@/api/apiAll/phpUrl.js";
export default {
  name: "",
  components: {
    RecordDetails,
  },
  data() {
    return {
      recorItem: {
        examineBaseInfo: {
          changedContent: [],
        }, //企业基础信息
        qualification: [], //资质数据
        companyTypeInfo: [], //企业类型数据
        scopeInfo: [], //经营范围数据
        reviewItem: {}, //复核信息
      },
    };
  },
  created() {
    this.getItem();
  },
  methods: {
    ...merchantCenterList.mapActions([
      "getCredentials", //审核记录详情（资质详情）
    ]),
    /** 获取详情数据 */
    async getItem() {
      var res = await this.getCredentials({
        businessId: this.$route.query.businessId,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        isBusinessInfo: this.$route.query.isBusinessInfo,
      });
      Object.assign(this.recorItem, res);
      if (this.$route.query.review) {
        var res1 = await reviewRecordDetail({
          id: this.$route.query.id,
        });
        this.recorItem.reviewItem = res1.data;
        this.recorItem.scopeInfo = res1.data.scopeInfo;
      }
      this.$nextTick(() => {
        this.$refs.Recordes.getItem();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-box {
  margin-top: 20px;
  width: 100%;
  height: 32px;
  display: flex;
  align-content: center;
  justify-content: center;
  div {
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    font-weight: normal;
    line-height: 32px;
    cursor: pointer;
  }
  .go-back {
    width: 88px;
    color: rgba($color: #1c1f23, $alpha: 0.6);
    border: 1px solid #d4d7d9;
  }
}
</style>
