<template>
  <div class="merlends">
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item prop="storeName">
        <el-input
          v-model="queryParams.storeName"
          placeholder="客户名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="">
        <el-cascader
          v-model="queryParams.addr"
          :options="AreaList"
          :props="propsValue"
          clearable
          filterable
          placeholder="省/市/区县"
        ></el-cascader>
      </el-form-item>
      <el-form-item prop="joined">
        <el-select
          v-model="queryParams.joined"
          placeholder="客户是否入驻"
          clearable
        >
          <el-option label="是" :value="1" />
          <el-option label="否" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="loading" @click="handleQuery">
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :header-cell-style="{ background: '#F2F3F5' }"
      :data="dataList"
      border
      style="width: 100%"
    >
      <el-table-column prop="storeName" label="客户名称" />
      <el-table-column prop="address" label="客户位置" />
      <el-table-column prop="" label="添加时间|添加人">
        <template slot-scope="{ row }">
          <div>{{ row.createTime }}</div>
          <div>{{ row.salesmanName }}/{{ row.salesmanAccount }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="关联供应商客户">
        <template slot-scope="{ row }">
          <div>{{ row.customerName }}</div>
          <div>单位内码：{{ row.erpCode }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="是否入驻平台">
        <template slot-scope="{ row }">
          <div>{{ row.joined }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="{ row }">
          <div>
            <span class="btsed" @click="deletes(row)">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  manuallyCustomerlist, //手动添加客户列表
  manuallyCustomerdel, //手动删除客户列表
} from "@/api/apiAll/phpUrl.js";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  name: "buyforfree",
  computed: {
    ...commonIndex.mapState(["AreaList"]),
  },

  data() {
    return {
      loading: false,

      // 搜索栏
      queryParams: {
        page: 1,
        perPage: 10,
        storeName: "", //客户名称
        addr: [], //省/市/区县
        joined: "", //客户是否入驻
      },
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      }, //级联选择器配置项

      dataList: [], //列表数据
      total: 0,
    };
  },
  filters: {
    typeState(val) {
      switch (val) {
        case 1:
          return "经营企业（批发）";
        case 3:
          return "经营企业（连锁）";
        case 2:
          return "生产企业";
        default:
          return "-";
      }
    },
  },

  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getList();
  },
  methods: {
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    /** 查询列表数据 */
    getList() {
      console.log(this.queryParams, "1111111111");

      this.loading = true;
      manuallyCustomerlist(this.queryParams).then((res) => {
        this.dataList = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
      this.setquery();
    },
    /** 搜索按键操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 删除 */
    deletes(row) {
      this.$confirm(`确定要删除 ${row.storeName}？`, "删除提醒", {
        closeOnClickModal: false,
        cancelButtonText: "取消",
        confirmButtonText: "删除",
      }).then(async () => {
        let res = await manuallyCustomerdel({ id: row.id });
        if (res.code == 200) {
          this.$message.success("删除成功");
          if (this.queryParams.page > 1 && this.dataList.length == 1) {
            this.queryParams.page--;
          }
          this.getList();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
