<template>
  <div>
    <div v-if="!Editonser && showsteds">
      <div class="fx" style="margin-bottom: 20px">
        <div class="fx-1">
          <span style="font-weight: bold">审批编号:</span>
          <span style="color: #666; margin: 0px 10px">{{
            $route.query.auditSerialNumber
          }}</span>
          说明标
          <span class="yelcard"></span>
          橙色背景，为本期资质更新部分。
        </div>
        <div class="fx-1">
          <span style="font-weight: bold">供应商名称：</span>
          {{ recorItem.examineBaseInfo.name }}
        </div>
      </div>
      <div v-if="$route.query.review">
        <div class="titlxtd">审批信息</div>
        <div class="cardes">
          <div>
            <span style="width: 150px">审批处理状态：</span>
            <div class="lusdet">
              {{
                recorItem.reviewItem.reviewStatus == 1
                  ? "复核通过"
                  : "复核不通过"
              }}
            </div>
          </div>
          <div>
            <span style="width: 150px">完成复核时间：</span>
            {{ recorItem.reviewItem.createTime }}
          </div>
          <div>
            <span style="width: 150px">复核人账号/名称：</span
            >{{ recorItem.reviewItem.operatorAccount }}/{{
              recorItem.reviewItem.operator
            }}
          </div>
          <div>
            <span style="width: 150px">备注：</span
            >{{ recorItem.reviewItem.remark }}
          </div>
        </div>
      </div>
      <div v-if="!$route.query.review">
        <div class="titlxtd">审核信息</div>
        <div class="cardes">
          <div>
            <span>审核状态：</span>
            <div class="lusdet">
              {{ recorItem.examineBaseInfo.auditStatus | audStatus }}
            </div>
          </div>
          <div>
            <span>审核时间：</span> {{ recorItem.examineBaseInfo.examineTime }}
          </div>
          <div>
            <span>审核人：</span>{{ recorItem.examineBaseInfo.operator }}/{{
              recorItem.examineBaseInfo.operatorAccount
            }}
          </div>
          <div><span>备注：</span>{{ recorItem.examineBaseInfo.remark }}</div>
        </div>
      </div>
      <div v-if="!$route.query.review">
        <div class="titlxtd">提交信息</div>
        <div class="cardes">
          <div>
            <span>提交类型：</span>
            <div class="lusdet">
              {{ recorItem.examineBaseInfo.auditType | audType }}
            </div>
          </div>
          <div>
            <span>提交时间：</span> {{ recorItem.examineBaseInfo.createTime }}
          </div>
          <div>
            <span>提交人： </span>{{ recorItem.examineBaseInfo.creator }}/{{
              recorItem.examineBaseInfo.creatorAccount
            }}
          </div>
        </div>
      </div>
      <div class="titlxtd">企业基础信息</div>
      <div class="cardes">
        <div
          :class="
            recorItem.examineBaseInfo.changedContent.includes('name')
              ? 'ChangCss'
              : ''
          "
        >
          <span>企业名称：</span> {{ recorItem.examineBaseInfo.name }}
        </div>
        <div
          :class="
            recorItem.examineBaseInfo.changedContent.includes('provinceName') ||
            recorItem.examineBaseInfo.changedContent.includes('cityName') ||
            recorItem.examineBaseInfo.changedContent.includes('districtName') ||
            recorItem.examineBaseInfo.changedContent.includes('address')
              ? 'ChangCss'
              : ''
          "
        >
          <span>注册地： </span>{{ recorItem.examineBaseInfo.provinceName
          }}{{ recorItem.examineBaseInfo.cityName
          }}{{ recorItem.examineBaseInfo.districtName
          }}{{ recorItem.examineBaseInfo.address }}
        </div>
        <div
          :class="
            recorItem.examineBaseInfo.changedContent.includes('legalEntity')
              ? 'ChangCss'
              : ''
          "
        >
          <span>法人：</span>{{ recorItem.examineBaseInfo.legalEntity }}
        </div>
        <div
          :class="
            recorItem.examineBaseInfo.changedContent.includes('telephone')
              ? 'ChangCss'
              : ''
          "
        >
          <span>联系电话：</span>{{ recorItem.examineBaseInfo.telephone }}
        </div>
        <div
          :class="
            recorItem.examineBaseInfo.changedContent.includes(
              'companyTypeInfo'
            ) || recorItem.examineBaseInfo.changedContent.includes('type')
              ? 'ChangCss'
              : ''
          "
        >
          <span>企业类型：</span>
          <div class="lusdet">
            {{ recorItem.examineBaseInfo.businessTypeName }}
          </div>
          <div
            style="display: inline-block"
            v-if="recorItem.examineBaseInfo.typeName"
          >
            <span
              class="business_type"
              v-for="(v, i) in recorItem.examineBaseInfo.typeName"
              :key="i"
              >{{ v }}</span
            >
          </div>
        </div>
        <div
          :class="
            recorItem.examineBaseInfo.changedContent.includes('unityType')
              ? 'ChangCss'
              : ''
          "
        >
          <span>证件类型：</span
          >{{
            recorItem.examineBaseInfo.unityType == "1"
              ? "三证合一"
              : "非三证合一"
          }}
        </div>
        <div><span>签章类型：</span>公章</div>
        <div
          :class="
            recorItem.examineBaseInfo.changedContent.includes('sealName')
              ? 'ChangCss'
              : ''
          "
        >
          <span>签章名称：</span>{{ recorItem.examineBaseInfo.sealName }}
        </div>
      </div>
    </div>
    <div class="titlxtd">
      证件资料
      <span
        style="color: #06b7ae; margin-left: 10px; cursor: pointer"
        @click="toDown"
      >
        下载所有证件资料
      </span>
    </div>
    <div class="cardsind">
      <div class="cardsone">
        <div
          v-for="item in recorItem.qualification"
          :key="item.id"
          :class="item.isChanged == 1 ? 'ChangCss' : ''"
        >
          <div style="font-weight: bold; margin-bottom: 10px">
            {{ item.tmpName }}
          </div>
          <div class="fx">
            <div>
              <div v-for="(v, i) in item.picture" :key="i">
                <el-image
                  class="photo"
                  v-if="v != ''"
                  :src="v"
                  :preview-src-list="[v]"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
            </div>
            <div class="leftQual">
              <div v-for="(v, i) in item.text" :key="i" class="text">
                <span class="leftxt">{{ v.key }}:</span> {{ v.val }}
              </div>
              <div class="text">
                <span class="leftxt">有效期:</span>
                <span v-if="item.effectiveEndTime == '9999-12-31'">长期</span>
                <span v-else>
                  {{ item.effectiveEndTime }}
                  <span class="redin">{{
                    item.qualificationStatus == 1
                      ? "即将过期"
                      : item.qualificationStatus == 2
                      ? "已过期"
                      : ""
                  }}</span>
                </span>
              </div>
            </div>
            <div style="display: flex; flex: 1; justify-content: end">
              <div class="fx leftQual">
                <div class="leftxt" style="width: 50px">图片：</div>
                <div>
                  <el-image
                    class="photo"
                    :src="v"
                    :preview-src-list="item.legend"
                    v-for="(v, i) in item.legend"
                    :key="i"
                  >
                    <div slot="placeholder" class="image-slot">
                      加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="cardstwo"
        v-if="!Editreview && recorItem.examineBaseInfo.auditStatus == 20"
      >
        <div style="font-weight: bold">经营范围</div>
        <div class="cardstrdsa">
          <div
            v-for="(item, index) in recorItem.scopeInfo"
            :key="index"
            class="cardstrdson"
            :class="item.isChanged == 1 ? 'ChangCss' : ''"
          >
            <div style="display: flex; justify-content: center">
              <div class="cardse">
                <div class="title exceed" :title="item.businessScopeName">
                  {{ item.businessScopeName }}
                </div>
                <div>
                  <el-switch
                    v-model="item.isSetVal"
                    active-color="#06B7AE"
                    inactive-color="#e6e8eb"
                    :active-value="1"
                    :inactive-value="2"
                    :disabled="!Editonser"
                  >
                  </el-switch>
                </div>
                <div class="input-box">
                  <el-date-picker
                    editable
                    format="yyyy-MM-dd"
                    v-model="item.expirationTime"
                    type="date"
                    placeholder="选择有效日期"
                    :picker-options="pickerOptions"
                    @change="
                      item.expirationTime == '9999-12-31 00:00:00'
                        ? (item.isLongTime = true)
                        : (item.isLongTime = false)
                    "
                    value-format="yyyy-MM-dd"
                    :disabled="
                      !Editonser ||
                      item.isSetVal == 2 ||
                      item.expirationTime == '9999-12-31 00:00:00'
                    "
                  >
                  </el-date-picker>
                </div>
                <div class="checks">
                  <el-checkbox
                    v-model="item.isLongTime"
                    :disabled="!Editonser || item.isSetVal == 2"
                    @change="
                      item.expirationTime == '9999-12-31 00:00:00'
                        ? (item.expirationTime = '')
                        : (item.expirationTime = '9999-12-31 00:00:00')
                    "
                  >
                    长期
                  </el-checkbox>
                </div>
              </div>
            </div>
            <div class="redin onsdes">
              <span>{{
                item.qualificationStatus == 1
                  ? "即将过期"
                  : item.qualificationStatus == 2
                  ? "已过期"
                  : ""
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const Audit = createNamespacedHelpers("Audit");
export default {
  name: "RecordDetails",
  props: {
    //经营范围是否可展示
    Editreview: {
      type: Boolean,
      default: false,
    },
    // 是否为供应商列表编辑
    Editonser: {
      type: Boolean,
      default: false,
    },
    //** 不是详情页面 */
    showsteds: {
      type: Boolean,
      default: true,
    },
    recorItem: {
      type: Object,
      default: () => {
        return {
          examineBaseInfo: {}, //企业基础信息
          qualification: [], //资质数据
          companyTypeInfo: [], //企业类型数据
          scopeInfo: [], //经营范围数据
        };
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  filters: {
    audStatus(val) {
      switch (val) {
        case 0:
          return "待审核";
        case 9:
          return "拒绝";
        case 10:
          return "审核失败";
        case 20:
          return "审核通过";
        case 30:
          return "已撤销";
        default:
          return "-";
      }
    },
    audType(val) {
      switch (val) {
        case 1:
          return "供应商入驻";
        case 2:
          return "更新企业信息";
        case 3:
          return "更新资质";
        default:
          return "-";
      }
    },
  },
  created() {
    // this.getItem();
  },
  methods: {
    ...Audit.mapActions([
      "getv2download", //证件资料下载
    ]),
    /** 获取详情数据 */
    async getItem() {
      var data = { ...this.recorItem };
      data.qualification.forEach((v) => {
        this.$set(v, "picture", []);
        this.$set(v, "text", []);
        v.items.forEach((v1) => {
          if (v1.itemType == 3) {
            v.picture.push(v1.imgUrl);
          }
          if (v1.itemType == 1) {
            v.text.push({ key: v1.itemName, val: v1.itemText });
          }
        });
      });
      data.scopeInfo.forEach((v, i) => {
        if (v.expirationTime == "9999-12-31 00:00:00") {
          v.isLongTime = true;
        } else {
          v.isLongTime = false;
        }
      });
      Object.assign(this.recorItem, data);
    },
    /** 下载证件资料 */
    async toDown() {
      let data = await this.getv2download({
        uid: this.$route.query.businessId || this.$route.query.id,
      });
      if (data.code == 200) {
        window.open(data.data);
      } else {
        this.$message.error(data.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.yelcard {
  display: inline-block;
  width: 50px;
  height: 15px;
  background: rgba(253, 233, 207);
}
.cardes {
  display: flex;
  flex-wrap: wrap;
  color: #555;
  margin-bottom: 20px;
  .lusdet {
    display: inline-block;
    padding: 4px 7px;
    font-size: 13px;
    text-align: center;
    color: #fff;
    background: #06b7ae;
    border-radius: 5px;
  }
  .business_type {
    font-size: 14px;
    padding: 3px 8px;
    // height: 24px;
    display: inline-block;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 5px;
    color: #00bfbf;
    background-color: rgba($color: #00bfbf, $alpha: 0.09);
  }
}
.cardes > div {
  width: calc(50% - 50px);
  box-sizing: border-box;
  padding: 10px;
  margin-right: 50px;
}
.cardes > div > span {
  display: inline-block;
  width: 100px;
  color: #000;
  font-weight: bold;
}
.cardsind {
  display: flex;
  padding-top: 20px;
  .cardsone {
    border: 1px solid #eee;
    padding: 10px;
    flex: 1;
  }
  .cardsone > div:first-child {
    border-top: 0px;
  }
  .cardsone > div {
    padding: 20px 20px 10px 20px;
    margin-bottom: 10px;
    border-top: 1px dotted #eee;
    .photo {
      margin-top: 10px;
      width: 120px;
      height: 120px;
      overflow: hidden;
    }
    .leftQual {
      margin-left: 10px;
      line-height: 20px;
      color: #555;
      .text {
        margin-bottom: 10px;
      }
      .leftxt {
        display: inline-block;
        width: 100px;
        font-weight: bold;
        color: #000;
      }
    }
  }
  .cardstwo {
    border: 1px solid #eee;
    padding: 10px;
    width: 45%;
    min-width: 550px;
    .cardstrdsa {
      padding: 5px 20px 0px 20px;
      .cardse {
        display: flex;
        align-items: center;
        margin: 5px 0px;
        .title {
          width: 180px;
        }
      }
      .cardse > div {
        margin: 0px 5px;
      }
    }
    .cardstrdson {
      padding: 10px;
      margin-bottom: 10px;
    }
  }
}
.ChangCss {
  background: rgba(253, 233, 207);
  border-radius: 5px;
}
.onsdes {
  text-align: center;
  margin-left: 275px;
}
</style>
