<template>
  <div class="additionalInfo">
    <div class="title">商品补充信息</div>
    <div class="addition">
      <div class="lable">
        国家药品编码:<span class="text">{{
          additionForm.nationalDrugCode || "/"
        }}</span>
      </div>
      <div class="lable">
        英文名称:<span class="text">{{ additionForm.englishName || "/" }}</span>
      </div>
      <div class="lable">
        器械管理类别:<span class="text">{{
          additionForm.typeOfDeviceManagement | instrument
        }}</span>
      </div>
    </div>
    <!-- <div class="title">批件</div>
    <div class="goods-img">
      <template>
        <el-image
          v-for="(item, index) in additionForm.batchImagesList"
          :key="index"
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="[item]"
        ></el-image>
      </template>
    </div>
    <div class="title">再注册批件</div>
    <div class="goods-img">
      <template>
        <el-image
          v-for="(item, index) in additionForm.registerBatchImagesList"
          :key="index"
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="[item]"
        ></el-image>
      </template>
    </div>
    <div class="title">补充批件</div>
    <div class="goods-img">
      <template>
        <el-image
          v-for="(item, index) in additionForm.extendBatchImagesList"
          :key="index"
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="[item]"
        ></el-image>
      </template>
    </div> -->
    <div class="title">批件</div>
    <!-- 批件列表 -->
    <el-table
      :data="ptGoodsQualificationInfoVOS"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column prop="" align="" label="批件名称">
        <template slot-scope="scope">
          <div class="contense">
            <img
              src="@/assets/img/file.png"
              alt=""
              style="width: 40px; height: 40px"
            />
            <div class="treetler">
              {{ scope.row.qualificationTypeName }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="qualificationExpireDate"
        align="center"
        label="有效期"
      />
      <el-table-column prop="" align="center" label="来源">
        <template slot-scope="scope">
          {{ scope.row.source | sourceType }}
        </template>
      </el-table-column>
      <el-table-column prop="operator" align="center" label="更新人" />
      <el-table-column prop="drugUploadTime" align="center" label="更新时间" />
    </el-table>
    <div style="padding: 10px; line-height: 40px">
      <div style="display: flex; align-items: center">
        <span style="margin-right: 10px">自动同步</span>
        <el-switch
          v-model="detailsData.isSynchronous"
          :active-value="1"
          :inactive-value="0"
          active-color="#06B7AE"
          inactive-color="#e6e8eb"
          disabled
        />
        <span style="margin-left: 20px" class="zhutxt">{{
          detailsData.isSynchronous == 1
            ? "批件自动同步中"
            : "自动同步已关闭，系统将不再同步批件资料"
        }}</span>
      </div>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 10px">批件状态</span>
        <el-switch
          v-model="detailsData.quaStatus"
          :active-value="1"
          :inactive-value="0"
          active-color="#06B7AE"
          inactive-color="#e6e8eb"
          disabled
        />
        <span style="margin-left: 20px" class="zhutxt"
          >{{
            detailsData.quaStatus == 1 ? "已通过" : "批件审核完成开启通过状态"
          }}
          <span v-if="detailsData.quaStatus == 1"
            >({{ detailsData.approvedAuditOpeName }}，{{
              detailsData.approvedAuditDate
            }})</span
          >
        </span>
      </div>
    </div>
    <div class="btn-box">
      <div class="refuse" @click="toBack">返回</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  name: "",
  props: {
    detailsData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    detailsData(val) {
      for (let key in val) {
        if (this.additionForm.hasOwnProperty(key)) {
          this.additionForm[key] = val[key];
        }
      }
      this.ptGoodsQualificationInfoVOS =
        this.detailsData.ptGoodsQualificationInfoVOS;
    },
  },
  data() {
    return {
      isCreat: 1,
      additionForm: {
        nationalDrugCode: "",
        englishName: "",
        typeOfDeviceManagement: "",
        batchImagesList: [],
        registerBatchImagesList: [],
        extendBatchImagesList: [],
      },
      ptGoodsQualificationInfoVOS: [], //批件列表
    };
  },
  filters: {
    instrument(v) {
      switch (v) {
        case "1":
          return "第一类";
        case "2":
          return "第二类";
        case "3":
          return "第三类";
      }
    },
  },
  created() {
    this.isCreat = this.$route.query.type;
  },
  methods: {
    toBack() {
      window.history.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
.additionalInfo {
  padding-top: 20px;
  .title {
    margin-top: 10px;
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
}
.addition {
  display: flex;
  justify-content: space-between;
  width: 70%;
  .lable {
    padding: 15px;
    font-size: 15px;
    color: #333;
    .text {
      margin-left: 20px;
    }
  }
}
.btn-box {
  cursor: pointer;
  //   margin-top: 1%;
  width: 100%;
  height: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  div {
    width: 90px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    font-weight: normal;
  }
  .pass {
    margin-left: 15px;
    background: #06b7ae;
    color: #ffffff;
  }
  .refuse {
    background: #f2f2f2;
    color: #999999;
  }
}
.goods-img {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
}
</style>
