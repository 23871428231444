<template>
  <div class="MERCH goodsLibrary merch-card">
    <!-- tag标签 -->
    <div class="merch-tag">
      <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
        <!-- <el-tab-pane label="标准商品库" name="0"> -->
        <div class="">
          <!-- 搜索列 -->
          <div class="merch-search">
            <el-form ref="formApi" :model="formApi" :inline="true">
              <el-form-item label="">
                <el-input
                  placeholder="商品名称/通用名"
                  v-model="formApi.keyword"
                ></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input
                  placeholder="编号"
                  clearable
                  v-model="formApi.id"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input
                  clearable
                  placeholder="批准文号"
                  v-model="formApi.approvalNumber"
                ></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input
                  placeholder="生产厂家"
                  v-model="formApi.manufacturer"
                ></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-cascader
                  v-model="cascaderValue"
                  :options="BasissCopeData.inquiregoods_categoryList"
                  :props="propsData"
                  @change="cascaderhandleChange"
                  :key="refKey"
                  placeholder="商品分类"
                  clearable
                ></el-cascader>
              </el-form-item>
              <el-form-item label="">
                <el-select
                  clearable
                  v-model="formApi.businessScopeId"
                  placeholder="选择商品经营类别"
                  filterable
                >
                  <el-option
                    v-for="item in BasissCopeData.notNewbusinessScopeId"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-select
                  v-model="formApi.forbidTheSaleStatus"
                  clearable
                  placeholder="是否禁售商品"
                >
                  <el-option
                    v-for="item in andOption"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-date-picker
                  v-model="datelist"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="图片更新开始时间"
                  end-placeholder="图片更新结束时间"
                  unlink-panels
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                />
              </el-form-item>
              <el-form-item prop="withOrWithoutPicture">
                <el-select
                  v-model="formApi.withOrWithoutPicture"
                  clearable
                  placeholder="是否有图片"
                >
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="auditPicture">
                <el-select
                  v-model="formApi.auditPicture"
                  clearable
                  placeholder="图片最新审核状态"
                >
                  <el-option label="待审核" value="1"></el-option>
                  <el-option label="已通过" value="2"></el-option>
                  <el-option label="已驳回" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="quaStatus">
                <el-select
                  v-model="formApi.quaStatus"
                  clearable
                  placeholder="批件状态"
                >
                  <el-option label="已通过" value="1"></el-option>
                  <el-option label="待审核" value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" @click="toSearch"> 查 询 </el-button>
                <el-button type="primary" @click="reset">
                  重置查询条件
                </el-button>
              </el-form-item>
            </el-form>
            <div class="search-box" style="transform: translateY(-20px)">
              <!-- 搜索 -->
              <div class="sch-1 sch-2 flex1">
                <div></div>
                <div class="flex-right">
                  <!-- <div v-if="allJurisdiction.goods_updrawse236">
                    <el-button type="primary" size="mini" @click="updrawse">
                      代供应商导入
                    </el-button>
                  </div> -->
                  <div style="width: 10px; background-color: #ffffff"></div>
                  <div>
                    <div class="bor" @click="handleCreatEdit(1)">商品创建</div>
                  </div>
                  <!-- <div>
                    <div class="bor" @click="DatabaseUpdateSet">
                      资料库更新设置
                    </div>
                  </div> -->
                  <!-- <div>
                    <div class="bor" @click="updateMess()" v-if="showProgress">
                      更新商品信息
                    </div>
                    <div class="schedule" v-if="!showProgress">
                      <div class="all">更新中...{{ progress.progress }}</div>
                      <div
                        class="complete"
                        :style="{ width: progresswidth + 'px' }"
                      >
                        <div>更新中...{{ progress.progress }}</div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div
                    @click="getplatformGoodsexport(formApi)"
                    v-if="allJurisdiction.goodsLibrary136"
                  >
                    <div class="bor">导入资料库商品</div>
                  </div> -->
                  <div>
                    <el-upload
                      :action="platformGoodsImportGoods"
                      :headers="{ TOKEN }"
                      :data="{ operatorId }"
                      :show-file-list="false"
                      :on-success="onUploadSuccess"
                    >
                      <div class="bor nobor" style="color: #fff">
                        导入资料库商品
                      </div>
                    </el-upload>
                  </div>
                  <div @click="templateDownload()">
                    <div class="bor borleft">导入模板下载</div>
                  </div>
                  <div
                    @click="getplatformGoodse(formApi)"
                    v-if="allJurisdiction.goodsLibrary136"
                  >
                    <div class="bor nobor">导出</div>
                  </div>
                </div>
                <!-- <el-button
                    type="primary"
                    size="mini"
                    @click="getplatformGoodsexport(formApi)"
                    v-if="allJurisdiction.goodsLibrary136"
                  >
                    导出未分类商品
                  </el-button> -->
              </div>
            </div>
          </div>
          <!-- 列表 -->
          <div class="merch-table scorr-roll">
            <div>
              <el-table
                :data="dataList.list"
                style="width: 100%"
                :header-cell-style="{ background: '#F2F3F5' }"
                height="500px"
                border
                v-loading="loading"
              >
                <el-table-column prop="address" label="商品图片" align="center">
                  <template slot-scope="{ row }">
                    <div class="img-box">
                      <el-image
                        style="width: 50px; height: 50px"
                        :src="handleImageError(row.picture)"
                        :preview-src-list="[handleImageError(row.picture)]"
                      >
                      </el-image>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="商品信息"
                  align="center"
                  width="320"
                >
                  <template slot-scope="{ row }">
                    <div class="goods-message">
                      <div class="span-box">
                        <div class="exceed">
                          商品名称：{{ row.name || "/" }}
                        </div>
                        <div class="exceed">商品编号：{{ row.id || "/" }}</div>
                        <div class="exceed">
                          批准文号：{{ row.approvalNumber || "/" }}
                        </div>
                        <!-- <div class="exceed">
                          生产厂家：{{ row.manufacturer || "/" }}
                        </div> -->
                        <div class="exceed">单位：{{ row.unit || "/" }}</div>
                        <!-- <div class="exceed">
                            经营类别：{{ row.type_string || "/" }}
                          </div> -->
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="经营类别" align="center">
                  <template slot-scope="{ row }">
                    <el-tooltip
                      effect="dark"
                      :content="row.typeString"
                      placement="bottom"
                    >
                      <div class="exceed">{{ row.typeString || "/" }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="商品分类" align="center">
                  <template slot-scope="{ row }">
                    <el-tooltip placement="top">
                      <div slot="content">
                        <span v-for="(v, i) in row.categoryString" :key="i"
                          >{{ v }}<br
                        /></span>
                      </div>
                      <div class="exceed">
                        {{ row.categoryString[0] || "/" }}
                      </div>
                    </el-tooltip>
                    <!-- <el-tooltip
                        effect="dark"
                        :content="row.category_string"
                        placement="bottom"
                      >
                        <div class="exceed">
                          {{ row.category_string || "/" }}
                        </div>
                      </el-tooltip> -->
                  </template>
                </el-table-column>

                <el-table-column prop="address" label="规格" align="center">
                  <template slot-scope="{ row }">
                    <div class="exceed">{{ row.specification }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="生产厂家" align="center">
                  <template slot-scope="{ row }">
                    <el-tooltip
                      effect="dark"
                      :content="row.manufacturer"
                      placement="bottom"
                    >
                      <div class="exceed">{{ row.manufacturer }}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="在售供应商"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <el-tooltip
                      effect="dark"
                      :content="row.businessCount"
                      placement="bottom"
                    >
                      <div class="exceed">
                        {{ row.businessCount || "/" }}
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="禁售商品" align="center">
                  <template slot-scope="{ row }">
                    <el-switch
                      :value="row.forbidTheSaleStatus == 1"
                      @input="handleInput(row)"
                    />
                    <!-- <el-popconfirm
                      cancel-button-text="取消"
                      confirm-button-text="确定"
                      icon="el-icon-info"
                      icon-color="#f59a23"
                      title="确定要将当前商品加入禁售清单？"
                      @confirm="forbidSale(row)"
                    >
                      <el-button slot="reference" class="popconfirm-button"><img src="../../assets/img/add.png" alt=""/></el-button>
                    </el-popconfirm> -->

                    <!-- <el-popconfirm
                      v-if="row.forbidTheSaleStatus == 1"
                      cancel-button-text="取消"
                      confirm-button-text="确定"
                      icon="el-icon-info"
                      icon-color="#f59a23"
                      title="确定要将当前商品从禁售清单中移除？"
                      @confirm="forbidSale(row)"
                    >
                      <el-button slot="reference" class="popconfirm-button"
                        ><img src="../../assets/img/SwitchOpen.png" alt=""
                      /></el-button>
                    </el-popconfirm>
                    <el-popconfirm
                      v-if="row.forbidTheSaleStatus == 0"
                      cancel-button-text="取消"
                      confirm-button-text="确定"
                      icon="el-icon-info"
                      icon-color="#f59a23"
                      title="确定要将当前商品加入禁售清单？"
                      @confirm="forbidSale(row)"
                    >
                      <el-button slot="reference" class="popconfirm-button"
                        ><img src="../../assets/img/SwitchClose.png" alt=""
                      /></el-button>
                    </el-popconfirm> -->
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="id" label="资料库ID" align="center">
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="商品名称"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <el-tooltip
                        effect="dark"
                        :content="row.name"
                        placement="bottom"
                      >
                        <div class="exceed">{{ row.name }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column> -->
                <!-- <el-table-column
                    prop="address"
                    label="是否审核"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <div class="exceed">
                        {{ row.isAudit == 1 ? "是" : "否" }}
                      </div>
                    </template>
                  </el-table-column> -->

                <el-table-column prop="" label="是否上传图片" align="center">
                  <template slot-scope="{ row }">
                    {{ row.isPicture == 1 ? "否" : "是" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="最新图片审核状态"
                  align="center"
                  width="180"
                >
                  <template slot-scope="{ row }">
                    <div>{{ row.auditPicture | auditState }}</div>
                    <div
                      v-if="row.auditPicture == 3"
                      class="yellos hider"
                      :title="row.remark"
                    >
                      (原因：{{ row.remark }})
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="操作"
                  align="center"
                  width="250"
                >
                  <template slot-scope="{ row }">
                    <div class="userBtn">
                      <!-- <div @click="tosetting(row.id)">同步设置</div> -->
                      <div
                        @click="handleCreatEdit(2, row)"
                        v-if="allJurisdiction.goodsLibrary138"
                      >
                        编辑
                      </div>
                      <div
                        @click="examdtoeds(row)"
                        v-if="
                          row.auditPicture == 1 &&
                          allJurisdiction.examineGoods266
                        "
                      >
                        审核
                      </div>
                      <div
                        @click="examdter(row)"
                        v-if="allJurisdiction.platformGoods264"
                      >
                        更新图片
                      </div>
                      <div
                        @click="handleCreatEdit(3, row)"
                        v-if="allJurisdiction.goodsLibrary137"
                      >
                        查看
                      </div>
                      <div @click="handledelet(row)">删除</div>
                    </div>
                    <div class="userBtn">
                      <div @click="UplierGoods(row)">更新供应商商品资料</div>
                    </div>
                    <div class="userBtn">
                      <div
                        v-if="[1, 2, 3].includes(row.auditPicture)"
                        @click="examdelids(row)"
                      >
                        审核记录
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 分页 -->
          <div class="merch-page">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :page-size="dataList.per_page"
              :current-page="formApi.currPage"
              layout="total, prev, pager, next, jumper"
              :total="dataList.total"
            >
            </el-pagination>
          </div>
        </div>
        <!-- </el-tab-pane> -->

        <!-- 同步记录此版本暂时删除 -->
        <!-- <el-tab-pane label="同步记录" name="1">
          <div class="merch-card scorr-roll">
            <div class="merch-search">
              <div class="search-box">
                <div class="sch-1 sch-2">
                  <div class="sch-title">标准库ID:</div>
                  <el-input
                    @change="toSearch"
                    placeholder="请输入标准库ID"
                    v-model="formApi.keyword"
                    type="number"
                  ></el-input>
                </div>
                <div class="sch-1 sch-2">
                  <div class="sch-title">资料库ID</div>
                  <el-input
                    @change="toSearch"
                    placeholder="请输入资料库ID"
                    v-model="formApi.keyword1"
                    type="number"
                  ></el-input>
                </div>
                <div class="sch-1 sch-2">
                  <el-button type="primary" size="mini" @click="toSearch">
                    搜索
                  </el-button>
                </div>
              </div>
            </div>
            <div
              :style="`height: ` + mainHright + `px`"
              class="merch-table scorr-roll"
            >
              <div>
                <el-table :data="recording.data" border style="width: 100%">
                  <el-table-column
                    prop="id"
                    label="同步时间"
                    align="center"
                    width="200px"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="同步内容"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <el-tooltip
                        effect="dark"
                        :content="row.name"
                        placement="bottom"
                      >
                        <div class="exceed">{{ row.name }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="merch-page">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="recording.per_page"
                :current-page="formApi.page"
                layout="total, prev, pager, next, jumper"
                :total="recording.total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <el-dialog title="同步开关" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="同步所有属性">
          <el-switch
            :value="form.val"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeSwitch(form.val)"
          >
          </el-switch>
        </el-form-item>
        特殊属性
        <div class="flex">
          <el-form-item label="药品本位码">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="规格">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="条形码（69）">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="剂型">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="国家医保码">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="生产厂家">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="批准文号">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="执行标准">
            <el-switch
              v-model="form.swi"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </div>
      </el-form>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div> -->
    </el-dialog>
    <el-dialog
      title="资料库商品更新提醒"
      :visible.sync="dialogVisibleRange"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="dialog-mess">
        本次共检测到
        <span>{{ progress.total }}</span> 条商品资料有更新，成功更新
        <span>{{ progress.successesCnt }}</span>
        条；更新失败<span>{{ progress.errorCnt }}</span> 条。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="errorLead"
          v-if="progress.errorCnt > 0"
        >
          下载导入失败模板
        </el-button>
        <el-button type="primary" @click="dialogVisibleRange = false" v-else>
          知道了
        </el-button>
        <!-- <el-button @click="dialogVisibleRange = false">知道了</el-button> -->
      </span>
    </el-dialog>
    <el-dialog
      title="资料库商品导入提醒"
      :visible.sync="dialogVisibleSynchronization"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="dialog-mess">
        共导入资料库商品<span>{{ toLead.total }}</span> 条，成功
        <span>{{ toLead.successesCnt }}</span>
        条；导入失败
        <span>{{ toLead.errorCnt }}</span> 条。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="toLeadError(toLead.url)"
          v-if="toLead.errorCnt > 0"
        >
          下载导入失败模板
        </el-button>
        <el-button
          type="primary"
          @click="dialogVisibleSynchronization = false"
          v-if="toLead.errorCnt == 0"
        >
          知道了
        </el-button>
        <!-- <el-button @click="dialogVisibleRange = false">知道了</el-button> -->
      </span>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog
      title="确认提示"
      :visible.sync="dialogdelet"
      width="450px"
      :close-on-click-modal="false"
    >
      <div style="line-height: 30px">
        <div>删除后，供应商商品将无法管控，确认删除当前商品？</div>
        <div style="font-size: 15px; font-weight: bold">使用供应商：</div>
        <div style="line-height: 20px">
          <span
            v-for="(v, i) in delegysList"
            :key="i"
            style="margin-right: 10px; white-space: nowrap"
            >{{ v }}</span
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogdelet = false">取 消</el-button>
        <el-button type="primary" @click="deletds">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 审核 -->
    <Batchsetexam ref="setexam" @getList="getplatformGoodslistApi" />
  </div>
</template>

<script>
import { Row } from "element-ui";
import { categoryList } from "@/utils/index";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { downLoadZip } from "@/utils/zipdownload";
const Basics = createNamespacedHelpers("Basics");
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
import { platformGoodsImportGoods } from "@/api/javaUrl.js";
const { mapState, mapActions } = createNamespacedHelpers(
  "Goods",
  "commonIndex"
);
import Batchsetexam from "./components/Batchsetexam.vue";
export default {
  components: { Batchsetexam },
  data() {
    return {
      loading: false,
      dialogVisibleSynchronization: false,
      dialogVisibleRange: false,
      toLead: {},
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
      },
      refKey: 0,
      datelist: [],
      activeName: 0,
      dataList: {},
      // 资料库请求数据
      recording: {},
      formApi: {
        isAudit: "",
        businessScopeId: "",
        categoryId: "",
        keyword: "",
        manufacturer: "",
        id: "",
        currPage: 1,
        pictureUpdateStartTime: "",
        pictureUpdateEndTime: "",
        withOrWithoutPicture: "", //是否有图片
        auditPicture: "", //图片审核 1待审核;2审核通过;3审核驳回
        quaStatus: "", //批件状态0：待审核 1：已通过
      },
      cascaderOptions: [],
      cascaderValue: [],
      // 禁售商品
      andOption: [
        {
          name: "是",
          type: 1,
        },
        {
          name: "否",
          type: 0,
        },
      ],
      dialogFormVisible: false,
      form: { val: false },
      platformGoodsImportGoods: "",
      operatorId: "",
      TOKEN: "",
      toLead: {},
      showProgress: true,
      progress: { progress: "" },
      progresswidth: "0",
      timer: null,
      errorUrl: "",

      phpbaseURL: window.localStorage.getItem("javaBaseUrl"), //导入url
      upItem: {
        supplierId: "", //供应商id
        addCourseForm: 1,
        creatorId: window.sessionStorage.getItem("admin_id"),
        source: 1,
      }, //代供应商导入上传数据
      TemplaData: {
        addCourseForm: 1,
      },
      jsonFields: {},
      businessList: [], //供应商数据
      //代供应商导入历史数据搜索条件
      searchData: {
        page: 1,
        perPage: 10,
        businessName: "", //供应商名称
        operatorName: "", //操作人
        dateTime: [], //日期筛选
      },
      behalofList: [], //代供应商导入历史数据
      total: 0,
      dialogdelet: false, //删除弹窗
      delegysList: [],
      deleId: "",
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "BasissCopeData",
      "FinilsCategoryList",
      "allJurisdiction",
      "detailsSeach",
    ]),
    ...Basics.mapState(["manageData"]),
  },
  filters: {
    Upstatus(type) {
      switch (type) {
        case 0:
          return "未处理";
        case 1:
          return "导入中";
        case 2:
          return "已完成";
        case 3:
          return "导入失败";
        default:
          return "——";
      }
    },
    auditState(type) {
      switch (type) {
        case 1:
          return "待审核";
        case 2:
          return "已通过";
        case 3:
          return "已驳回";
        default:
          return "——";
      }
    },
  },
  mounted() {
    this.platformGoodsImportGoods = platformGoodsImportGoods;
    this.operatorId = sessionStorage.getItem("admin_id");
  },
  async created() {
    if (this.$route.params.id) {
      this.formApi.id = this.$route.params.id;
    } else {
      let router = this.$router.history.current.path.substr(1);
      if (this.detailsSeach[router]) {
        this.formApi = this.detailsSeach[router];
        this.cascaderValue = this.detailsSeach[router].cascaderValue;
        this.datelist = this.detailsSeach[router].datelist;
      }
    }
    this.firstgetsyncBigDataProgress();
    this.getplatformGoodslistApi();
    this.getsyncBigDataProgressApi();
    // this.cgOptions();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    ...mapActions([
      // "getplatformGoodslist",
      "getplatformGoodsexport",
      "postsyncBigDataRun",
      "getsyncBigDataProgress",
      "postgoodsList",
      "postupdateForbidTheSaleStatus",
    ]),
    ...merchantCenterList.mapActions([
      "postbusinessList", //获取供应商列表数据
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    ...commonIndex.mapActions([
      "getGoodsImportRecordList", //商品资料库-商品导入记录
      "getPlatformGoodsSupplier", // 商品资料库-获取使用商品资料的供应商
      "deletePlatformGoods", // 商品资料库-删除资料库商品
      "syncSupplierGoods", // 商品资料库-更新供应商商品资料
    ]),
    handleCreatEdit(type, row) {
      let goodsId = "";
      if (row) {
        goodsId = row.id;
      }
      this.$router.push({
        path: "/goodsStandard",
        query: { id: goodsId, type: type },
      });
    },
    /** 更新图片 */
    examdter(row) {
      if (row.auditPicture == 1) {
        this.$alert(
          "该商品更新图片还未审核完成，暂不能更新！请联系质管审核已提交的记录！",
          "提示",
          {
            confirmButtonText: "知道了",
            closeOnClickModal: false,
            showClose: false,
            callback: (action) => {},
          }
        );
      } else {
        this.$refs.setexam.init(row.id);
      }
    },
    /** 审核 */
    examdtoeds(row) {
      this.$router.push({
        path: "/platGoodication",
        query: { id: row.auditId, goodsId: row.id },
      });
    },
    /** 审核记录 */
    examdelids(row) {
      this.$router.push({
        name: "平台资质审核",
        params: {
          id: row.auditId,
        },
      });
    },
    //** 删除 */
    handledelet(row) {
      this.getPlatformGoodsSupplier({ id: row.id }).then((res) => {
        if (res.code == "200") {
          this.delegysList = res.data;
          this.dialogdelet = true;
          this.deleId = row.id;
        }
      });
    },
    /** 更新供应商商品资料 */
    UplierGoods(row) {
      this.syncSupplierGoods({ id: row.id }).then((res) => {
        if (res.code == "200") {
          this.$message.success("更新成功");
          this.getplatformGoodslistApi();
        }
      });
    },
    async deletds() {
      let data = await this.deletePlatformGoods({ id: this.deleId });
      if (data.code == "200") {
        this.$message.success("删除成功");
        this.dialogdelet = false;
        if (this.formApi.currPage > 1 && this.dataList.list.length == 1) {
          this.formApi.currPage--;
        }
      }
      await this.getplatformGoodslistApi();
    },
    handleInput(val) {
      let confirmText = "";
      if (val.forbidTheSaleStatus == 1) {
        confirmText = "确定要将当前商品从禁售清单中移除？";
      } else if (val.forbidTheSaleStatus == 0) {
        confirmText = "确定要将当前商品加入禁售清单？";
      }
      this.$confirm(confirmText).then((event) => {
        this.forbidSale(val);
      });
    },
    // 导入
    onUploadSuccess(response, file, fileList) {
      // 处理上传成功返回的结果

      if (response.code == "000000") {
        this.toLead = response.content;
        this.dialogVisibleSynchronization = true;
        // this.errorUrl=response.content.url
        this.getplatformGoodslistApi();
      } else {
        this.$message({
          message: response.message,
          type: "warning",
        });
      }
    },

    // 导入模板下载
    templateDownload() {
      let queryParams = null;
      downLoadZip(
        `/mgengine/platformGoods/getGoodsTemplateNew`,
        "xlsx",
        "商品资料库导入模板",
        queryParams,
        "app",
        "get"
      );
    },
    // 禁售
    async forbidSale(val) {
      console.log(val);
      let operatorId = sessionStorage.getItem("admin_id");
      let forbidTheSaleStatus = val.forbidTheSaleStatus == 0 ? "1" : "0";
      let Arr = [
        {
          forbidTheSaleStatus,
          operatorId,
          id: val.id,
        },
      ];
      let data = await this.postupdateForbidTheSaleStatus(Arr);
      if (data.code == "000000") {
        this.getplatformGoodslistApi();
      }
    },

    // 处理数据
    dispose(res) {
      if (res.code == "000000") {
        this.showProgress = false;
        if (res.content == null) {
          this.showProgress = true;
          clearInterval(this.timer);
        } else {
          let percentage = res.content.progress.replace("%", "");
          percentage = percentage * 0.01;
          this.progresswidth = percentage * 120;
          this.progress = res.content;
          if (percentage == 1) {
            this.showProgress = true;
            clearInterval(this.timer);
            this.dialogVisibleRange = true;
          }
        }
      }
    },
    async firstgetsyncBigDataProgress() {
      let res = await this.getsyncBigDataProgress();
      this.dispose(res);
    },
    async getsyncBigDataProgressApi() {
      this.timer = setInterval(() => {
        this.getsyncBigDataProgress().then((res) => {
          this.dispose(res);
        });
      }, 5000);
    },
    async updateMess() {
      let res = await this.postsyncBigDataRun();
      if (res.code == "000000") {
        this.firstgetsyncBigDataProgress();
        this.getsyncBigDataProgressApi();
      }
    },
    // 资料库更新设置
    DatabaseUpdateSet() {
      this.$router.push({
        path: "/DatabaseUpdateSet",
      });
    },
    toLeadError(url) {
      window.open(url);
      this.dialogVisibleSynchronization = false;
    },
    // 导入失败模板
    errorLead() {
      window.open(this.errorUrl);
      this.dialogVisibleRange = false;
    },
    async getplatformGoodslistApi() {
      this.loading = true;
      if (this.datelist && this.datelist.length > 0) {
        this.formApi.pictureUpdateStartTime = this.datelist[0];
        this.formApi.pictureUpdateEndTime = this.datelist[1];
      } else {
        this.formApi.pictureUpdateStartTime = "";
        this.formApi.pictureUpdateEndTime = "";
      }
      let data = await this.postgoodsList(this.formApi);
      this.loading = false;
      if ((data.code = "0000000")) {
        let router = this.$router.history.current.path.substr(1);
        let save = { ...this.formApi };
        save.datelist = this.datelist;
        save.cascaderValue = this.cascaderValue;
        save.router = router;
        this.changeSeach(save);
        console.log(data);
        data.content.list.forEach((v) => {
          let categoryArr = [];
          v.categoryStringList.forEach((v1) => {
            // console.log(v1)
            let category = "";
            if (v1.length > 0) {
              v1.forEach((v2, i2) => {
                if (i2 == v1.length || i2 == 0) {
                  category = category + v2;
                } else {
                  category = category + ">" + v2;
                }
              });
            }
            categoryArr.push(category);
          });
          v.businessCount = v.businessCount + "";
          v.categoryString = categoryArr;
        });
        this.dataList = data.content;
      }
    },
    async handleCurrentChange(e) {
      this.formApi.currPage = e;
      this.getplatformGoodslistApi();
    },
    async toSearch() {
      this.formApi.currPage = 1;
      this.getplatformGoodslistApi();
    },
    reset() {
      this.formApi = {
        isAudit: "",
        businessScopeId: "",
        categoryId: "",
        keyword: "",
        manufacturer: "",
        id: "",
        currPage: 1,
        pictureUpdateStartTime: "",
        pictureUpdateEndTime: "",
      };
      this.$router.push("/goodsLibrary");
      this.datelist = [];
      this.cascaderValue = [];
      ++this.refKey;
      this.toSearch();
    },
    cascaderhandleChange(value) {
      this.cascaderValue = value;
      this.formApi.categoryId = value[value.length - 1];
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 调整次级联动结构格式
    cgOptions() {
      setTimeout((v) => {
        this.cascaderOptions = categoryList(this.FinilsCategoryList);
      }, 1000);
    },
    toMessage(row, val) {
      this.$router.push({
        path: "/goodMessage",
        query: { id: row.id, type: val },
      });
    },
    // 同步设置
    tosetting(id) {
      this.dialogFormVisible = true;
    },

    changeSwitch(value) {
      this.$confirm(
        `${
          !value
            ? "开启同步信息后，将自动同步标准库信息，已经上架商家商品信息将不会影响，确认开启？"
            : "关闭同步信息后，将不会自动同步标准库信息，确认关闭？"
        }`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          this.form.val = !value;
        })
        .catch(() => {
          this.$message.error("取消操作");
        });
    },
    getplatformGoodse(item) {
      downLoadZip(
        `/mgengine/goods/exportPlatformGoods`,
        "xlsx",
        "商品",
        item,
        "app",
        "post"
      );
    },
    /** 打开代供应商导入 */
    async updrawse() {
      // let res = await this.postbusinessList({
      //   currPage: 1,
      //   pageSize: "10000",
      // }); //获取供应商数据
      // if (res.code == "000000") {
      //   this.businessList = res.content.list;
      // }
      // await this.getBehalo(); //获取代供应商导入历史数据
      // Object.assign(this.upItem, this.$options.data().upItem);
      // this.drawer = true;
      this.$router.push({
        name: "代供应商导入",
      });
    },
    closeing() {
      Object.assign(this.upItem, this.$options.data().upItem);
    },
    confirming() {
      if (this.upItem.supplierId == "") {
        this.$message.error("请选择供应商");
      } else {
        this.$refs.updrawer.upconfirm();
      }
    },
    geterrList() {
      this.getBehalo();
    },
    downErr() {
      downLoadZip(
        `/mgengine/supplierGoods/exportError`,
        "xlsx",
        "导入失败数据",
        {},
        "app",
        "get"
      );
    },
    /** 获取代供应商导入历史数据 */
    async getBehalo() {
      this.loading = true;
      var data = { ...this.searchData };
      if (data.dateTime) {
        data.startTime = data.dateTime[0];
        data.endTime = data.dateTime[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      delete data.dateTime;
      let res = await this.getGoodsImportRecordList(data);
      if (res.code == 200) {
        this.behalofList = res.data.data;
        this.total = res.data.total;
      }
      this.loading = false;
    },
    handleChangeds() {
      this.searchData.page = 1;
      this.getBehalo();
    },
    Reset() {
      Object.assign(this.searchData, this.$options.data().searchData);
      this.getBehalo();
    },
    importResult() {},
    /** 下载导入文件、导入结果 */
    UpImportfile(filerl) {
      if (filerl == "") {
        this.$message.error("未获取下载链接地址");
      } else {
        window.open(filerl);
        this.$message.success("下载成功");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  // height: 100%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  .merch-tag {
    width: 100%;
    height: 40px;
  }
  .merch-card {
    width: 100%;
    max-height: 100%;

    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.goods-message {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img-box {
    width: 50px;
    height: 50px;
    background: rebeccapurple;
  }
  .span-box {
    margin-left: 10px;
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    div {
      width: 100%;
      text-align: left;
    }
  }
}
.flex {
  display: flex;
  .el-form-item {
    margin-bottom: 0;
    width: 200px;
  }
}
::v-deep .el-dialog {
  width: 600px;
}
::v-deep .el-form-item__label {
  width: 120px;
}
::v-deep .el-input__inner {
  line-height: 38px;
}

::v-deep .el-input--suffix .el-input__inner {
  // padding-right: 107px;
}
.flex1 {
  display: flex;
  flex: 1;
  height: 40px;
  justify-content: space-between;
  min-width: 800px !important;
  .flex-right {
    background-color: #06b7ae;
  }
  > div {
    height: 40px;
    display: flex;
    font-size: 14px;
    border-radius: 4px;
    // background-color: #06b7ae;
    color: #ffffff;

    > div {
      // padding: 0 10px;
      // margin-top: 8px;
      // height: 24px;
      // line-height: 24px;
      // border-right: 1px solid #fff;
      // margin-top: 12px;
      // margin-bottom: 12px;
      cursor: pointer;
      width: 120px;
      text-align: center;
      box-sizing: border-box;
      .bor {
        cursor: pointer;
        height: 16px;
        line-height: 16px;
        border-right: 1px solid #fff;
        margin-top: 12px;
      }
      .nobor {
        border-right: none;
      }
      .borleft {
        border-left: 1px solid #fff;
      }
      .schedule {
        position: relative;
        > div {
          height: 40px;
          line-height: 40px;
        }
        .all {
          background-color: #f2f2f2;
          color: #909399;
          width: 120px;
        }
        .complete {
          position: absolute;
          z-index: 2;
          background-color: #06b7ae;
          top: 0;
          // width: 80px;
          white-space: nowrap;
          overflow: hidden;
          > div {
            width: 120px;
          }
        }
      }
    }
    // .noborder{
    //   border-right: none;
    // }
  }
  .el-button {
    height: 40px;
    font-size: 14px;
  }
}
.dialog-mess {
  line-height: 24px;
  span {
    color: #06b7ae;
  }
}
.popconfirm-button {
  border: none;
  padding: 0;
  img {
    width: 50px;
    height: 20px;
  }
}
</style>
