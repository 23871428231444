import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import * as filters from './filters' // global filters
import router from './router'
import store from './store'//引入store
import ElementUI from 'element-ui';//引入elmentui
import 'echarts'

import Pagination from "@/components/Pagination";
// 引入图标
import VCharts from 'v-charts'
import 'element-ui/lib/theme-chalk/index.css';//elmentui样式
import "@/assets/css/common.css"//全局css
import "@/assets/scss/common.scss"
// import "./assets/scss/index.scss"
import cookie from 'vue-cookie'//引入cook存储浏览器数据
import 'default-passive-events'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/icon/iconfont.css'
import '@/assets/scss/element-variables.scss'

import Matching from './components/matching/matching.vue'
import FilterSelect from './components/FilterSelect/index.vue'
Vue.component("Matching", Matching)
// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('FilterSelect', FilterSelect)
// 引入iconfont
import "./assets/icon/iconfont.js"//
import mixin from './mixins';
Vue.mixin(mixin)

// 公共函数
import publicFunction from "./utils/publicFunction.js";
Vue.prototype.$publicFunction = publicFunction

/** 
* @desc :下拉框懒加载
* @date :
* @params :
* @author : liangdecheng
**/
Vue.directive('el-select-lazyloading', {
  bind(el, binding) {
    let SELECT_DOM = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap"
    );
    SELECT_DOM.addEventListener("scroll", function () {
      let condition =
        this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (condition) {
        binding.value();
      }
    });
  }
})
/** 
* @desc :限制el-input中type="number"的输入长度
* @date :
* @params :
* @author : liangdecheng
**/
Vue.directive('limit-number-length', {
  bind(el, binding, vnode) {
    const maxLength = binding.value; // 获取绑定的长度值
    const inputElement = el.querySelector('.el-input__inner');

    const handleInput = (event) => {
      const value = event.target.value;
      if (value.toString().length > maxLength) {
        event.target.value = value.toString().slice(0, maxLength);
        vnode.componentInstance.$emit('input', value.toString().slice(0, maxLength));
      }
    };

    inputElement.addEventListener('input', handleInput);

    // 清除事件监听器
    vnode.context.$once('hook:beforeDestroy', () => {
      inputElement.removeEventListener('input', handleInput);
    });
  }
});

Date.prototype.format = function (format) {
  var o = {
    "M+": this.getMonth() + 1, //month
    "d+": this.getDate(),    //day
    "h+": this.getHours(),   //hour
    "m+": this.getMinutes(), //minute
    "s+": this.getSeconds(), //second
    "q+": Math.floor((this.getMonth() + 3) / 3),  //quarter
    "S": this.getMilliseconds() //millisecond
  }
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
    (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp("(" + k + ")").test(format))
    format = format.replace(RegExp.$1,
      RegExp.$1.length == 1 ? o[k] :
        ("00" + o[k]).substr(("" + o[k]).length));
  return format;
}
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// Vue.use()
Vue.prototype.$http = axios
Vue.prototype.$cookie = cookie;
// Vue.use(Echarts);
Vue.use(ElementUI);
// 全局注册
Vue.use(VCharts)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
