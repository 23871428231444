<template>
  <div class="merch-card">
    <div class="edit-tab">
      <div
        class="defaultStyle"
        :class="active == 1 ? 'opp' : ''"
        @click="changeTab(1)"
      >
        商品信息
      </div>
      <div
        class="defaultStyle"
        :class="active == 2 ? 'opp' : ''"
        @click="changeTab(2)"
      >
        补充信息
      </div>
      <div
        class="defaultStyle"
        :class="active == 3 ? 'opp' : ''"
        @click="changeTab(3)"
      >
        说明书
      </div>
    </div>
    <div class="scorr-roll" style="max-height: 75vh">
      <div v-show="active == 1">
        <template v-if="isCreat == 3">
          <basicGoodsShow />
        </template>
        <template v-else>
          <basicGoodsInfo
            @creteSuccessed="creteSuccessed"
            :detailsData="detailsData"
          />
        </template>
      </div>
      <div v-show="active == 2">
        <template v-if="isCreat == 3">
          <additionalShow :detailsData="detailsData" />
        </template>
        <template v-else>
          <additionalInfo
            @replenish="replenish"
            :detailsData="detailsData"
            ref="additionalInfo"
          />
        </template>
      </div>
      <div v-show="active == 3">
        <template v-if="isCreat == 3">
          <specificationShow :detailsData="detailsData" />
        </template>
        <template v-else>
          <specification :detailsData="detailsData" ref="specification" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
import additionalInfo from "./components/additionalInfo.vue";
import basicGoodsInfo from "./components/basicGoodsInfo.vue";
import specification from "./components/specification.vue";
import basicGoodsShow from "./components/basicGoodsShow.vue";
import additionalShow from "./components/additionalShow.vue";
import specificationShow from "./components/specificationShow.vue";
export default {
  components: {
    additionalInfo,
    basicGoodsInfo,
    specification,
    basicGoodsShow,
    additionalShow,
    specificationShow,
  },
  data() {
    return {
      active: 1, //1商品信息 2补充信息 3说明书
      isCreat: 1, // 1新增 2编辑 3查看
      isCreatSuccess: 1,
      detailsData: {},
    };
  },
  created() {
    this.isCreat = this.$route.query.type;
    if (this.isCreat == 1) {
      this.isCreatSuccess = 1;
    } else {
      this.isCreatSuccess = 2;
      this.getDetails();
    }
  },
  methods: {
    ...mapActions(["getPlatformGoodsDetail"]),
    async getDetails() {
      let id = this.$route.query.id;
      let data = await this.getPlatformGoodsDetail({ id: id });
      // console.log(data.content,'11111111');
      this.detailsData = Object.assign({}, data.content);
    },
    creteSuccessed(creatSuccess, goodsId) {
      this.isCreatSuccess = creatSuccess;
      this.active = 2;
      // console.log(goodsId,'1111111');
      this.$children[1]._data.additionForm.goodsId = goodsId;
      this.$children[2]._data.specificationForm.goodsId = goodsId;
    },
    replenish(type) {
      this.active = 3;
    },
    changeTab(tab) {
      if (this.isCreat == 1 && this.isCreatSuccess == 1) {
        return this.$message({
          message: "请先新增商品",
          type: "warning",
        });
      } else {
        this.active = tab;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-tab {
  display: flex;
  .opp {
    color: #06b7ae;
    border-radius: 5px;
    font-weight: bold;
    background-color: rgba($color: #2e3238, $alpha: 0.05);
  }
  .defaultStyle {
    width: 100px;
    padding: 10px 20px;
    text-align: center;
    line-height: 100%;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
