<template>
  <div class="merlends">
    <div class="fx">
      <el-form ref="queryForm" :model="queryParams" :inline="true">
        <el-form-item prop="txSn" label="提现流水号：">
          <el-input
            v-model="queryParams.txSn"
            placeholder="请输入提现流水号"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="提现时间：">
          <el-date-picker
            style="width: 380px"
            v-model="queryParams.timeList"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="提现开始时间"
            end-placeholder="提现结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="提现状态：">
          <el-select
            v-model="queryParams.withdrawStatus"
            placeholder="请选择提现状态"
            clearable
          >
            <el-option label="提现中" :value="0" />
            <el-option label="提现成功" :value="1" />
            <el-option label="提现失败" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="handleQuery">
            查询
          </el-button>
          <el-button type="primary" @click="handleReset">重 置</el-button>
        </el-form-item>
      </el-form>
      <div class="fx-1" style="text-align: right">
        <el-button
          type="primary"
          @click="downseet"
          class="marle_10"
          :disabled="!dataList.length"
          >导出</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      :header-cell-style="{ background: '#F2F3F5' }"
      :data="dataList"
      border
      style="width: 100%"
    >
      <el-table-column prop="txSn" label="提现流水号" />
      <el-table-column prop="amount" label="提现金额">
        <template slot-scope="{ row }">
          <div v-if="row.amo != ''">￥ {{ row.amount }}</div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column prop="bankId" label="提现银行" />
      <el-table-column prop="address" label="提现进度">
        <template slot-scope="{ row }">
          <div>
            {{ row.credited | crediType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="提现时间" />
      <el-table-column prop="withdrawModel" label="提现方式" />
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="{ row }">
          <div>
            <!-- <span class="btsed" @click="BillDetails(row)">账单明细</span> -->
            <span class="btsed" @click="uponDown(row)">账单下载</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  platformWithdrawRecordlist, //平台提现记录-列表
} from "@/api/apiAll/phpUrl.js";
import { createNamespacedHelpers } from "vuex";
import { downLoadZip } from "@/utils/zipdownload";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  name: "buyforfree",
  computed: {
    ...commonIndex.mapState(["AreaList"]),
  },

  data() {
    return {
      loading: false,
      // 搜索栏
      queryParams: {
        page: 1,
        perPage: 10,
        txSn: "", //提现流水号
        timeList: [], //提现时间
        startTime: "", //提现开始时间
        endTime: "", //提现结束时间
        withdrawStatus: "", //提现状态 0-处理中 1-提现成功 2-提现失败
      },
      dataList: [], //列表数据
      total: 0,
    };
  },
  watch: {
    //提现时间
    "queryParams.timeList": {
      handler(newVal) {
        if (newVal) {
          this.queryParams.startTime = newVal[0];
          this.queryParams.endTime = newVal[1];
        } else {
          this.queryParams.startTime = "";
          this.queryParams.endTime = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },
  filters: {
    crediType(val) {
      switch (val) {
        case "0":
          return "提现中";
        case "1":
          return "提现成功";
        case "2":
          return "提现失败";
        default:
          return "-";
      }
    },
  },

  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getList();
  },
  methods: {
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    /** 查询列表数据 */
    getList() {
      this.loading = true;
      platformWithdrawRecordlist(this.queryParams).then((res) => {
        this.dataList = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
      this.setquery();
    },
    /** 搜索按键操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    // 重置
    handleReset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
    /** 导出 */
    downseet() {
      downLoadZip(
        `/platformWithdrawRecord/export`,
        "xlsx",
        "平台提现记录",
        this.queryParams,
        "php",
        "post"
      );
    },
    /** 账单下载 */
    uponDown(row) {
      downLoadZip(
        `/platformWithdrawRecord/downloadBill`,
        "xlsx",
        "账单",
        { serialNumber: row.txSn },
        "php",
        "post"
      );
    },
    /** 账单明细 */
    BillDetails(row) {},
  },
};
</script>
<style lang="scss" scoped></style>
