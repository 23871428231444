<template>
  <div class="merch-card scorr-roll detailsedes">
    <div class="fx heled">
      <div class="insted ontext">
        <div class="titlin">
          <span>{{ BasicData.statusStr || "无状态" }}</span>
          &emsp;<i class="el-icon-refresh grerding" @click="refresh"></i>
        </div>
        <div class="exceed font-size-14 font-width">
          <span>订单号：</span>
          {{ BasicData.orderNumber }}
        </div>
        <div class="exceed font-size-14 font-width">
          <span>主订单号：</span>
          {{ BasicData.groupOrderNumber }}
        </div>
        <div class="exceed font-size-14 font-width">
          <span>下单时间：</span>

          {{ BasicData.createTime }}
        </div>
        <div class="exceed font-size-14 font-width">
          <span>共享供应商：</span>
          {{ BasicData.businessName }}
        </div>
        <div class="exceed font-size-14 font-width">
          <span> 销售供应商：</span>
          {{ BasicData.saleBusinessName }}
        </div>
        <div class="exceed font-size-14 font-width">
          <span>团队：</span>
          {{ BasicData.team }}
        </div>
        <div class="fx exceed">
          <div style="flex: 5">
            <span>业务员：</span>{{ BasicData.saleMan }}
          </div>
        </div>
        <div class="exceed">
          <span>来源：</span>
          <span class="grerd">{{ BasicData.source | source }}</span>
          <span
            class="application"
            v-if="BasicData.source == 2"
            @click="onToRequestNotes"
            >查看申请单</span
          >
        </div>
        <div class="exceed">
          <span>配送商：</span>
          <span>{{ BasicData.deliveryService == 1 ? "自发货" : "合纵" }}</span>
          <el-button
            v-if="isHezong() && orderTime.count == 6"
            class="back_green"
            style="margin-left: 15px"
            size="mini"
            @click="getFollowGoods"
            >随货同行单</el-button
          >
        </div>
      </div>
      <div class="upall">
        <el-steps
          :active="orderTime.count == 0 ? undefined : orderTime.count"
          align-center
          finish-status="success"
        >
          <el-step
            v-for="(item, index) in orderTime.jobs"
            :key="index"
            :title="item.jobName"
            :description="item.createTime"
          ></el-step>
        </el-steps>
      </div>
    </div>
    <div class="stb ontext zhifuse">
      <div class="zhifu">
        <div>
          支付方式：<span class="grerd" v-if="BasicData.payWay">{{
            BasicData.payWay
          }}</span>
        </div>
        <div>支付时间：{{ BasicData.payTime || "/" }}</div>
        <div>
          支付状态：
          <span>{{ BasicData.payStatus }}</span>
        </div>
        <div>支付单号：{{ BasicData.payNumber || "/" }}</div>
        <div v-if="BasicData.payWay == '药聚力白条支付'">
          借据号：{{ BasicData.iouNumber || "/" }}
        </div>
        <div
          class="settleState-box"
          v-if="BasicData.payWay == '药聚力白条支付'"
        >
          <div>
            结清状态：<span
              v-if="
                BasicData.settleState &&
                BasicData.payWay == '药聚力白条支付' &&
                BasicData.settleState == '0'
              "
              style="color: #e63e3f"
              >未结清</span
            ><span
              v-else-if="
                BasicData.settleState &&
                BasicData.payWay == '药聚力白条支付' &&
                BasicData.settleState == '1'
              "
              style="color: #06b7ae"
              >已结清</span
            ><span v-else>/</span>
          </div>
          <div
            class="settleState"
            v-if="BasicData.payWay == '药聚力白条支付'"
            @click="settleStateDe"
          >
            贷款详情 <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div>
          发票类型：<span v-if="BasicData.invoiceType">{{
            BasicData.invoiceType | invoiceType
          }}</span
          ><span v-else>/</span>
        </div>
      </div>
      <div class="logistics">
        <div>
          <div>收货人：{{ BasicData.consignee }}</div>
          <div>电话号码：{{ BasicData.consigneeMobile }}</div>
          <div>地址：{{ BasicData.address }}</div>
          <div>物流公司：{{ BasicData.logisticsCom }}</div>
          <div>物流单号：{{ BasicData.logisticsNumber }}</div>
        </div>
        <div class="text-center bored">
          <!-- {{  materialData}} -->
          <div class="logis scorr-roll" v-if="materialData.list.length > 0">
            <p v-if="materialData.respCode != 0">
              物流信息有误：{{ materialData.respmsg }}
            </p>

            <div class="steps-box" v-if="materialData.respCode == 0">
              <!-- <el-steps
                  direction="vertical"
                  :space="20"
                  :active="1"
                >
                  <el-step
                    v-for="(item, index) in materialData[0].data.list"
                    :key="index"
                    :title="item.time"
                    :description="item.status"
                    style="flex-basis: 100px"
                  ></el-step>
                </el-steps> -->
              <div
                v-for="(item, index) in materialData.list"
                :key="index"
                class="logistics-schedules"
              >
                <span class="logistics-time">{{ item.time }}</span>
                <div class="logistics-back">
                  <img
                    src="../../assets/img/schedule.png"
                    alt=""
                    :class="index == 0 ? 'new' : ''"
                  />
                  <div class="logistics-line"></div>
                </div>
                <span class="logistics-status">{{ item.status }}</span>
              </div>
            </div>

            <!-- 物流信息 -->
          </div>
          <div v-if="materialData.list.length == 0" class="nologits">
            <img src="../../assets/img/nologits.png" alt="" />暂无物流信息
          </div>
        </div>
      </div>
    </div>
    <div class="ontext zhifuse goodsList">
      <div class="sign">
        <!-- 左边 -->
        <div class="twotle total marle_20">
          订单商品条目 {{ OrderListData.list.length }}
          条(¥
          {{ OrderListData.totalPrice }}
          )
        </div>
        <!-- 右边 -->
        <div class="leftBtn fx marig_20">
          <el-input
            placeholder="商品名称/货号"
            v-model="zfOrder.keyWords"
            clearable
          />
          <div style="width: 10px"></div>
          <el-button class="back_green bai" @click="getOrdergoods"
            >查询</el-button
          >
        </div>
      </div>
      <el-table
        :data="OrderListData.list"
        border
        style="width: 98%"
        class="el-table-mar"
        header-cell-class-name="table_header"
      >
        <el-table-column
          align="center"
          prop="id"
          label="序号"
          type="index"
          width="50"
        >
        </el-table-column>
        <el-table-column
          prop="goodsSource"
          label="标识"
          width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.goodsSource == 1">普通商品库</div>
            <div v-if="row.goodsSource == 2">活动商品库</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="named"
          label="商品名称"
          width="180"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.name"
              placement="bottom"
            >
              <div class="exceed">{{ row.name || "/" }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="afterSales"
          label="售后"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #e63e3f" v-if="scope.row.afterSales">
              {{ scope.row.afterSales }}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsId" label="商品货号" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="String(row.goodsId)"
              placement="bottom"
            >
              <div class="exceed">{{ row.goodsId }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="changjia"
          label="生产厂家"
          width="200"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.manufacturer"
              placement="bottom"
            >
              <div class="exceed">{{ row.manufacturer }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="guige" label="规格" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.specification"
              placement="bottom"
            >
              <div class="exceed">{{ row.specification }}</div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="expirationTime" label="效期" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.expirationTime"
              placement="bottom"
            >
              <div class="exceed">{{ row.expirationTime }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="approvalNumber"
          label="批准文号"
          align="center"
        />
        <el-table-column prop="unit" label="单位" align="center">
        </el-table-column>

        <el-table-column prop="piece" label="件装量" align="center">
        </el-table-column>
        <el-table-column prop="inventoryQuantity" label="库存" align="center">
        </el-table-column>

        <el-table-column prop="basicPrice" label="销售价" align="center">
        </el-table-column>

        <el-table-column
          prop="sellingPrice"
          label="结算价 (元)"
          align="center"
          width="100"
        >
        </el-table-column>

        <el-table-column
          prop="whitePreferentialAmount"
          label="白条优惠(元）"
          align="center"
          width="110"
        >
        </el-table-column>
        <!-- 
        <el-table-column
          prop="settlementPrice"
          label="价格 (元)"
          align="center"
        >
        </el-table-column> -->
        <el-table-column prop="orderQuantity" label="下单数量" align="center">
        </el-table-column>
        <el-table-column prop="quantity" label="原单数量" align="center">
        </el-table-column>
        <el-table-column
          prop="deliveryQuantity"
          label="发货数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="goodsGroup"
          label="商品组"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="goodsBrokerage"
          label="业务员佣金比例(%)"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.goodsBrokerage == 0">
              {{ scope.row.goodsBrokerage }}
            </div>
            <div v-else>{{ scope.row.goodsBrokerage }}%</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="realTimeGoodsBrokerage"
          label="实时业务员佣金比例(%)"
          align="center"
          width="170"
          ><template slot-scope="scope">
            <div v-if="scope.row.realTimeGoodsBrokerage == 0">
              {{ scope.row.realTimeGoodsBrokerage }}
            </div>
            <div v-else>{{ scope.row.realTimeGoodsBrokerage }}%</div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="orderItemCommission"
          label="订单快照佣金"
          align="center"
        />
        <el-table-column
          prop="realTimeCommission"
          label="实时佣金"
          align="center"
        /> -->
        <!-- <el-table-column
          prop="delivery_number"
          label="发货数量"
          align="center"
          v-if="orderTime.count == 0 || orderTime.count == 20"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="发货数量"
          align="center"
          v-if="orderTime.count != 0 && orderTime.count != 20"
        >
        </el-table-column> -->
        <el-table-column label="小计 (元)" align="center">
          <template slot-scope="scope">
            <div>
              {{
                (scope.row.quantity * Number(scope.row.sellingPrice)).toFixed(2)
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="expenseder" class="text-right riged">
        <div>商品总金额：¥{{ OrderListData.totalPrice }}</div>
        <div>运费：¥{{ OrderListData.freightAmount }}</div>
        <div>
          订单快照总佣金(不含白条)：¥{{
            OrderListData.orderItemTotalCommission
          }}
        </div>
        <div>
          实时总佣金 (不含白条)：¥{{ OrderListData.realTimeTotalCommission }}
        </div>
      </div>
      <div style="margin: 10px"></div>

      <div class="allfu">
        <span class="allde" v-show="!expenseder" @click="expenseder = true"
          >费用明细</span
        >
        <span class="allde" v-show="expenseder" @click="expenseder = false"
          >收起</span
        >
        <span class="allse">
          应付金额：
          <span class="twotle red">¥{{ OrderListData.payTotalPrice }}</span>
          <span v-if="OrderListData.isShow != 0" style="margin-left: ">
            ，
            <span v-if="OrderListData.isShow == 1"
              >白条支付可优惠：¥{{ OrderListData.blankNote }}</span
            >
            <span v-if="OrderListData.isShow == 2"
              >白条支付已优惠：¥{{ OrderListData.blankNote }}</span
            >
          </span>
        </span>
      </div>
    </div>
    <!-- 弹窗 -->
    <Popupedr ref="popu" :getdataed="getdataed" />

    <el-dialog width="30%" title="随货同行单" :visible.sync="inventoryVisible">
      <div class="follow-invent">
        <el-button
          class="follow-item"
          v-for="(item, index) in inventoryData"
          :key="index"
          @click="showPdf(item)"
        >
          出库单号: &nbsp;{{ item.outboundNumber }}</el-button
        >
      </div>
      <span slot="footer" style="text-align: center" class="dialog-footer">
        <el-button
          type="primary"
          v-loading="btnLoading"
          @click="handleDownInventory"
          >下载全部</el-button
        >
        <el-button type="primary" @click="inventoryVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog width="20%" title="提示" :visible.sync="isLoading">
      <div>
        <Loading style="margin-top: 0px" />
        <div class="loading-text">随货同行单查询中，请稍后...</div>
      </div>
      <span slot="footer" style="text-align: center" class="dialog-footer">
        <el-button type="primary" @click="isLoading = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog width="20%" title="提示" :visible.sync="reloadVisible">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <div>
          <i
            class="el-icon-warning"
            style="font-size: 60px; color: #797979"
          ></i>
        </div>
        <div class="loading-text">随货同行单还未生成，请于出库3小时后重试</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="reloadVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { downLoadZip } from "@/utils/zipdownload";
import Loading from "@/components/loading/loading.vue";
import Popupedr from "./components/Popupedr.vue";
import Logistics from "./components/Logistics.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Order"); //vuex公共库
export default {
  name: "detailsedes",
  components: {
    Logistics,
    Popupedr,
    Loading,
  },
  data() {
    return {
      btnLoading: false,
      reloadVisible: false,
      isLoading: false,
      inventoryData: [],
      inventoryVisible: false,
      selData: require("../../assets/json/orderSel.json"),
      expenseder: false,
      getdataed: {},

      newLogisticsList: {
        data: [],
      },
      orderTime: {
        jobs: [
          {
            jobStatus: 1,
            createTime: "",
          },
          {
            jobStatus: 2,
            createTime: "",
          },
          {
            jobStatus: 3,
            createTime: "",
          },
          {
            jobStatus: 4,
            createTime: "",
          },
          {
            jobStatus: 5,
            createTime: "",
          },
          {
            jobStatus: 6,
            createTime: "",
          },
        ],
      }, //订单时间线
      OrderListData: {
        list: [],
      },
      BasicData: {}, //基础信息
      materialData: {
        list: [],
      }, //物流信息
      zfOrder: {
        keyWords: "",
      },
      pdfCount: 0,
    };
  },
  watch: {
    inventoryData(newVal) {
      if (newVal.length > 0) {
        this.inventoryVisible = true;
      } else {
        this.reloadVisible = true;
      }
    },
  },
  filters: {
    jobStatus(v) {
      switch (v) {
        case null:
          return "待传输";
        case 1:
          return "待传输";
        case 2:
          return "待开票";
        case 3:
          return "待审核";
        case 4:
          return "待拣货";
        case 5:
          return "待出库";
        case 6:
          return "已发货";
      }
    },
    source(v) {
      switch (v) {
        case "0":
          return "客户端";
        case "1":
          return "清单分享";
        case "2":
          return "自动请货";
      }
    },
    invoiceType(v) {
      switch (v) {
        case "-1":
          return " 暂未设置";
        case "0":
          return "增值税普票";
        case "1":
          return "纸制发票";
        case "2":
          return "增值税专票";
      }
    },
    settleState(v) {
      switch (v) {
        case "0":
          return "未结清";
        case "1":
          return "已结清";
      }
    },
  },
  created() {
    // //console.log(this.$route.query.id);
    this.getOrder();
  },
  methods: {
    ...mapActions([
      "getordertimeLine",
      "getorderdetailGoodsList",
      "getorderdetailBasis",
      "getorderCenterdetailCourier",
      "orderOutbound",
      "getSingleAccompanying",
      "downloadOrderOutbound",
      "orderApplicationList",
    ]),

    async onToRequestNotes() {
      let res = await this.orderApplicationList({
        businessId: this.BasicData.saleId,
        orderId: this.BasicData.orderNumber,
      });
      if (res.data && res.data.data.length > 0) {
        this.$router.push({
          path: "/requestNotesDetails",
          query: { id: res.data.data[0].id, businessId: this.BasicData.saleId },
        });
      }
    },

    isHezong() {
      if (this.BasicData.deliveryService == 1 && this.BasicData.saleId == 88) {
        return true;
      } else if (
        this.BasicData.deliveryService == 2 &&
        this.BasicData.saleId != 88
      ) {
        return true;
      } else {
        return false;
      }
    },
    async showPdf(value) {
      // this.isLoading = true
      // let data = await this.getSingleAccompanying({
      //   orderId: this.$route.query.id,
      //   outboundNo: value
      // })
      // this.isLoading = false
      // console.log(value.accompanyGoodsOrderUrl,'11111111111');
      if (value.accompanyGoodsOrderUrl) {
        window.open(`${value.accompanyGoodsOrderUrl}`, "_blank");
      } else {
        this.reloadVisible = true;
        this.inventoryVisible = false;
      }
    },
    async handleDownInventory() {
      // let pramas = {
      //   outboundNos:this.inventoryData,
      //   orderId: this.$route.query.id,
      //   opt: "getCount"
      // }
      // let downType;
      // let res = await this.downloadOrderOutbound(pramas)
      // if(res.code == 200){
      //   // 判断是下载压缩包还是文件
      //   if (res.content == 0) {
      //     downType = null;
      //     this.reloadVisible = true;
      //     return;
      //   }else if(res.content.length == 1){
      //     downType = 'pdf'
      //   }else{
      //     downType = 'zip'
      //   }
      // }
      let downType;
      // 判断是下载压缩包还是文件
      if (this.pdfCount == 0) {
        downType = null;
        this.reloadVisible = true;
        return;
      } else if (this.inventoryData.length == 1) {
        downType = "pdf";
      } else {
        downType = "zip";
      }

      if (downType) {
        this.btnLoading = true;
        downLoadZip(
          `/orderCenter/order/downloadOrderOutbound`,
          downType,
          "随货同行单",
          { outboundNos: this.inventoryData, orderId: this.$route.query.id },
          "php",
          "post"
        );
        setTimeout(() => {
          this.btnLoading = false;
        }, 1000);
      }
    },
    //this.$route.query.id
    async getFollowGoods() {
      let data = await this.orderOutbound({
        orderId: this.$route.query.id,
      });
      if (data.code == 200) {
        this.inventoryData = data.data.outboundData;
        this.pdfCount = data.data.pdfCount;
        // this.inventoryVisible = true;
      }
    },
    settleStateDe() {
      this.$router.push({
        path: "/loanDetails",
        query: {
          userId: this.BasicData.userId,
          number: this.BasicData.iouNumber,
          // id: val.id,
        },
      });
    },
    async getOrdergoods() {
      let data = await this.getorderdetailGoodsList({
        orderId: this.$route.query.id,
        keyWords: this.zfOrder.keyWords,
      });

      this.OrderListData = data.content;
    },
    async refresh() {
      this.getOrder();
    },
    // 获取订单数据
    async getOrder() {
      //log
      // //console.log(this.$route.query);
      let timedata = await this.getordertimeLine({
        orderId: this.$route.query.id,
      });
      console.log(timedata, "11111111111");
      let listdata = await this.getorderdetailGoodsList({
        orderId: this.$route.query.id,
      });

      let basData = await this.getorderdetailBasis({
        orderId: this.$route.query.id,
      });
      let matData = await this.getorderCenterdetailCourier({
        orderId: this.$route.query.id,
      });
      // this.orderTime.jobs.forEach((v, i) => {
      //   if (timedata.content.jobs.length != 0) {
      //     timedata.content.jobs.forEach((value, index) => {
      //       if (value.jobStatus == i + 1) {
      //         this.orderTime.jobs[i].createTime = value.createTime || "";
      //       }
      //     });
      //   }
      // });
      Object.assign(this.orderTime.jobs, timedata.content.jobs);
      this.orderTime.count = timedata.content.count;
      // listdata.content.list.forEach((item) => {
      //   if (item.settlementPrice && item.orderQuantity) {
      //     item.subtotal = item.settlementPrice * item.orderQuantity;
      //     item.subtotal = item.subtotal.toFixed(2);
      //   } else {
      //     item.subtotal = 0;
      //   }
      // });
      this.OrderListData = listdata.content;
      console.log(this.OrderListData, "OrderListData");
      if (basData.content.payWay == null) {
        basData.content.payWay = false;
      }
      this.selData.payment.forEach((item) => {
        if (item.id == basData.content.payStatus) {
          basData.content.payStatus = item.Name;
        }
      });
      this.BasicData = basData.content;
      this.materialData = matData.content;
      // console.log(this.materialData)
    },
    copyed(txt) {
      this.$copyText(txt)
        .then(() => {
          this.$message.success({ message: "已成功复制到剪切板" });
        })
        .catch(() => {
          this.$message.error({ message: "复制失败" });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__footer {
  text-align: center;
}
</style>
<style lang="scss" scoped>
.follow-invent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .follow-item {
    width: calc(46%);
    margin: 10px;
  }
}
.loading-text {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}
.merch-card {
  width: 100%;
  max-height: 100%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 4px;
  background-color: rgba($color: #000000, $alpha: 0);
  box-shadow: 0;
  // background: #ffffff;
}
.heled {
  // border-top: 2px solid rgb(112, 182, 3);
  // border-left: 1px solid #ccc;
  // border-right: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
  // margin: 20px;
  margin-bottom: 20px;
  //   background: #f2f2f2;
}
.insted {
  // border-right: 1px solid #ccc;
  line-height: 25px;
  width: 350px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;

  .titlin {
    color: rgb(244, 82, 82);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    height: 16px;
    display: flex;
    justify-content: space-between;

    .grerding {
      color: rgb(127, 127, 127);
      cursor: pointer;
    }
    span::before {
      width: 4px;
      height: 100%;
      background-color: #06b7ae;
      border-radius: 10px;
      display: inline-block;
      content: "";
      margin-right: 10px;
      margin-bottom: -2px;
    }
  }
  .exceed {
    font-weight: normal;
    font-size: 14px;
    color: #333;
    span {
      color: #666;
    }
  }
  .grerd {
    color: #06b7ae !important;
    border: 1px solid #06b7ae;
    border-radius: 3px;
    padding: 0 3px;
  }
}
.upall {
  flex: 9;
  text-align: center;
  padding-top: 100px;
  background-color: #fff;
  margin-left: 20px;
  // height: 100%;
  border-radius: 10px;
}
// .zhifuse {
//   // display: flex;
//   // justify-content: space-between;
// }
.zhifu {
  padding: 20px;
  line-height: 25px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  width: 350px;
  font-size: 14px !important;
  > div {
    color: #666;
    span {
      color: #333;
    }
  }
  .grerd {
    color: #06b7ae !important;
    border: 1px solid #06b7ae;
    border-radius: 3px;
    padding: 0 3px;
  }
}
.logistics {
  padding: 20px;
  line-height: 25px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  flex: 9;
  margin-left: 20px;
  display: flex;
  font-size: 14px;
}
.el-badge__content {
  border-radius: 0px;
  right: 30px;
}
.btned {
  margin-right: 10px;
  color: #06b7ae;
  border: 1px solid #06b7ae;
}
.bored {
  border-left: 1px dashed #ccc;
  margin-left: 10px;
  flex: 1;
  .nologits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #bbbdbf;
    img {
      width: 82px;
      height: 61px;
    }
  }
}
.el-table-mar {
  margin-left: 1%;
}
.logis {
  // margin: 25px;
  padding: 0 25px;
  width: 100%;
  height: 170px;
  overflow: auto;
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 20px;
  .total {
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  .total::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
}
.back_green {
  background-color: #06b7ae;
  color: #fff;
  border: none;
}
.riged {
  margin: 15px;
  line-height: 25px;
  div {
    text-align: end;
  }
}
.allfu {
  height: 35px;
  line-height: 35px;
  text-align: center;
  position: relative;
  background: rgba(245, 247, 250, 1);
  .allde {
    cursor: pointer;
    color: #06b7ae;
  }
  .allse {
    position: absolute;
    right: 40px;
    font-size: 14px;
    // display: flex;
    // align-items: center;
    .twotle {
      font-size: 16px;
    }
  }
}
.steps-box {
  // display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.goodsList {
  background-color: #fff;
  border-radius: 10px;
  padding-top: 10px;
  margin-top: 20px;
}
.logistics-schedules {
  display: flex;
  .logistics-time {
    width: 150px;
    line-height: 20px;
  }
  .logistics-status {
    flex: 1;
    text-align: start;
    line-height: 20px;
    padding-bottom: 30px;
  }
  .logistics-back {
    // background: #BBBDBF;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      background: #bbbdbf;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      margin: 0 10px;
    }
    .new {
      background: #06b7ae;
    }
    .logistics-line {
      flex: 1;
      background-color: #bbbdbf;
      width: 1px;
      margin: 5px 0;
    }
  }
}
//步骤条
::v-deep .el-step__icon-inner {
  font-size: 20px;
  font-weight: 500;
}
::v-deep .el-step__icon {
  height: 40px;
  width: 40px;
  margin-top: -10px;
}
::v-deep .el-step.is-center .el-step__line {
  width: 60%;
  margin-left: 20%;
}
::v-deep .el-step__head.is-process {
  // border-color: #06b7ae;
  // color: #06b7ae;
  color: #c0c4cc;
  border-color: #c0c4cc;
}
::v-deep .el-step__head.is-success {
  border-color: #06b7ae;
  color: #06b7ae;
}
::v-deep .el-step__title.is-success {
  border-color: #06b7ae;
  color: #31373d;
  font-weight: bold;
}
::v-deep .el-step__description.is-success {
  color: #666;
}

::v-deep .el-button--primary.is-disabled,
::v-deep .el-button--primary.is-disabled:hover,
::v-deep .el-button--primary.is-disabled:focus,
::v-deep .el-button--primary.is-disabled:active {
  background-color: #bbbdbf;
  color: #ffffff;
  border-color: #bbbdbf;
}
::v-deep .table_header {
  background-color: #f2f3f5 !important;
}
.settleState-box {
  display: flex;
  justify-content: space-between;
  .settleState {
    color: #06b7ae;
    cursor: pointer;
  }
}
.application {
  color: #06b7ae !important;
  margin-left: 10px;
  text-decoration: underline;
}
</style>
