<template>
  <div class="vessel">
    <el-dialog
      title="请选择地图定位"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div class="mapBox">
        <div class="searchBox">
          <el-input
            style="width: 250px"
            placeholder="请输入关键字"
            suffix-icon="el-icon-search"
            @input="searchByKeyword"
            @focus="searchByKeyword"
            size="medium"
            v-model="keyword"
          >
          </el-input>

          <div class="search-result" v-if="searchResult.length && isShowResult">
            <div
              class="result-item"
              v-for="(item, index) of searchResult"
              :key="index"
              @click="handleClickResult(item)"
            >
              <div class="area-name">{{ item.name }}</div>

              <div class="area-address">{{ item.district + item.address }}</div>
            </div>
          </div>
        </div>

        <div id="container" style="width: 100%; height: 600px"></div>

        <!-- <div style="margin-top: 20px">
          <el-form :inline="true" :model="loc">
            <el-form-item label="经度">
              <el-input
                style="width: 300px"
                v-model="loc.longitude"
                placeholder="经度"
              ></el-input>
            </el-form-item>

            <el-form-item label="纬度">
              <el-input
                style="width: 300px"
                v-model="loc.latitude"
                placeholder="纬度"
              ></el-input>
            </el-form-item>

            <el-form-item label="地址">
              <el-input
                style="width: 300px"
                v-model="address"
                placeholder="详细地址"
              ></el-input>
            </el-form-item>
          </el-form>
        </div> -->
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>

        <el-button type="primary" @click="sureSelPoint">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 使用方法
 * 1.引入
 * import mapSel from "@/components/mapSel/index.vue";
 * 2.注册
 * components:{
 *     mapSel
 *  },
 * 3.使用
 * <mapSel ref="mapSel" @sendLoc="getLoc" />
 *
 * this.loc为：
 * {longitude: "104.045887",
 *  latitude: "30.734988",}
 * this.$refs.mapSel.showMap(this.loc);
 *
 * getLoc该方法返回经纬度
 * getLoc(e){
 *     this.ruleForm.lng = e.longitude
 *     this.ruleForm.lat = e.latitude
 *   },
 */

import AMapLoader from "@amap/amap-jsapi-loader";

window._AMapSecurityConfig = {
  securityJsCode: "5e9878f8772e3dba170f05c5fa06ba14", //填写你的安全密钥
};

export default {
  name: "mapSel",

  data() {
    return {
      dialogVisible: false,
      map: null,
      loc: {
        longitude: "",
        latitude: "",
      },
      address: "",
      marker: null,
      autocomplete: null,
      placeSearch: null, //搜索框
      keyword: "",
      searchResult: [],
      isShowResult: false,
    };
  },

  mounted() {},

  methods: {
    async showMap(item) {
      if (item) {
        await Object.assign(this.loc, item);
      }
      this.dialogVisible = true;
      await this.initMap(item);
    },

    // 点击搜索结果
    handleClickResult(e) {
      const { location, district, address } = e;
      const { lng, lat } = location || {};
      // 1. 如果当前搜索结果有经纬度, 直接在地图上选中, 并返回当前的值
      if (lng && lat) {
        this.map.clearMap(); //先清空点位
        this.changeValue(lng, lat, e.name, district + address);
        // console.log(location);
        this.marker = new AMap.Marker({
          position: [lng, lat],
          zIndex: 102,
        });

        let geocoder = new AMap.Geocoder({});
        var lnglat = new AMap.LngLat(lng, lat);
        geocoder.getAddress(lnglat, (status, result) => {
          // console.log(result);
          if (status === "complete" && result.regeocode) {
            // this.address = result.regeocode.formattedAddress;
            this.address = district + address;
            
            this.showInfoWindow();
          }
        });
      } else {
        this.isShowResult = false;
        this.placeSearch.search(district + address);
      }
    },

    // 输入框搜索显示下拉
    searchByKeyword() {
      this.autocomplete.search(this.keyword, (status, result) => {
        if (status === "complete" && result.info === "OK") {
          this.searchResult = result.tips.map((item) => {
            return {
              ...item,
              address: Array.isArray(item.address)
                ? item.address[0] || ""
                : item.address,
            };
          });
          this.isShowResult = true;
        }
      });
    },

    // 点击地图
    initMarket(name) {
      this.keyword = "";
      if (!(this.loc.longitude && this.loc.latitude)) {
        return;
      }
      this.isShowResult = false;
      if (this.map.getAllOverlays("marker").length) {
        // 地图已有标注，清除标注
        this.map.clearMap();
      }
      this.marker = new AMap.Marker({
        position: [this.loc.longitude, this.loc.latitude],
        zIndex: 102,
      });
      // console.log(name, "是");
      if (name) {
        // let num = name.length * 4
        // marker.value.setLabel({
        //     offset: new AMap.value.Pixel(-num, 35),
        //     // content: name
        // })
      }
      this.map.add(this.marker);
      // 构造成 LngLat 对象后传入
      var lnglat = new AMap.LngLat(this.loc.longitude, this.loc.latitude);
      // var pixel = this.map.lngLatToContainer(lnglat);
      // console.log(pixel);
      let geocoder = new AMap.Geocoder({});
      geocoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.regeocode) {
          this.address = result.regeocode.formattedAddress;
          this.showInfoWindow();
        }
      });
      // let distance = this.getMapDistance(
      //   23.357418,
      //   110.06644,
      //   this.loc.latitude,
      //   this.loc.longitude
      // );
      // console.log(`Distance: ${distance.toFixed(2)} km`);
    },
    // 地图初始化
    initMap(item) {
      AMapLoader.reset();
      AMapLoader.load({
        key: "1e6767af2e1cde818c744b7814824514", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.Geolocation",
          "AMap.CitySearch",
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.MarkerClusterer",
          "AMap.DistrictSearch",
          "AMap.ZoomControl",
          "AMap.Polygon",
          "AMap.Geocoder",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // console.log(AMap, "aaaaaaaaaaaaa数据");
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            resizeEnable: true,
            zoom: 12, //初始化地图级别
            center: [this.loc.longitude, this.loc.latitude], //初始化地图中心点位置
          });
          this.map.addControl(new AMap.Scale()); // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          this.map.addControl(new AMap.ToolBar()); //在图面添加鹰眼控件，在地图右下角显示地图的缩略图

          //通过经纬度回显地标
          if (item) {
            this.initMarket({
              lnglat: {
                lat: this.loc.latitude,
                lng: this.loc.longitude,
              },
            });
          }

          this.map.on("click", (e) => {
            this.loc.latitude = e.lnglat.lat;
            this.loc.longitude = e.lnglat.lng;
            this.initMarket(e);
          });
          var autoOptions = {
            input: "tipinput",
          };
          this.autocomplete = new AMap.AutoComplete(autoOptions);
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map,
          });
        })
        .catch((e) => {
          // console.log(e);
        });
    },

    changeValue(longitude, latitude, name, address) {
      this.isShowResult = false;
      this.loc.longitude = longitude;
      this.loc.latitude = latitude;
      const center = new AMap.LngLat(longitude, latitude);
      // 将地图的中心点移动到指定的经纬度
      this.map.setCenter(center);
      this.marker = new AMap.Marker({
        position: [this.loc.longitude, this.loc.latitude],
        zIndex: 103,
      });
      this.map.add(this.marker);
    },
    sureSelPoint() {
      if (this.loc.latitude && this.loc.longitude) {
        var data = {
          longitude: this.loc.longitude,
          latitude: this.loc.latitude,
          address: this.address,
        };
        this.$emit("sendLoc", data);
        this.dialogVisible = false;
      } else {
        this.$message.warning("请选择经纬度");
      }
    },

    /*
      计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
      默认单位km
    */
    getMapDistance(lat1, lon1, lat2, lon2) {
      const toRad = (value) => (value * Math.PI) / 180;
      const R = 6371; // 地球半径，单位为公里
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    },
    //自定义信息窗体
    showInfoWindow() {
      let infoWindow = new AMap.InfoWindow({
        isCustom: true, //是否自定义信息窗体
        content: `<div style="background-color: white;padding: 5px; border-radius: 5px;border: 1px solid #cccccc;line-height: 20px;"> 地址：${this.address}</div>`,
        closeWhenClickMap: true,
        zIndex: 999,
        offset: new AMap.Pixel(16, -35),
      });
      infoWindow.open(this.map, this.marker.getPosition());
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0px 20px !important;
}

.mapBox {
  position: relative;
  .searchBox {
    position: absolute;
    z-index: 111;
    top: 10px;
    left: 10px;
  }
}

.search-result {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  .result-item {
    width: 100%;
    cursor: pointer;
    border-bottom: 2px solid #eee;
    padding: 10px 12px;
    .area-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 20px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 20px;
    }
    .area-address {
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
