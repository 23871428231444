/** java端接口(原有接口调用方式太繁琐，从3.7.3版本起采用此方法调用) */
import { javaBaseUrl } from "@/utils/anyUrl.js";
import request from "@/utils/request";
var BaseUrl = javaBaseUrl;
let financeURL = BaseUrl + "/ptfinance"; //金融管理模块
let userURL = BaseUrl + "/ptuser"; //角色模块
let mgEngineURL = BaseUrl + "/mgengine"; //同步
let ruleURL = BaseUrl + "/yjlrule";

// 获取用户当地均价和用户的经营地址
export function getAveragePrice(params) {
  return request({
    url: ruleURL + `/baoliAccount/getAveragePrice/` + params.userId,
    method: "get",
    params,
  });
}
// 获取新网电子回单
export function obtainOrderReceipt(params) {
  return request({
    url: ruleURL + `/xwRelevant/obtainOrderReceipt/` + params.serialNumber,
    method: "get",
    params,
  });
}
// 获取批件列表
export function getApprovedDoc(data) {
  return request({
    url: mgEngineURL + `/goods/getApprovedDoc`,
    method: "post",
    data,
  });
}
// 编辑批件
export function updateApprovedDoc(data) {
  return request({
    url: mgEngineURL + `/goods/updateApprovedDoc`,
    method: "post",
    data,
  });
}
// 删除批件
export function deleteForGoodsQualById(params) {
  return request({
    url: mgEngineURL + `/goods/deleteForGoodsQualById`,
    method: "get",
    params,
  });
}
// 获取批件批量上传
export function uploadApprovedDocBatch(data) {
  return request({
    url: mgEngineURL + `/goods/uploadApprovedDocBatch`,
    method: "post",
    data,
  });
}
