<template>
  <div class="MERCH outdata">
    <!-- tag标签 -->
    <div class="merch-card scorr-roll">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <!-- 类型选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">经营类别:</div> -->
            <el-select
              clearable
              v-model="formApi.scope"
              placeholder="选择经营类别"
              filterable
            >
              <el-option
                v-for="item in BasissCopeData.Newbusiness_scope_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 区域选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">商品分类:</div> -->
            <el-cascader
              placeholder="选择商品分类"
              v-model="cascaderValue"
              :options="BasissCopeData.inquiregoods_categoryList"
              :props="propsData"
              @change="cascaderhandleChange1"
              clearable
            ></el-cascader>
            <!-- <el-select
              clearable
              v-model="formApi.category"
              placeholder="选择商品分类"
              filterable
            >
              <el-option
                v-for="item in BasissCopeData.Newgoods_category_id"
                :key="item.type"
                :label="item.category_name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </div>
          <!-- 企业名称 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">关键字搜索:</div> -->
            <el-input
              placeholder="请输入商品名称/通用名"
              v-model="formApi.keyWords"
            ></el-input>
          </div>
          <!-- 企业名称 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">生产厂家:</div> -->
            <el-input
              placeholder="请输入生产厂家"
              v-model="formApi.factory"
            ></el-input>
          </div>
          <!-- 企业名称 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">供应商:</div> -->
            <el-input
              placeholder="请输入供应商名称"
              v-model="formApi.business"
            ></el-input>
          </div>
          <!-- 搜索 -->
          <div class="sch-1 sch-2">
            <el-button type="primary" size="small" @click="toSearch">
              查 询
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="toadd"
              v-if="allJurisdiction.outdata82"
            >
              新建
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table :data="dataList.data" border style="width: 100%">
            <el-table-column prop="address" label="商品名称" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.name"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="经营类别" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.scope_string"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.scope_string || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商品分类" align="center">
              <template slot-scope="{ row }">
                <el-tooltip placement="top">
                  <div slot="content">
                    <span v-for="(v, i) in row.category_string" :key="i"
                      >{{ v }}<br
                    /></span>
                  </div>
                  <div class="exceed">
                    {{ row.category_string[0] || "/" }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="address" label="商品分类" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.category_string"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.category_string || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column> -->
            <el-table-column prop="address" label="规格" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.specification }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="生产厂家" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.manufacturer"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.manufacturer || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="供应商" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.business_name"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.business_name }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="排序" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.sort }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商品编号" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.goods_id + ''"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.goods_id }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="200"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!-- <div @click="deltelData(row)">删除</div> -->
                  <el-popconfirm
                    @confirm="deltelData(row)"
                    title="确定删除当前兜底商品？删除后药聚力小程序将不再显示该商品"
                  >
                    <div slot="reference" v-if="allJurisdiction.outdata83">
                      删除
                    </div>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="dataList.per_page"
          :current-page="formApi.page"
          layout="total, prev, pager, next, jumper"
          :total="dataList.total"
        >
        </el-pagination>
      </div>
      <!-- isAdd -->
      <div>
        <el-dialog top="5vh" title="新增" :visible.sync="isAdd" width="1200px">
          <div class="cc-box scorr-roll">
            <div class="merch-search">
              <div class="search-box">
                <div class="sch-1 sch-2">
                  <div class="sch-title">经营类别:</div>
                  <el-select
                    clearable
                    v-model="ycApi.scope_id"
                    placeholder="选择类型"
                    filterable
                  >
                    <el-option
                      v-for="item in BasissCopeData.Newbusiness_scope_id"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>

                <div class="sch-1 sch-2">
                  <div class="sch-title">商品分类:</div>
                  <el-cascader
                    placeholder="选择商品分类"
                    v-model="cascaderValue1"
                    :options="BasissCopeData.inquiregoods_categoryList"
                    :props="propsData"
                    @change="cascaderhandleChange2"
                    clearable
                  ></el-cascader>
                  <!-- <el-select
                    clearable
                    v-model="ycApi.category_id"
                    placeholder="选择类型"
                    filterable
                  >
                    <el-option
                      v-for="item in BasissCopeData.Newgoods_category_id"
                      :key="item.id"
                      :label="item.category_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select> -->
                </div>
                <div class="sch-1 sch-2">
                  <div class="sch-title exceed">商品名称:</div>
                  <el-input
                    placeholder="请输入商品名称/通用名"
                    v-model="ycApi.name"
                  ></el-input>
                </div>
                <div class="sch-1 sch-2">
                  <div class="sch-title">生产厂家:</div>
                  <el-input
                    placeholder="请输入生产厂家"
                    v-model="ycApi.manufacturer"
                  ></el-input>
                </div>
                <!-- 是否otc -->
                <!-- <div class="sch-1 sch-2">
                  <div class="sch-title">是否otc:</div>
                  <el-select
                    clearable
                    v-model="ycApi.over_the_counter"
                    placeholder="选择类型"
                    filterable
                  >
                    <el-option
                      v-for="item in otclist"
                      :key="item.type"
                      :label="item.name"
                      :value="item.type"
                    >
                    </el-option>
                  </el-select>
                </div> -->
                <div class="sch-1 sch-2">
                  <el-button type="primary" size="mini" @click="inquire">
                    查 询
                  </el-button>
                </div>
              </div>
            </div>
            <!-- 列表 -->
            <div class="merch-table-1 scorr-roll">
              <div>
                <el-table :data="YcData.data" border style="width: 100%">
                  <el-table-column prop="id" label="编号" align="center">
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="商品名称"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <el-tooltip
                        effect="dark"
                        :content="row.name"
                        placement="bottom"
                      >
                        <div class="exceed">{{ row.name || "/" }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="经营类别"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <el-tooltip
                        effect="dark"
                        :content="row.type_string"
                        placement="bottom"
                      >
                        <div class="exceed">{{ row.type_string || "/" }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="商品分类"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <div>
                        <span v-for="(v, i) in row.categoryString" :key="i"
                          >{{ v }}<br
                        /></span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="规格" align="center">
                    <template slot-scope="{ row }">
                      <el-tooltip
                        effect="dark"
                        :content="row.specification"
                        placement="bottom"
                      >
                        <div class="exceed">{{ row.specification || "/" }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="生产厂家"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <el-tooltip
                        effect="dark"
                        :content="row.manufacturer"
                        placement="bottom"
                      >
                        <div class="exceed">{{ row.manufacturer || "/" }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="供应商" align="center">
                    <template slot-scope="{ row }">
                      <el-tooltip
                        effect="dark"
                        :content="row.enterprise"
                        placement="bottom"
                      >
                        <div class="exceed">{{ row.enterprise || "/" }}</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="address" label="供应商" align="center">
                    <template slot-scope="{ row }">
                      <el-input
                        v-model="row.sort"
                        placeholder="请输入内容"
                      ></el-input>
                    </template>
                  </el-table-column> -->
                  <el-table-column
                    prop="address"
                    label="是否otc"
                    align="center"
                  >
                    <template slot-scope="{ row }">
                      <div>{{ row.over_the_counter == 0 ? "否" : "是" }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="排序"
                    align="center"
                    width="80"
                  >
                    <template slot-scope="{ row }">
                      <el-input
                        type="number"
                        v-model="row.sort"
                        placeholder="1"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="address"
                    label="操作"
                    align="center"
                    width="170"
                    fixed="right"
                  >
                    <template slot-scope="{ row }">
                      <div class="userBtn">
                        <div @click="addGoods(row)">添加</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- 分页 -->
            <div class="merch-page">
              <el-pagination
                background
                @current-change="handleCurrentChangeyc"
                :page-size="YcData.per_page"
                layout="total, prev, pager, next, jumper"
                :total="YcData.total"
                :current-page="ycApi.page"
              >
              </el-pagination>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { Row } from "element-ui";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const Basics = createNamespacedHelpers("Basics");
const { mapState, mapActions } = createNamespacedHelpers("Goods");
export default {
  data() {
    return {
      activeName: 0,
      dataList: {},
      // 资料库请求数据
      formApi: {
        page: 1,
      },
      otclist: [
        {
          name: "是",
          type: 1,
        },
        {
          name: "否",
          type: 0,
        },
      ],
      // 审核
      andOption: [
        {
          name: "是",
          type: 1,
        },
        {
          name: "否",
          type: 0,
        },
      ],
      isAdd: false,
      ycApi: {
        business_scope_id: "",
        for_sale: "",
        category_id: "",
        name: "",
        page: 1,
      },
      cascaderValue: "",
      cascaderValue1: "",
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
      },
      YcData: {},
      // 审核
      andOption: [
        {
          name: "自定义",
          type: 2,
        },
        {
          name: "立即上架",
          type: 1,
        },
        {
          name: "暂不上架",
          type: 0,
        },
      ],
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "BasissCopeData",
      "allJurisdiction",
      "detailsSeach",
    ]),
    ...Basics.mapState(["manageData"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.formApi = this.detailsSeach[router];
    }

    this.getbaseGoodslistApi();
  },
  methods: {
    ...mapActions([
      "getbaseGoodslist",
      "getplatformGoodsexport",
      "getgoodsInfolist",
      "postbaseGoodsadd",
      "postbaseGoodsdelete",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    cascaderhandleChange1(value) {
      this.formApi.category = value[value.length - 1];
    },
    cascaderhandleChange2(value) {
      this.ycApi.category_id = value[value.length - 1];
    },
    // 确认新建
    async addGoods(row) {
      let sort = row.sort;

      if (!row.sort || row.sort == "") {
        sort = 1;
      }
      let data = await this.postbaseGoodsadd({
        goods_id: row.id,
        sort,
      });
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 删除
    async deltelData(row) {
      let data = await this.postbaseGoodsdelete({ id: row.id });
      if (data.code == 200) {
        this.toSearch();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    inquire() {
      this.ycApi.page = 1;
      this.getList();
    },
    // 获取云仓列表
    async getList() {
      let data = await this.getgoodsInfolist(this.ycApi);
      console.log(data.data.data, "111111");
      if (data) {
        data.data.data.forEach((v) => {
          let categoryArr = [];
          if (v.categoryString) {
            v.categoryString.forEach((v1) => {
              let category = "";
              if (v1.length > 0) {
                v1.forEach((v2, i2) => {
                  if (i2 == v1.length || i2 == 0) {
                    category = category + v2;
                  } else {
                    category = category + ">" + v2;
                  }
                });
              }
              categoryArr.push(category);
            });
          }
          v.categoryString = categoryArr;
        });
        this.YcData = data.data;
      }
    },

    async handleCurrentChange(e) {
      this.formApi.page = e;
      this.getbaseGoodslistApi();
    },
    async toSearch() {
      this.formApi.page = 1;
      this.getbaseGoodslistApi();
    },
    async getbaseGoodslistApi() {
      let data = await this.getbaseGoodslist(this.formApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.formApi };
      save.router = router;
      this.changeSeach(save);

      if ((data.code = 200)) {
        data.data.data.forEach((v) => {
          let categoryArr = [];
          v.category_string.forEach((v1) => {
            let category = "";
            if (v1.length > 0) {
              v1.forEach((v2, i2) => {
                if (i2 == v1.length || i2 == 0) {
                  category = category + v2;
                } else {
                  category = category + ">" + v2;
                }
              });
            }
            categoryArr.push(category);
          });
          v.category_string = categoryArr;
        });
        this.dataList = data.data;
      }
    },
    // xinzen翻页
    handleCurrentChangeyc(e) {
      this.ycApi.page = e;
      this.getList();
    },
    // 前往新建
    toadd() {
      this.getList();
      this.isAdd = true;
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    toMessage(row, val) {
      this.$router.push({
        path: "/goodMessage",
        query: { id: row.id, type: val },
      });
    },
    // 关闭
    toGb() {
      this.isAdd = false;
      this.toSearch();
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  .merch-tag {
    width: 100%;
    height: 40px;
  }
  .merch-card {
    width: 100%;
    max-height: 100%;

    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.merch-table-1 {
  margin-top: 1%;
  width: 100%;
  height: 350px;
}
.cc-box {
  width: 100%;
  max-height: 500px;
}
</style>
