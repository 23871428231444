<template>
  <div class="MERCH wholedeploy">
    <div class="merch-card">
      <!-- tag标签 -->
      <div class="merch-tag">
        <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
          <el-tab-pane label="订单配置" name="1"></el-tab-pane>
          <el-tab-pane label="售后配置" name="2"></el-tab-pane>
          <el-tab-pane label="ERP配置" name="3"></el-tab-pane>
          <el-tab-pane label="支付配置" name="4"></el-tab-pane>
          <el-tab-pane label="下单拦截配置" name="5"></el-tab-pane>
          <el-tab-pane label="客服电话配置" name="6"></el-tab-pane>
          <el-tab-pane
            label="举报电话配置"
            name="7"
            v-if="allJurisdiction.wholedeploy263"
          ></el-tab-pane>
          <el-tab-pane label="打卡定位位置微调" name="8"></el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="activeName == '8'">
        <div class="fx" style="margin: 30px; align-items: center">
          <div>手机定位位置微调范围：</div>
          <el-input
            v-model="itemfrom.value"
            placeholder="请输入"
            clearable
            style="width: 300px"
            @input="
              itemfrom.value = itemfrom.value.replace(/^(0+)|[^\d]+/g, '')
            "
          ></el-input>
          <div>m（米）</div>
        </div>
        <div class="cardtable">
          <div>温馨提示：</div>
          <div style="color: #f59a23; margin-left: 20px">
            1.功能应用场景：该功能用于业务员助手端手机定位地址，可匹配门店范围。
          </div>
        </div>
        <div style="margin-left: 15vw; margin-top: 40px">
          <el-button
            @click="setVaphtos(3)"
            type="primary"
            :loading="btnLoading"
          >
            保存
          </el-button>
        </div>
      </div>
      <div v-if="activeName == '7'">
        <div class="fx" style="margin: 30px">
          <div>平台投诉电话：</div>
          <el-input
            v-model="itemfrom.value"
            placeholder="手机号或座机"
            clearable
            style="width: 300px"
          ></el-input>
        </div>

        <div style="margin: 30px">
          <el-button
            @click="setVaphtos(1)"
            type="primary"
            :loading="btnLoading"
          >
            保存
          </el-button>
        </div>
      </div>
      <div v-if="activeName == '6'">
        <customerTel />
      </div>
      <div v-if="activeName == '3'">
        <div class="btn-box" style="margin-top: 30px">
          <el-button @click="ERPbtns()" type="primary" :loading="loading">
            ERP手动配置
          </el-button>
        </div>
      </div>
      <div v-if="activeName == '4'">
        <div class="place-order">
          <span class="span1">支付通道</span>
          <el-radio-group v-model="payment">
            <el-radio :label="1">中金支付</el-radio>
            <el-radio :label="2">京东支付</el-radio>
          </el-radio-group>
        </div>
        <div class="place-order">
          <span class="span1">
            <span style="color: red">*</span>现金支付免登录</span
          >
          <el-radio-group v-model="Cashpayment">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div class="btn-box" style="margin-top: 200px">
          <el-button
            @click="handleSave(1)"
            style="width: 100px"
            type="primary"
            :loading="btnLoading"
          >
            保存
          </el-button>
        </div>
      </div>
      <div v-if="activeName == '5'">
        <div class="place-order">
          <span class="span1">客户资质有效期</span>
          <el-switch
            style="margin-right: 20px"
            v-model="placeOrderStatus"
            active-color="#00bfbf"
            inactive-color="#c6c7c9"
            :active-value="1"
            :inactive-value="0"
          />
          <div class="div1">
            <el-input
              v-model="dayInput"
              oninput="value=value > 0 ? value : ''"
              style="width: 100px; margin-right: 20px"
              size="mini"
              placeholder="请输入内容"
              type="number"
            ></el-input>
            <span class="tian11">天</span>
            <div class="bn-box">
              <el-tooltip
                class="item"
                effect="dark"
                :content="textTool"
                placement="bottom"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="place-order">
          <span class="span1">七统一商品限购</span>
          <el-radio-group v-model="goodsUnify">
            <el-radio :label="1">开</el-radio>
            <el-radio :label="0">关</el-radio>
          </el-radio-group>
        </div>
        <div class="btn-box" style="margin-top: 200px">
          <el-button
            @click="saveOrder"
            style="width: 100px"
            type="primary"
            :loading="btnLoading"
          >
            保存
          </el-button>
        </div>
      </div>
      <div v-if="activeName == '1' || activeName == '2'">
        <div>
          <div class="ordeDEP scorr-roll">
            <div class="box-1" v-for="item in DeoloyData" :key="item.id">
              <span class="span-title exceed" :title="item.title">
                {{ item.title }}
              </span>
              <div class="input-box">
                <el-input
                  @blur="item.second > 0 ? '' : (item.second = 1)"
                  v-model="item.second"
                  placeholder="请输入内容"
                  type="number"
                ></el-input>
              </div>
              <span class="span-title">{{ item.unit_name }}</span>
              <span v-if="item.title == '收货时效'" class="rigtes">
                <span @click="towhites('2')">
                  <span>白名单</span><span class="el-icon-arrow-right"></span>
                </span>
              </span>
              <span v-if="item.title == '申请售后时效'" class="rigtes">
                <span @click="towhites('1')">
                  <span>白名单</span><span class="el-icon-arrow-right"></span>
                </span>
              </span>
              <div class="bn-box">
                <el-tooltip
                  v-if="item.explain"
                  class="item"
                  effect="dark"
                  :content="item.explain"
                  placement="bottom"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-box">
          <div
            class="pass"
            @click="toNext"
            v-if="allJurisdiction.wholedeploy81"
          >
            保存
          </div>
        </div>
      </div>
    </div>
    <WhiteList ref="whiteds" />
  </div>
</template>

<script>
import {
  getValue, //全局配置-举报电话获取
  setValue, //全局配置-举报电话设置
} from "@/api/apiAll/phpUrl";
import WhiteList from "../WhiteList.vue";
import customerTel from "./customerTel.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  components: {
    customerTel,
    WhiteList,
  },
  data() {
    return {
      itemfrom: {
        value: "",
      },
      btnLoading: false,
      activeName: "1",
      input: "",
      DeoloyData: [],
      loading: false,
      placeOrderStatus: 0,
      dayInput: 7,
      payment: 2, //支付通道
      Cashpayment: 0, //现金支付免登录
      goodsUnify: 0,
      textTool:
        "设置客户资质（营业执、医疗机构执业许可证、身份证信息等）提前预警并拦截客户下单并提醒",
    };
  },
  created() {
    this.GetMessage(1);
    this.getQueryOrder();
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  methods: {
    ...mapActions([
      "getconfigurationlist",
      "postbaseConfigedit",
      "postQueryOrder",
      "postSaveOrder",
    ]),
    ...merchantCenterList.mapActions(["getsyncUser"]),

    async getQueryOrder() {
      let data = await this.postQueryOrder();
      if (data.content.interceptDate == null) {
        this.dayInput = 7;
      } else {
        this.dayInput = data.content.interceptDate;
      }
      if (data.content.status == null) {
        console.log(1111, "1111");
        this.placeOrderStatus = 0;
      } else {
        this.placeOrderStatus = data.content.status * 1;
      }
    },
    async saveOrder() {
      let prams = {
        interceptDate: this.dayInput,
        status: this.placeOrderStatus,
      };
      this.btnLoading = true;
      let res = await this.postSaveOrder(prams);
      this.btnLoading = false;
      if (res.code == "000000") {
        // this.$message({
        //   message: res.message,
        //   type: "success",
        // });
        this.handleSave(2);
      } else {
        this.getPayment(4);
      }

      // console.log(res,'res');
    },
    async GetMessage(type) {
      let data = await this.getconfigurationlist({ type });
      if (data.code == 200) {
        data.data.forEach((item) => {
          if (item.second == 0) {
            item.second = 1;
          }
          if (item.unit == 1) {
            //   小时
            // 时间转化为小时   item.second 是秒
            item.second = Math.ceil(Math.ceil(item.second / 60) / 60);
          } else if (item.unit == 2) {
            //   天
            // 时间转化为天   item.second 是秒
            item.second = Math.ceil(
              Math.ceil(Math.ceil(item.second / 60) / 60) / 24
            );
          }
        });
        this.DeoloyData = data.data;
      } else {
      }
    },
    // 数据处理
    changeSecon(val, index) {
      if (val > 0) {
        this.DeoloyData[index].second = val;
      } else {
      }
    },
    // 保存
    async toNext() {
      let transferData = [];
      this.DeoloyData.forEach((item) => {
        transferData.push({
          id: item.id,
          second: item.second,
          real_unit: item.real_unit,
          unit: item.unit,
        });
      });
      transferData.forEach((item) => {
        if (item.unit == 1) {
          //   小时
          // 时间转化为秒   item.second 是小时
          item.second = Math.ceil(Math.ceil(item.second * 60) * 60);
        } else if (item.unit == 2) {
          //   天
          // 时间转化为秒   item.second 是天
          item.second = Math.ceil(
            Math.ceil(Math.ceil(item.second * 24) * 60) * 60
          );
        }
      });
      let data = await this.postbaseConfigedit(transferData);
      if (data.code == 200) {
        this.GetMessage(this.activeName);
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    //
    handleClick(tab) {
      if (tab === "1" || tab === "2") {
        this.GetMessage(tab);
      }
      if (tab === "4") {
        this.getPayment(3);
      }
      if (tab === "5") {
        this.getQueryOrder();
        this.getPayment(4);
      }
      if (tab === "7") {
        this.getVaphtos(1);
      }
      if (tab === "8") {
        this.getVaphtos(3);
      }
    },
    /** 获取举报电话、打卡定位位置微调 */
    getVaphtos(key) {
      getValue({ key: key }).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            Object.assign(this.itemfrom, res.data[0]);
          } else {
            this.itemfrom.value = "";
          }
        }
      });
    },
    /** 保存举报电话、打卡定位位置微调 */
    setVaphtos(key) {
      var data = { ...this.itemfrom };
      data.key = key;
      this.btnLoading = true;
      setValue(data).then((res) => {
        this.btnLoading = false;
        if (res.code == 200) {
          this.$message.success("保存成功");
        }
        this.getVaphtos(key);
      });
    },
    async getPayment(type) {
      let { data } = await this.getconfigurationlist({ type });
      if (type == 3) {
        this.payment = data[0].value * 1;
        this.Cashpayment = data[1].value * 1;
      }
      if (type == 4) {
        if (data.length > 0) {
          this.goodsUnify = data[0].value * 1;
        } else {
          this.goodsUnify = 0;
        }
      }
    },
    async handleSave(type) {
      let params = [];
      if (type == 1) {
        params.push({
          id: 16,
          value: this.payment,
        });
        params.push({
          id: 17,
          value: this.Cashpayment,
        });
      }
      if (type == 2) {
        params.push({
          id: 18,
          value: this.goodsUnify,
        });
      }
      let res = await this.postbaseConfigedit(params);
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      if (type == 1) {
        this.getPayment(3);
      }
      if (type == 2) {
        this.getQueryOrder();
        this.getPayment(4);
      }
    },
    //ERP手动配置
    ERPbtns() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "配置中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      // loading.close();
      this.loading = true;
      this.getsyncUser()
        .then((res) => {
          this.loading = false;
          if (res.code == "000000") {
            this.$message.success("ERP手动配置成功");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //白名单
    async towhites(index) {
      this.$refs.whiteds.stateType = index;
      await this.$refs.whiteds.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.bn-box .el-button--primary {
  background: black !important;
}
.bn-box .el-icon-question {
  font-size: 20px;
  color: black;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;

  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .TRAN-BOX {
      width: 100%;
      height: 600px;
    }
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      height: 50px;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      // max-height: 60vh;
      // height: 200px;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.ordeDEP {
  padding: 10px;
  width: 100%;
  max-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .box-1 {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 45%;
    height: 50px;
    border-bottom: 1px solid #e5e5e5;
    .span-title {
      width: 120px;
      font-size: 14px;
      font-weight: 800;
    }
    .input-box {
      min-width: 60px;
      width: 60%;
    }
    // background: rebeccapurple;
  }
}
.place-order {
  display: flex;
  align-items: center;
  margin-top: 30px;
  .span1 {
    margin-right: 40px;
  }
  .div1 {
    display: flex;
    align-content: center;
    .tian11 {
      margin-right: 20px;
      margin-top: 6px;
    }
  }
}
.rigtes {
  width: 100px;
  text-align: right;
  color: #06b7ae;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
}
.cardtable {
  margin-left: 10vw;
  margin-top: 20vh;
  width: 50vw;
  background-color: rgb(239, 251, 251);
  padding: 10px;
  border-radius: 10px;
  line-height: 25px;
}
</style>
