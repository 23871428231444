<template>
  <div class="additionalInfo">
    <el-form
      :model="additionForm"
      label-width="140px"
      ref="additionForm"
      :inline="true"
      :rules="rules"
    >
      <div class="title">商品补充信息</div>
      <el-form-item label="国家药品编码" prop="nationalDrugCode">
        <el-input
          v-model="additionForm.nationalDrugCode"
          size="medium"
          clearable
          placeholder="请输入该药品国家药品编码"
        />
      </el-form-item>
      <el-form-item label="英文名称" prop="englishName">
        <el-input
          v-model="additionForm.englishName"
          size="medium"
          clearable
          placeholder="请输入该药品英文名称"
        />
      </el-form-item>
      <el-form-item label="器械管理类别" prop="typeOfDeviceManagement">
        <el-select
          v-model="additionForm.typeOfDeviceManagement"
          placeholder="请选择器械管理类别"
          clearable
        >
          <el-option
            v-for="item in instrumentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <div class="title">批件</div>
      <div class="goods-img">
        <template>
          <div class="up-box-bas">
            <el-upload
              :action="Actions"
              list-type="picture-card"
              :limit="5"
              :headers="token"
              :file-list="instructionsList"
              :before-upload="beforeAvatarUpload"
              :data="{ type: '1' }"
              :on-success="successInstruction"
              :on-exceed="onExceed"
            >
              <i class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete" title="删除" />
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
        </template>
      </div>
      <div class="title">再注册批件</div>
      <div class="goods-img">
        <template>
          <div class="up-box-bas">
            <el-upload
              :action="Actions"
              list-type="picture-card"
              :limit="5"
              :headers="token"
              :file-list="imageList"
              :before-upload="beforeAvatarUpload"
              :data="{ type: '1' }"
              :on-success="successUpImg"
              :on-exceed="onExceed"
            >
              <i class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleDel(file)"
                  >
                    <i class="el-icon-delete" title="删除" />
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
        </template>
      </div>
      <div class="title">补充批件</div>
      <div class="goods-img">
        <template>
          <div class="up-box-bas">
            <el-upload
              :action="Actions"
              list-type="picture-card"
              :limit="5"
              :headers="token"
              :file-list="replenishList"
              :before-upload="beforeAvatarUpload"
              :data="{ type: '1' }"
              :on-success="successReplenish"
              :on-exceed="onExceed"
            >
              <i class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleDelReplenish(file)"
                  >
                    <i class="el-icon-delete" title="删除" />
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
        </template>
      </div> -->
      <div>
        <div class="title">
          <span>批件</span>
          <div style="flex: 1; text-align: right">
            <el-button type="primary" size="mini" @click="Obtainappdocumen">
              获取批件
            </el-button>
            <el-button type="primary" size="mini" @click="Upapprdocumen">
              上传批件
            </el-button>
          </div>
        </div>
        <!-- 批件列表 -->
        <el-table
          v-loading="loading"
          :data="additionForm.ptGoodsQualificationInfoVOS"
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <el-table-column prop="" align="" label="批件名称">
            <template slot-scope="scope">
              <div class="contense">
                <img
                  src="@/assets/img/file.png"
                  alt=""
                  style="width: 40px; height: 40px"
                />
                <div class="treetler">
                  {{ scope.row.qualificationTypeName }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="qualificationExpireDate"
            align="center"
            label="有效期"
          />
          <el-table-column prop="" align="center" label="来源">
            <template slot-scope="scope">
              {{ scope.row.source | sourceType }}
            </template>
          </el-table-column>
          <el-table-column prop="operator" align="center" label="更新人" />
          <el-table-column
            prop="drugUploadTime"
            align="center"
            label="更新时间"
          />
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <span
                class="btsed"
                @click="Preview(scope.row.qualificationPdfFile)"
                >预览</span
              >
              <span class="btsed" @click="editapproval(scope.row)">编辑</span>
              <span class="btsed" @click="Deleteapproval(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 10px; line-height: 40px">
          <div style="display: flex; align-items: center">
            <span style="margin-right: 10px">自动同步</span>
            <el-switch
              v-model="additionForm.isSynchronous"
              :active-value="1"
              :inactive-value="0"
              active-color="#06B7AE"
              inactive-color="#e6e8eb"
            />
            <span style="margin-left: 20px" class="zhutxt">{{
              additionForm.isSynchronous == 1
                ? "批件自动同步中，关闭后，系统将不再自动同步批件资料"
                : "自动同步已关闭，系统将不再同步批件资料"
            }}</span>
          </div>
          <div style="display: flex; align-items: center">
            <span style="margin-right: 10px">批件状态</span>
            <el-switch
              v-model="additionForm.quaStatus"
              :active-value="1"
              :inactive-value="0"
              active-color="#06B7AE"
              inactive-color="#e6e8eb"
            />
            <span style="margin-left: 20px" class="zhutxt"
              >{{
                additionForm.quaStatus == 1
                  ? "已通过"
                  : "批件审核完成开启通过状态"
              }}
              <!-- <span
                v-if="
                  additionForm.quaStatus == 1 && additionForm.approvedAuditDate
                "
                >({{ additionForm.approvedAuditOpeName }}，{{
                  additionForm.approvedAuditDate
                }})</span
              > -->
            </span>
          </div>
        </div>
      </div>
    </el-form>
    <div class="btn-box">
      <div class="refuse" @click="toBack">返回</div>
      <div class="pass" @click="toSave">保存</div>
      <div class="paseds" v-if="isCreat == 2" @click="toSavecommd">
        保存并更新供应商商品资料
      </div>
    </div>
    <!-- 上传、编辑批件 -->
    <el-dialog
      v-if="dialogVisible"
      :title="UploadItem.showid == '' ? '上传批件' : '编辑批件'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="550px"
    >
      <div>
        <el-form
          ref="Uploadform"
          :model="UploadItem"
          label-width="80px"
          :rules="Uploadrule"
        >
          <el-form-item label="批件名称" prop="name">
            <el-input
              style="width: 350px"
              clearable
              v-model="UploadItem.name"
              placeholder="请输入批件名称"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效期" prop="validityPeriod">
            <el-date-picker
              style="width: 350px"
              v-model="UploadItem.validityPeriod"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <div class="fx" v-if="UploadItem.showid == ''">
            <span class="red" style="margin: 15px 5px 0px 0px">*</span>
            <div style="flex: 1">
              <UploadAll
                ref="updall"
                :confirmed="true"
                labeltxt="批件文件"
                :baseURL="baseURL"
                :pathUrl="'/mgengine/goods/uploadApprovedDoc'"
                :Updata="UploadItem"
                @confirming="confirming"
                @closting="closting"
              >
                <div class="zhutxt" style="margin-bottom: 10px">
                  支持扩展名：.rar .zip .doc .docx .pdf .jpg..
                </div>
              </UploadAll>
            </div>
          </div>
          <div v-else>
            <div class="contense">
              <span style="margin: 0px 10px 0px 10px">批件文件</span>
              <div class="contense">
                <img
                  src="@/assets/img/file.png"
                  alt=""
                  style="width: 40px; height: 40px"
                />
                <div class="treetler">
                  {{ UploadItem.namecopy }}
                </div>
              </div>
            </div>
            <div class="zhutxt el-icon-info" style="margin: 20px 0px">
              不支持编辑批件文件，若需要编辑，请删除后重新上传/获取批件
            </div>
            <div style="text-align: right">
              <el-button @click="closting()">取 消</el-button>
              <el-button type="primary" @click="editUpload">确定</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!-- 获取批件 -->
    <Obtainapprovalts ref="Obtaindiag" :detailsData="detailsData" />
  </div>
</template>

<script>
import {
  updateApprovedDoc, //编辑批件
  deleteForGoodsQualById, //删除批件
} from "@/api/apiAll/javaUrl.js";
import { getToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
import UploadAll from "@/components/UploadAll/index.vue";
import Obtainapprovalts from "./Obtainapprovalts.vue";
export default {
  name: "additionalInfo",
  components: {
    UploadAll,
    Obtainapprovalts, //获取批件
  },
  props: {
    detailsData: {
      type: Object,
      default: {},
    },
  },
  filters: {
    sourceType(v) {
      switch (v) {
        case 0:
          return "手动上传";
        case 1:
          return "获取同步";
        case 2:
          return "自动同步";
        default:
          return "-";
      }
    },
  },
  watch: {
    detailsData(val) {
      for (let key in val) {
        if (this.additionForm.hasOwnProperty(key)) {
          if (
            this.upkeys ||
            ![
              "nationalDrugCode",
              "englishName",
              "typeOfDeviceManagement",
              "isSynchronous",
              "quaStatus",
            ].includes(key)
          ) {
            this.additionForm[key] = val[key];
          }
        }
      }

      val.batchImagesList.forEach((item) => {
        this.instructionsList.push({
          url: item,
        });
      });
      val.registerBatchImagesList.forEach((item) => {
        this.imageList.push({
          url: item,
        });
      });
      val.extendBatchImagesList.forEach((item) => {
        this.replenishList.push({
          url: item,
        });
      });
    },
  },
  data() {
    return {
      baseURL: window.localStorage.getItem("javaBaseUrl"), //导入url
      Actions: wholeUrl + "/basis/upload",
      token: { token: getToken() },
      instructionsList: [],
      replenishList: [],
      imageList: [],
      operatorId: "",
      isCreat: 1,
      instrumentOptions: [
        {
          value: "1",
          label: "第一类",
        },
        {
          value: "2",
          label: "第二类",
        },
        {
          value: "3",
          label: "第三类",
        },
      ],
      additionForm: {
        nationalDrugCode: "",
        englishName: "",
        typeOfDeviceManagement: "",
        registerBatchImagesList: [], //再注册批件
        batchImagesList: [], //批件
        extendBatchImagesList: [],
        ptGoodsQualificationInfoVOS: [], //批件列表
        isSynchronous: "", //是否自动同步（0：否，1：是）
        quaStatus: "", //批件状态0：待审核 1：已通过
        existSource: "", //是否存在批件获取同步 0：否 1：是 存在
        approvedAuditOpeName: "", //
        approvedAuditDate: "", //
      },
      upkeys: true,
      rules: {
        nationalDrugCode: [
          {
            min: 1,
            max: 40,
            message: "国家药品编码长度最多40个字符",
            trigger: "change",
          },
        ],
        englishName: [
          {
            min: 1,
            max: 40,
            message: "英文名称长度最多40个字符",
            trigger: "change",
          },
        ],
        nationalDrugCode: [
          {
            min: 1,
            max: 50,
            message: "国家药品编码长度最多40个字符",
            trigger: "change",
          },
        ],
      },

      loading: false,
      dialogVisible: false, //上传批件弹窗
      UploadItem: {
        showid: "", //是否为编辑
        id: "", //资料库id
        operatorId: "", //操作人id
        name: "", //批件名称
        namecopy: "", //批件名称
        validityPeriod: "", //有效期
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      Uploadrule: {
        name: [{ required: true, message: "请输入批件名称", trigger: "blur" }],
        validityPeriod: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.isCreat = this.$route.query.type;
  },
  mounted() {
    if (this.isCreat == 1) {
      Object.assign(this.additionForm, this.$options.data().additionForm);
    }
    // console.log(this.BasissCopeData,'111111111');
  },
  methods: {
    ...mapActions(["saveExtendsInfo", "newEdit", "getPlatformGoodsDetail"]),
    ...commonIndex.mapActions([
      "syncSupplierGoods", // 商品资料库-更新供应商商品资料
    ]),
    async getDetails() {
      let id = this.$route.query.id;
      let data = await this.getPlatformGoodsDetail({ id: id });
    },
    toBack() {
      window.history.go(-1);
    },
    async toSave() {
      if (this.isCreat == 1) {
        let res = await this.saveExtendsInfo(this.additionForm);
        if (res.code == "000000") {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.$emit("replenish", 3);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        const operatorId = sessionStorage.getItem("admin_id");
        this.additionForm.id = this.$route.query.id;
        this.additionForm.opType = "extend";
        this.additionForm.operatorId = operatorId;
        let resEdit = await this.newEdit(this.additionForm);
        if (resEdit.code == "000000") {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message({
            message: resEdit.message,
            type: "error",
          });
        }
      }
      this.upkeys = true;
      this.$parent.getDetails();
    },
    async toSavecommd() {
      const operatorId = sessionStorage.getItem("admin_id");
      this.additionForm.id = this.$route.query.id;
      this.additionForm.opType = "extend";
      this.additionForm.operatorId = operatorId;
      let resEdit = await this.newEdit(this.additionForm);
      if (resEdit.code == "000000") {
        let res = await this.syncSupplierGoods({ id: this.$route.query.id });
        if (res.code == "200") {
          this.$message.success("操作成功");
        }
      }
    },
    successInstruction(data) {
      if (data.code == 200) {
        this.instructionsList.push({
          url: data.data.full_url,
        });
        this.additionForm.batchImagesList.push(data.data.full_url);
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    successReplenish(data) {
      if (data.code == 200) {
        this.replenishList.push({
          url: data.data.full_url,
        });
        this.additionForm.extendBatchImagesList.push(data.data.full_url);
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 成功数据
    successUpImg(data) {
      if (data.code == 200) {
        this.imageList.push({
          url: data.data.full_url,
        });
        this.additionForm.registerBatchImagesList.push(data.data.full_url);
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    handleDel(data) {
      this.imageList = this.imageList.filter((item) => {
        return item.url != data.url;
      });
      this.additionForm.registerBatchImagesList =
        this.additionForm.registerBatchImagesList.filter((item) => {
          return item != data.url;
        });
    },
    handleDelReplenish(data) {
      this.replenishList = this.replenishList.filter((item) => {
        return item.url != data.url;
      });
      this.additionForm.extendBatchImagesList =
        this.additionForm.extendBatchImagesList.filter((item) => {
          return item != data.url;
        });
    },
    // 删除上传的图片
    handleRemove(data) {
      this.instructionsList = this.instructionsList.filter((item) => {
        return item.url != data.url;
      });
      this.additionForm.batchImagesList =
        this.additionForm.batchImagesList.filter((item) => {
          return item != data.url;
        });
    },
    async beforeAvatarUpload(file) {
      if (file.size / 1024 / 1024 > 50)
        return this.$message.error("上传头像图片大小不能超过 50MB!");
      return await compressImage([file]);
    },
    onExceed(files, fileList) {
      this.$message.error("超出图片数量上限");
    },

    /** 获取批件列表 */
    getList() {
      this.upkeys = false;
      this.$parent.getDetails();
    },
    //** 上传批件弹窗 */
    Upapprdocumen() {
      Object.assign(this.UploadItem, this.$options.data().UploadItem);
      this.UploadItem.id = this.$route.query.id;
      this.UploadItem.operatorId = sessionStorage.getItem("admin_id");
      this.dialogVisible = true;
    },

    /** 上传批件保存 */
    confirming() {
      this.$refs.Uploadform.validate((valid) => {
        if (valid) {
          this.$refs.updall.upconfirm();
        }
      });
    },
    /** 上传批件关闭 */
    closting() {
      this.dialogVisible = false;
      this.getList();
    },
    /** 编辑批件弹窗 */
    editapproval(row) {
      this.UploadItem.name = row.qualificationTypeName;
      this.UploadItem.namecopy = row.qualificationTypeName;
      this.UploadItem.validityPeriod = row.qualificationExpireDate;
      this.UploadItem.id = row.id;
      this.UploadItem.showid = row.id;
      this.UploadItem.operatorId = sessionStorage.getItem("admin_id");
      this.dialogVisible = true;
    },
    /** 编辑批件保存 */
    editUpload() {
      this.$refs.Uploadform.validate((valid) => {
        if (valid) {
          updateApprovedDoc(this.UploadItem).then((res) => {
            if (res.code == "000000") {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              this.getList();
            }
          });
        }
      });
    },
    /** 预览 */
    Preview(row) {
      window.open(row);
    },
    /** 删除批件 */
    Deleteapproval(row) {
      this.$confirm(`确认删除批件资质？`, "删除批件", {
        closeOnClickModal: false,
        cancelButtonText: "确定",
        confirmButtonText: "删除",
        type: "warning",
      }).then(() => {
        deleteForGoodsQualById({ id: row.id }).then((res) => {
          if (res.code == "000000") {
            this.$message.success("删除成功");
            this.getList();
          }
        });
      });
    },
    /** 获取批件弹窗 */
    Obtainappdocumen() {
      this.$refs.Obtaindiag.getItems();
    },
  },
};
</script>
<style lang="scss" scoped>
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
.additionalInfo {
  padding-top: 20px;
  .title {
    margin-top: 10px;
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
}
.btn-box {
  cursor: pointer;
  margin-top: 3%;
  width: 100%;
  height: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  div {
    width: 90px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    font-weight: normal;
  }
  .pass {
    margin-left: 15px;
    background: #06b7ae;
    color: #ffffff;
  }
  .paseds {
    width: 200px;
    background: #06b7ae;
    color: #ffffff;
  }
  .refuse {
    background: #f2f2f2;
    color: #999999;
  }
}
.goods-img {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
}
</style>
